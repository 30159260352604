import {Product} from '../../product.model';
import {StatusNames} from '@/shared/state/status/enums/status-names.enum';

export const calculateStatusForProduct = ({suppliers}: Product) => {
  if (suppliers?.length === 0) {
    return StatusNames.Inactive;
  }

  let statusBasedOnSupplierProducts: StatusNames = StatusNames.Inactive;

  for (const {pivot: supplierProduct} of suppliers) {
    // If at least one supplier product is active assume status is active
    if (supplierProduct?.status?.name === StatusNames.Active) {
      return StatusNames.Active;
    }

    // If only inactive but at least one supplier product is in proposal assume status is proposal
    if (supplierProduct?.status?.name === StatusNames.Proposal) {
      statusBasedOnSupplierProducts = StatusNames.Proposal;
    }
  }

  return statusBasedOnSupplierProducts;
}
