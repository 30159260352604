import {inject, Pipe, PipeTransform} from '@angular/core';
import {ProductAttributeTemplate} from '../product-attribute-template.model';
import {ProductAttributeTemplatesQuery} from '../product-attribute-templates.query';

@Pipe({
  name: 'productAttributeTemplateById$'
})
export class ProductAttributeTemplateByIdPipe implements PipeTransform {
  private readonly productAttributeTemplatesQuery = inject(ProductAttributeTemplatesQuery);

  transform(productAttributeTemplateId: ProductAttributeTemplate['id']) {
    return this.productAttributeTemplatesQuery.selectProductAttributeTemplateById(productAttributeTemplateId);
  }
}
