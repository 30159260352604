<div class="mat-typography">
    <div
      class="mat-headline"
      mat-dialog-title>
        {{ data.selectedProductAttributeTemplateId ? 'Optionen hinzufügen' : 'Variantenattribut hinzufügen' }}
    </div>

    <form [formGroup]="variantAttributesForm">
        <dqn-form-field class="w-full">
            <dqn-label>Artikelmerkmal</dqn-label>

            <dqn-dropdown formControlName="variantAttributeTemplate">
                <dqn-option
                  *ngFor="let template of availableProductAttributes"
                  [value]="template.id">
                    {{ template.display_name }}
                </dqn-option>
            </dqn-dropdown>

            <dqn-error *ngIf="variantAttributeTemplateControl.touched && variantAttributeTemplateControl.hasError('required')">
                Es muss ein Artikelmerkmal ausgewählt sein.
            </dqn-error>
        </dqn-form-field>

        <div class="flex gap-1 items-center">
            <dqn-form-field
              hintText="Jede neue Option mit 'Enter' bestätigen."
              class="w-full">
                <dqn-label>Optionen</dqn-label>

                <input
                  dqnInput
                  formControlName="variantAttributeOption"
                  (keydown.enter)="addNewVariantAttributeOption()"
                  placeholder="Neue Option eingeben" />

                <dqn-error *ngIf="variantAttributeOptionControl.touched && variantAttributeOptionControl.hasError('existingOption')">
                    Diese Option existiert bereits.
                </dqn-error>
            </dqn-form-field>

            <button
              dqn-button
              type="button"
              (click)="addNewVariantAttributeOption()">
                Hinzufügen
            </button>
        </div>
    </form>

    <dqn-table
      *ngIf="variantAttributeOptions.length > 0"
      size="small"
      [headers]="variantAttributeOptionsTableHeaders"
      [rows]="variantAttributeOptions"
      class="mt-1">
        <td
          *dqnTableCellTemplate="'value'; let option"
          dqnTableCell>
            {{ option }}
        </td>

        <dqn-table-row-actions *dqnTableRowAction="let option">
            <dqn-table-row-action-delete
              (click)="removeVariantAttributeOption(option)"
              text="Entfernen" />
        </dqn-table-row-actions>
    </dqn-table>

    <mat-dialog-actions align="end">
        <button
          mat-button
          color="primary"
          (click)="cancel()">Abbrechen
        </button>

        <button
          mat-raised-button
          color="primary"
          [disabled]="!variantAttributesForm.valid || variantAttributeOptions.length === 0"
          (click)="save()">
            Anlegen
        </button>
    </mat-dialog-actions>
</div>
