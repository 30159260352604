<div
  *ngIf="!formMode.isReadonly"
  class="flex justify-end">
    <button
      mat-raised-button
      color="primary"
      (click)="openProductAttributesAddDialog()">
        Artikelmerkmale hinzufügen
    </button>
</div>

<app-product-attributes-table
  [formMode]="formMode.mode"
  [productAttributes]="query.selectedProductAttribute$ | async"
  (removeProductAttribute)="removeProductAttribute($event)"
  (updateProductAttribute)="updateValueOfProductAttributeByProductAttributeTemplateId($event)" />
