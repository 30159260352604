import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {VariantAttribute} from './variant-attribute.model';

export type VariantAttributesState = EntityState<VariantAttribute>;

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'variant-attributes'})
export class VariantAttributesStore extends EntityStore<VariantAttributesState> {

  constructor() {
    super({loading: false});
  }

}

