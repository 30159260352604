import {Supplier} from '@/management/supplier/state/supplier.model';
import {SuppliersQuery} from '@/management/supplier/state/suppliers.query';
import {SuppliersService} from '@/management/supplier/state/suppliers.service';
import {FormMode} from '@/shared/enums/form-mode.enum';
import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DqnAutocompleteOption} from '@dqn/components-library/autocomplete';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {ProposalQuery, ProposalStore} from '../../state/proposal.state';

@UntilDestroy()
@Component({
  selector: 'app-step-select-or-create-supplier',
  templateUrl: './step-select-or-create-supplier.component.html',
  styleUrls: ['./step-select-or-create-supplier.component.scss']
})
export class StepSelectOrCreateSupplierComponent implements OnInit {
  FormMode = FormMode;

  form: UntypedFormGroup;
  newSupplier!: Supplier;
  disableCreateSupplier!: boolean;

  suppliers$!: Observable<DqnAutocompleteOption[]>;
  selectedSupplier$!: Observable<Supplier>;

  constructor(
    private store: ProposalStore,
    private query: ProposalQuery,
    private suppliersService: SuppliersService,
    private suppliersQuery: SuppliersQuery,
    private formBuilder: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.initialize();

    this.suppliersService.get().subscribe();

    this.form.get('supplierId').valueChanges.pipe(
      switchMap(supplierId => this.suppliersQuery.selectSupplierById(supplierId)),
      tap(supplier => this.store.updateSupplier(supplier)),
      untilDestroyed(this),
    ).subscribe();
  }

  onSupplierFormChanged(value: Supplier) {
    this.newSupplier = value;
  }

  proposal(): void {
    this.suppliersService.addToDbWithoutRedirect(this.newSupplier).pipe(
      tap(({data: supplier}) => {
        if (supplier?.id) {
          this.form.get('supplierId').setValue(supplier.id);
          this.disableCreateSupplier = true;
        }
      })
    ).subscribe();
  }

  private initialize() {
    this.newSupplier = null;
    this.disableCreateSupplier = false;

    this.form = this.formBuilder.group({
      supplierId: [null, Validators.required]
    });

    this.suppliers$ = this.suppliersQuery.suppliersAsDqnAutocompleteOptions$;
    this.selectedSupplier$ = this.query.supplier$;
  }
}
