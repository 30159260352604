import {
  ProductAttributeTemplate
} from '@/management/product/state/product-attribute-templates/product-attribute-template.model';
import {
  ProductAttributeTemplatesQuery
} from '@/management/product/state/product-attribute-templates/product-attribute-templates.query';
import {inject, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'productAttributeTemplateById$'
})
export class ProductAttributeTemplateByIdPipe implements PipeTransform {
  private readonly productAttributeTemplatesQuery = inject(ProductAttributeTemplatesQuery);

  transform(productAttributeTemplateId: ProductAttributeTemplate['id']) {
    return this.productAttributeTemplatesQuery.selectProductAttributeTemplateById(productAttributeTemplateId);
  }
}
