import {
  ProductAttributeTemplatesQuery
} from '@/management/product/state/product-attribute-templates/product-attribute-templates.query';
import {
  generateNameFromProductAttributes
} from '@/management/product/state/product-attributes/helpers/generate-name-from-product-attributes';
import {ProductAttribute} from '@/management/product/state/product-attributes/product-attribute.model';
import {inject, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'nameFromProductAttributes'
})
export class NameFromProductAttributesPipe implements PipeTransform {
  private readonly productAttributeTemplatesQuery = inject(ProductAttributeTemplatesQuery);

  transform(productAttributes: ProductAttribute[]) {
    if (!productAttributes || productAttributes.length === 0) {
      return '';
    }

    // Add information of product attribute template
    productAttributes = productAttributes.map(variantAttribute => ({
      ...variantAttribute,
      product_attribute_template: this.productAttributeTemplatesQuery.getEntity(variantAttribute.product_attribute_template_id),
    }));

    return generateNameFromProductAttributes(productAttributes);
  }
}
