import {createProduct} from '../../product.model';
import {SupplierProduct} from '../supplier-product.model';

export const createProductWithInformationFromSupplierProduct = (supplierProduct: SupplierProduct) => {
  if (!supplierProduct.product) {
    return undefined;
  }

  return createProduct({
    ...supplierProduct.product,
    preferred_supplier_product_id: supplierProduct.id,
    price_unit_price_from: supplierProduct.unit_price,
    price_unit_price_to: supplierProduct.unit_price,
    price_unit_price_currency_code_id: supplierProduct.currency_code_id,
    price_billing_frequency_price_from: supplierProduct.billing_frequency_price,
    price_billing_frequency_price_to: supplierProduct.billing_frequency_price,
    price_billing_frequency_price_currency_code_id: supplierProduct.currency_code_id,
    price_billing_frequency_id: supplierProduct.billing_frequency_id,
    supplier_product_count: 1,
    variant_count: 0,
  });
}
