import {SessionQuery} from '@/core/session/state/session.query';
import {QuantityUnit} from '@/management/product/state/quantity-units/quantity-unit.model';
import {QuantityUnitsQuery} from '@/management/product/state/quantity-units/quantity-units.query';
import {QuantityUnitsService} from '@/management/product/state/quantity-units/quantity-units.service';
import {
  SupplierProductAvailableStatus
} from '@/management/product/state/supplier-product-available-statuses/supplier-product-available-status.model';
import {
  SupplierProductAvailableStatusesQuery
} from '@/management/product/state/supplier-product-available-statuses/supplier-product-available-statuses.query';
import {
  SupplierProductAvailableStatusesService
} from '@/management/product/state/supplier-product-available-statuses/supplier-product-available-statuses.service';
import {
  createSupplierProduct,
  SupplierProduct
} from '@/management/product/state/supplier-products/supplier-product.model';
import {SuppliersQuery} from '@/management/supplier/state/suppliers.query';
import {SuppliersService} from '@/management/supplier/state/suppliers.service';
import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {DqnAutocompleteOption} from '@dqn/components-library/autocomplete';
import {Observable} from 'rxjs';
import {BillingFrequenciesQuery} from '../../../../state/billing-frequencies/billing-frequencies.query';
import {BillingFrequenciesService} from '../../../../state/billing-frequencies/billing-frequencies.service';
import {BillingFrequency} from '../../../../state/billing-frequencies/billing-frequency.model';
import {CurrencyCode} from '../../../../state/currency-codes/currency-code.model';
import {CurrencyCodesQuery} from '../../../../state/currency-codes/currency-codes.query';
import {CurrencyCodesService} from '../../../../state/currency-codes/currency-codes.service';
import {
  billingFrequencyPriceNotEmptyThenCurrencyCodeIdAndBillingFrequencyIdRequiredValidator,
  unitPriceNotEmptyThenCurrencyCodeIdRequiredValidator,
  unitPriceOrBillingFrequencyPriceRequiredValidator
} from '../../../../validators';

export type SupplierProductFormDialogData = {
  supplierProduct: SupplierProduct;
};

export type SupplierProductFormDialogOutput = SupplierProduct | undefined;

@Component({
  selector: 'app-supplier-product-form-dialog',
  templateUrl: './supplier-product-form-dialog.component.html',
  styleUrls: ['./supplier-product-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierProductFormDialogComponent implements OnInit {
  suppliers$!: Observable<DqnAutocompleteOption[]>;
  supplierAvailableStatuses$!: Observable<SupplierProductAvailableStatus[]>;
  currencyCodes$: Observable<CurrencyCode[]>;
  billingFrequencies$: Observable<BillingFrequency[]>;
  quantityUnits$: Observable<QuantityUnit[]>;

  supplierProductForm!: UntypedFormGroup;

  get supplierProduct() {
    return this.data.supplierProduct;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: SupplierProductFormDialogData,
    private dialogRef: MatDialogRef<SupplierProductFormDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private suppliersQuery: SuppliersQuery,
    private suppliersService: SuppliersService,
    private currencyCodesQuery: CurrencyCodesQuery,
    private currencyCodesService: CurrencyCodesService,
    private billingFrequenciesQuery: BillingFrequenciesQuery,
    private billingFrequenciesService: BillingFrequenciesService,
    private quantityUnitsQuery: QuantityUnitsQuery,
    private quantityUnitsService: QuantityUnitsService,
    private supplierProductAvailableStatusesQuery: SupplierProductAvailableStatusesQuery,
    private supplierProductAvailableStatusesService: SupplierProductAvailableStatusesService,
    private sessionQuery: SessionQuery,
  ) {
  }

  ngOnInit() {
    this.initialize();

    this.quantityUnitsService.get().subscribe();
    this.billingFrequenciesService.get().subscribe();
    this.currencyCodesService.get().subscribe();
    this.supplierProductAvailableStatusesService.get().subscribe();
    this.suppliersService.get().subscribe();
  }

  updateUnitPrice(unitPrice: number) {
    this.supplierProductForm.get('unitPriceGroup.unitPrice').patchValue(unitPrice);
  }

  updateBillingFrequencyPrice(billingFrequencyPrice: number) {
    this.supplierProductForm.get('billingFrequencyPriceGroup.billingFrequencyPrice').patchValue(billingFrequencyPrice);
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (!this.supplierProductForm.valid) {
      return;
    }

    const unitPriceValue = this.supplierProductForm.get('unitPriceGroup.unitPrice').value;
    const billingFrequencyPriceValue = this.supplierProductForm.get('billingFrequencyPriceGroup.billingFrequencyPrice').value;

    this.dialogRef.close(createSupplierProduct({
      ...this.supplierProduct,
      tenant_id: this.sessionQuery.tenantId,
      supplier_order_number: this.supplierProductForm.get('supplierOrderNumber').value,
      // Add supplier model to use with autocomplete field
      supplier_id: this.supplierProductForm.get('supplierId').value,
      unit_price: unitPriceValue !== null && unitPriceValue >= 0 ? unitPriceValue * 100 : null,
      currency_code_id: this.supplierProductForm.get('unitPriceGroup.currencyCodeId').value,
      billing_frequency_price: billingFrequencyPriceValue !== null && billingFrequencyPriceValue >= 0 ? billingFrequencyPriceValue * 100 : null,
      billing_frequency_currency_code_id: this.supplierProductForm.get('billingFrequencyPriceGroup.billingFrequencyCurrencyCodeId').value,
      billing_frequency_id: this.supplierProductForm.get('billingFrequencyPriceGroup.billingFrequencyId').value,
      status_id: this.supplierProductForm.get('statusId').value,
      promoted_order_number: this.supplierProductForm.get('isPromoted').value ? 1 : null,
    }));
  }

  private initialize() {
    this.initializeForm();

    this.suppliers$ = this.suppliersQuery.suppliersAsDqnAutocompleteOptions$;
    this.currencyCodes$ = this.currencyCodesQuery.currencyCodes$;
    this.billingFrequencies$ = this.billingFrequenciesQuery.billingFrequencies$;
    this.quantityUnits$ = this.quantityUnitsQuery.quantityUnits$;
    this.supplierAvailableStatuses$ = this.supplierProductAvailableStatusesQuery.supplierProductAvailableStatuses$;
  }

  private initializeForm() {
    this.supplierProductForm = this.formBuilder.group({
        statusId: [
          null,
          [Validators.required],
        ],
        supplierId: [
          null,
          [Validators.required],
        ],
        supplierOrderNumber: [
          '',
          [Validators.required],
        ],
        unitPriceGroup: this.formBuilder.group({
            unitPrice: null,
            currencyCodeId: null,
          },
          {validators: unitPriceNotEmptyThenCurrencyCodeIdRequiredValidator}
        ),
        billingFrequencyPriceGroup: this.formBuilder.group({
            billingFrequencyPrice: null,
            billingFrequencyCurrencyCodeId: null,
            billingFrequencyId: null,
          },
          {validators: billingFrequencyPriceNotEmptyThenCurrencyCodeIdAndBillingFrequencyIdRequiredValidator}
        ),
        isPromoted: [
          null,
        ]
      },
      {validators: unitPriceOrBillingFrequencyPriceRequiredValidator}
    );

    if (this.supplierProduct !== null) {
      const {
        supplier_id,
        status_id,
        supplier_order_number,
        unit_price,
        currency_code_id,
        billing_frequency_price,
        billing_frequency_currency_code_id,
        billing_frequency_id,
        promoted_order_number
      } = this.supplierProduct;

      this.supplierProductForm.patchValue({
        statusId: status_id,
        supplierId: supplier_id,
        supplierOrderNumber: supplier_order_number,
        unitPriceGroup: {
          unitPrice: (unit_price !== null && unit_price >= 0) ? unit_price / 100 : null,
          currencyCodeId: currency_code_id,
        },
        billingFrequencyPriceGroup: {
          billingFrequencyPrice: (billing_frequency_price !== null && billing_frequency_price >= 0) ? billing_frequency_price / 100 : null,
          billingFrequencyCurrencyCodeId: billing_frequency_currency_code_id,
          billingFrequencyId: billing_frequency_id,
        },
        isPromoted: promoted_order_number !== null
      });
    }
  }
}
