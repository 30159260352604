<mat-card>
  <img mat-card-image [src]="imageSourcePath" />
  <mat-card-content>
    <div class="mat-subheading-2">Benötigen Sie Hilfe?</div>
    <div class="mat-subheading-1">
      Unser strategischer Einkauf steht jederzeit für Fragen und Unterstützung
      zur Verfügung.
    </div>
  </mat-card-content>

  <mat-card-actions>
      <a
        mat-button
        href="mailto:{{(sessionQuery.tenantSupportContactMail$ | async)}}">
          E-Mail
      </a>
  </mat-card-actions>
</mat-card>
