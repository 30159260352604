import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProductRouteNames} from './constants/routes.constants';
import {ProductDetailsComponent} from './pages/details/product-details.component';
import {ProposalComponent} from './pages/proposal/proposal.component';

const routes: Routes = [
  {
    path: ProductRouteNames.PROPOSAL,
    component: ProposalComponent,
  },
  {
    path: ProductRouteNames.DETAILS,
    component: ProductDetailsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductRoutingModule {
}
