import {SharedModule as GlobalSharedModule} from '@/shared';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ProductUnitDisplayTextPipe} from '../shared/pipes/product-unit-display-text.pipe';

@NgModule({
  imports: [
    CommonModule,
    GlobalSharedModule,
  ],
  declarations: [
    ProductUnitDisplayTextPipe,
  ],
  exports: [
    ProductUnitDisplayTextPipe,
  ],
})
export class SharedModule {
}
