import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Product} from '../../state/product.model';

@Component({
  selector: 'app-product-gallery',
  templateUrl: './product-gallery.component.html',
  styleUrls: ['./product-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductGalleryComponent {
  @Input() products!: Product[];

  trackByProductId(index: number, product: Product) {
    return product.id;
  }
}
