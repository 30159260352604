import {Tenant} from '@/core/session/state/tenant/tenant.model';
import {User} from '@/core/session/state/user/user.model';
import {CountryCode} from '@/shared/state/country-codes/country-code.model';
import {CurrencyCode} from '@/shared/state/currency-codes/currency-code.model';
import {LanguageCode} from '@/shared/state/language-codes/language-code.model';
import {Status} from '@/shared/state/status/status.model';
import {WithTimestamps} from '@/shared/types/api/with-timestamps';
import {SupplierProduct} from '../../product/state/supplier-products/supplier-product.model';

export type SupplierIncludes = {
  created_by?: User;
  pivot?: SupplierProduct;
}

export interface Supplier extends SupplierIncludes, WithTimestamps {
  id: number;
  tenant_id: Tenant['id'];
  status_id: Status['id'];
  ext_id?: string;
  name: string;
  name_2?: string;
  account_number?: string;
  street: string;
  house_number: string;
  additional_address_information?: string;
  postal_code: string;
  city: string;
  country_code_id: CountryCode['id'];
  language_code_id?: LanguageCode['id'];
  gln?: string;
  email: string;
  homepage?: string;
  description?: string;
  created_by_id?: User['id'];
  // "Mindestbestellwert" & "Mindermengenzuschlag"
  minimum_order_value: number | null;
  minimum_order_value_currency_code_id: CurrencyCode['id'] | null;
  minimum_order_value_surcharge: number | null;
  minimum_order_value_surcharge_currency_code_id: CurrencyCode['id'] | null;
  // Shipping costs
  shipping_costs_minimum_order_value: number | null; // This is the value from which shipping is free.
  shipping_costs_minimum_order_value_currency_code_id: CurrencyCode['id'] | null;
  shipping_costs: number | null;
  shipping_costs_currency_code_id: CurrencyCode['id'] | null;

  toll_charge: number | null;
  toll_charge_currency_code_id: CurrencyCode['id'] | null;

  additional_costs: number | null;
  additional_costs_currency_code_id: CurrencyCode['id'] | null;
  additional_costs_description: string;
}

export const createSupplier = (params: Partial<Supplier>) => ({...params} as Supplier);
