import {SessionQuery} from '@/core/session/state/session.query';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {createHttpIncludes, getHttpOptionsWithInclude} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {setLoading} from '@datorama/akita';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {ProductAttributeTemplate} from './product-attribute-template.model';
import {ProductAttributeTemplatesStore} from './product-attribute-templates.store';

@Injectable({providedIn: 'root'})
export class ProductAttributeTemplatesService {
  static readonly includes = createHttpIncludes([
    'productAttributeType',
    'quantityUnit',
  ]);

  private readonly productAttributeTemplatesStore = inject(ProductAttributeTemplatesStore);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);

  get() {
    const options = getHttpOptionsWithInclude(ProductAttributeTemplatesService.includes, {
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .get<ApiResponse<ProductAttributeTemplate[]>>(environment.api.baseUrl + 'product-attribute-templates', options)
      .pipe(
        setLoading(this.productAttributeTemplatesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.productAttributeTemplatesStore)),
        tap((response) => {
          this.productAttributeTemplatesStore.set(response['data']);
        }),
      );
  }
}
