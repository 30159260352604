import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {CostCenter} from './cost-center.model';

export interface CostCentersState extends EntityState<CostCenter>, ActiveState {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'costCenters'})
export class CostCentersStore extends EntityStore<CostCentersState> {
  constructor() {
    super({loading: false});
  }
}
