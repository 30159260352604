import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'priceToHighestCurrencyValue'
})
export class PriceToHighestCurrencyValuePipe implements PipeTransform {
  /**
   * Transforms price from the lowest currency value to the highest currency by dividing by 100.
   * For Example: cent to € or penny to £
   *
   * @param price
   */
  transform(price: number): number {
    return isNaN(price) ? null : price / 100;
  }
}
