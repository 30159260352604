import {Directive, Input} from '@angular/core';
import {FormMode} from '../enums/form-mode.enum';

@Directive({
  selector: '[appFormMode]',
  standalone: true,
})
export class FormModeDirective {
  @Input({alias: 'formMode', required: true}) mode: FormMode;

  get isReadonly() {
    return this.isDetails && !(this.isEdit || this.isCreate);
  }

  get isEdit() {
    return this.mode === FormMode.edit;
  }

  get isDetails() {
    return this.mode === FormMode.details;
  }

  get isCreate() {
    return this.mode === FormMode.create;
  }

  get isProposal() {
    return this.mode === FormMode.proposal;
  }
}

export const FormModeHostDirective = {
  directive: FormModeDirective,
  inputs: ['formMode'],
};
