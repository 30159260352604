import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {BreadCrumb} from './types/bread-crumb';

@Component({
  selector: 'app-bread-crumbs',
  templateUrl: './bread-crumbs.component.html',
  styleUrls: ['./bread-crumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadCrumbsComponent {
  @Input({required: true}) breadCrumbs: BreadCrumb[];
}
