import {SuppliersService} from '@/management/supplier/state/suppliers.service';
import {getMatDialogConfig} from '@/shared/utils/functions/mat-dialog';
import {ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {finalize, forkJoin, Observable, of} from 'rxjs';
import {filter, switchMap, take, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {ShoppingCartService} from '../../../shopping-cart/state/shopping-cart.service';
import {
  ProductAttributeTemplatesService
} from '../../state/product-attribute-templates/product-attribute-templates.service';
import {Product} from '../../state/product.model';
import {ProductsQuery} from '../../state/products.query';
import {ProductsService} from '../../state/products.service';
import {SupplierProduct, SupplierProductWithRank} from '../../state/supplier-products/supplier-product.model';
import {SupplierProductsQuery} from '../../state/supplier-products/supplier-products.query';
import {SupplierProductsService} from '../../state/supplier-products/supplier-products.service';
import {VariantAttributesService} from '../../state/variant-attributes/variant-attributes.service';
import {
  SelectSupplierProductForShoppingCartDialogComponent
} from '../dialogs/select-supplier-product-for-shopping-cart-dialog/select-supplier-product-for-shopping-cart-dialog.component';
import {
  SelectSupplierProductForShoppingCartDialogData
} from '../dialogs/select-supplier-product-for-shopping-cart-dialog/types/select-supplier-product-for-shopping-cart-dialog-data';
import {
  SelectVariantForShoppingCartDialogComponent
} from '../dialogs/select-variant-for-shopping-cart-dialog/select-variant-for-shopping-cart-dialog.component';
import {
  SelectVariantForShoppingCartDialogData
} from '../dialogs/select-variant-for-shopping-cart-dialog/types/select-variant-for-shopping-cart-dialog-data';

@Component({
  selector: 'app-product-gallery-item',
  templateUrl: './product-gallery-item.component.html',
  styleUrls: ['./product-gallery-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductGalleryItemComponent implements OnInit {
  readonly supplierProductsQuery = inject(SupplierProductsQuery);
  readonly productsQuery = inject(ProductsQuery);
  readonly productsService = inject(ProductsService);
  private readonly variantAttributesService = inject(VariantAttributesService);
  private readonly productAttributeTemplatesService = inject(ProductAttributeTemplatesService);
  private readonly suppliersService = inject(SuppliersService);
  private readonly supplierProductsService = inject(SupplierProductsService);

  private readonly matSnackBar = inject(MatSnackBar);
  private readonly matDialog = inject(MatDialog);
  private readonly destroyRef = inject(DestroyRef);
  private readonly shoppingCartService = inject(ShoppingCartService);

  @Input({required: true}) product!: Product;

  @Input() rank: SupplierProductWithRank['rank'] = null;
  @Input() supplierProductId: SupplierProduct['id'] = null;

  get isShopActive() {
    return environment.shopActive;
  }

  get hasMultipleUnitPrices() {
    return this.product.price_unit_price_from !== this.product.price_unit_price_to;
  }

  get hasMultipleBillingFrequencyPrices() {
    return this.product.price_billing_frequency_price_from !== this.product.price_billing_frequency_price_to;
  }

  get supplierProductIdForRoute() {
    return this.supplierProductId || this.product.preferred_supplier_product_id;
  }

  get hasVariants() {
    return this.product.variant_count && this.product.variant_count > 0;
  }

  productIsLoading$!: Observable<boolean>;

  ngOnInit() {
    this.productIsLoading$ = this.productsQuery.selectProductIsLoading(this.product.id);
  }

  toShoppingCart() {
    // TODO: Could be moved to service to keep component dumb
    // As long as there is only one supplier product (variant or normal product) we can add the preferred supplier product directly to the shopping cart
    if (this.product.supplier_product_count === 1) {
      this.addSupplierProductToShoppingCart(this.product.preferred_supplier_product_id);
      return;
    }

    of(this.product).pipe(
      take(1),
      // Ignore product if it has no supplier products (would be a misconfiguration)
      filter(product => product.supplier_product_count > 1),
      tap(() => this.productsService.setActiveProduct(this.product.id)),
      switchMap(product => this.getRequestsForShoppingCartDialog(product)),
      switchMap(() => {
        if (this.product.is_virtual && this.product.variant_count > 0) {
          return this.openDialogForVariants();
        }

        return this.openDialogForSupplierProducts();
      }),
      tap(result => this.addSupplierProductToShoppingCart(result?.supplierProduct?.id)),
      finalize(() => this.productsService.setActiveProduct(null)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  private addSupplierProductToShoppingCart(supplierProductId: SupplierProduct['id'] | undefined) {
    if (!supplierProductId) {
      return;
    }

    this.shoppingCartService.addProductToCart(supplierProductId, 1);
    this.matSnackBar.open('Produkt wurde dem Warenkorb hinzugefügt!', 'Schließen', {
      panelClass: 'snackbar-success',
    });
  }

  private getRequestsForShoppingCartDialog({id, supplier_product_count, variant_count, is_virtual}: Product) {
    const requests: Observable<unknown>[] = [];

    if (is_virtual && variant_count > 0) {
      // Fetch all variants for product
      requests.push(
        this.productsService.getVariantsForProduct(id),
        this.variantAttributesService.getForProduct(id),
        this.supplierProductsService.getForProduct(id),
        this.productAttributeTemplatesService.get(),
      );
    } else if (!is_virtual && supplier_product_count > 1) {
      // Fetch product and fill supplier products based on include
      requests.push(
        this.supplierProductsService.getForProduct(id),
        this.suppliersService.get(),
      );
    }

    return forkJoin(requests);
  }

  private openDialogForVariants() {
    const preferredVariant = this.supplierProductsQuery.getSupplierProductById(this.product.preferred_supplier_product_id)?.product_id;
    const variants = this.productsQuery.getVariantsForProduct(this.product.id);

    const dialogConfig = getMatDialogConfig<SelectVariantForShoppingCartDialogData>({
      data: {
        product: this.product,
        selectedVariantId: preferredVariant ?? variants[0]?.id,
        variants,
      },
      minWidth: '700px',
    });

    return this.matDialog.open(SelectVariantForShoppingCartDialogComponent, dialogConfig).afterClosed();
  }

  private openDialogForSupplierProducts() {
    const dialogConfig = getMatDialogConfig<SelectSupplierProductForShoppingCartDialogData>({
      data: {
        product: this.product,
        supplierProducts: this.supplierProductsQuery.getSupplierProductsWithSameBaseProduct(this.product.id),
      }
    });

    return this.matDialog.open(SelectSupplierProductForShoppingCartDialogComponent, dialogConfig).afterClosed();
  }
}
