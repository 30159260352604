<ol class="flex gap-1">
    <li
      *ngFor="let breadCrumb of breadCrumbs; let last=last"
      appBreadCrumbItem
      class="list-none"
      [class.last-bread-crumb]="last">
        <a [routerLink]="breadCrumb.url">
            {{ breadCrumb.label }}
        </a>
    </li>

    <ng-content select="li[appBreadCrumbItem]"/>
</ol>
