import {Supplier} from '@/management/supplier/state/supplier.model';
import {SuppliersQuery} from '@/management/supplier/state/suppliers.query';
import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';

@Pipe({
  name: 'supplierById'
})
export class SupplierByIdPipe implements PipeTransform {
  constructor(
    private suppliersQuery: SuppliersQuery,
  ) {
  }

  transform(supplierId: number, ...args: any[]): Observable<Supplier> {
    return this.suppliersQuery.selectSupplierById(supplierId);
  }
}
