<div class="favorite-icon-container">
    <button
      class="favorite-icon"
      mat-mini-fab
      aria-label="Teilen eines Produktes über eine E-Mail"
      (click)="shareSupplierProduct()"
      matTooltip="Mit Kollegen teilen">
        <mat-icon fontSet="material-icons-outlined">share</mat-icon>
    </button>
</div>
