<div class="flex justify-end items-center">
    <mat-icon
      class="close-button"
      [mat-dialog-close]="null"
      matTooltip="Abbrechen">close
    </mat-icon>
</div>

<mat-dialog-content>
    <div class="dialog-container mat-typography flex flex-col">
        <app-supplier-products-product-list
          [supplierProducts]="data.supplierProducts"
          [product]="data.product"
          [allowNavigation]="true"
          (onAddToShoppingCart)="addToShoppingCart($event)"
          (onNavigateToSupplierProduct)="cancel()" />
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
      mat-button
      color="primary"
      (click)="cancel()">Abbrechen
    </button>
</mat-dialog-actions>
