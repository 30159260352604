import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {OrderApprovalWorkflowFormData} from '../../order/state/order.model';
import {SupplierProduct} from '../../product/state/supplier-products/supplier-product.model';
import {ShoppingCartItem} from './shopping-cart.model';

export interface ShoppingCartState extends EntityState<ShoppingCartItem> {
  comments: string;
  approvalWorkflowData: OrderApprovalWorkflowFormData;
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'shoppingCart', idKey: 'supplier_product_id', resettable: true})
export class ShoppingCartStore extends EntityStore<ShoppingCartState> {

  constructor() {
    super({
      loading: false,
      comments: '',
      approvalWorkflowData: null
    });
  }

  updateQuantity(productId: SupplierProduct['id'], quantity: number) {
    this.update(productId, entity => {
      return {
        ...entity,
        quantity: quantity
      };
    });
  }

  updateApprovalWorkflow(productId: SupplierProduct['id'], costCenterId: ShoppingCartItem['cost_center_id']) {
    this.update(productId, entity => {
      return {
        ...entity,
        cost_center_id: costCenterId
      };
    });

    this.updateApprovalWorkflowMapping(productId, costCenterId);
  }

  private updateApprovalWorkflowMapping(productId: SupplierProduct['id'], costCenterId: ShoppingCartItem['cost_center_id']) {
    this.update(state => {
      let costCenterMappings = state.approvalWorkflowData?.individual_cost_center_mapping ?? [];

      // Remove supplierProduct from existing mappings
      costCenterMappings = costCenterMappings.map(mapping => ({
        cost_center_id: mapping.cost_center_id,
        supplier_product_ids: mapping.supplier_product_ids.filter(id => id !== productId)
      }));

      if (costCenterId) { // Don't add mapping if costCenterId is null
        // Add map entry for cost center if not exists
        if (!costCenterMappings.some(mapping => mapping.cost_center_id === costCenterId)) {
          costCenterMappings = [
            ...costCenterMappings,
            {
              cost_center_id: costCenterId,
              supplier_product_ids: []
            }
          ];
        }

        // Add supplierProduct to new costCenter mapping
        costCenterMappings = costCenterMappings.map(mapping => {
          if (mapping.cost_center_id == costCenterId) {
            mapping.supplier_product_ids = [
              ...mapping.supplier_product_ids,
              productId
            ];
          }

          return mapping;
        });
      }
      // Remove empty mappings
      costCenterMappings = costCenterMappings.filter(mapping => mapping?.supplier_product_ids.length);


      return {
        approvalWorkflowData: {
          ...state.approvalWorkflowData,
          individual_cost_center_mapping: costCenterMappings
        }
      }
    });
  }
}
