import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';

/** Unit price or billing frequency price one of them is required */
export const billingFrequencyPriceNotEmptyThenCurrencyCodeIdAndBillingFrequencyIdRequiredValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const billingFrequencyPriceControl = control.get('billingFrequencyPrice');
  const billingFrequencyCurrencyCodeIdControl = control.get('billingFrequencyCurrencyCodeId');
  const billingFrequencyIdControl = control.get('billingFrequencyId');

  const billingFrequencyPriceControlValueIsEmpty = billingFrequencyPriceControl?.value == null || billingFrequencyPriceControl?.value === '';
  const billingFrequencyCurrencyCodeIdControlValueIsEmpty = billingFrequencyCurrencyCodeIdControl?.value == null || billingFrequencyCurrencyCodeIdControl?.value === '';
  const billingFrequencyIdControlValueIsEmpty = billingFrequencyIdControl?.value == null || billingFrequencyIdControl?.value?.value === '';

  return billingFrequencyPriceControlValueIsEmpty || (!billingFrequencyPriceControlValueIsEmpty && !billingFrequencyCurrencyCodeIdControlValueIsEmpty && !billingFrequencyIdControlValueIsEmpty) ? null : {'required': true};
};

@Directive({
  selector: '[appBillingFrequencyPriceNotEmptyThenCurrencyCodeIdAndBillingFrequencyIdRequired]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: BillingFrequencyPriceNotEmptyThenCurrencyCodeIdAndBillingFrequencyIdRequiredValidatorDirective,
    multi: true
  }]
})
export class BillingFrequencyPriceNotEmptyThenCurrencyCodeIdAndBillingFrequencyIdRequiredValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return billingFrequencyPriceNotEmptyThenCurrencyCodeIdAndBillingFrequencyIdRequiredValidator(control);
  }
}
