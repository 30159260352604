import {ProductAttribute} from '../../product-attributes/product-attribute.model';
import {VariantAttribute} from '../variant-attribute.model';

export const findVariantAttributeOptionForProductAttribute = (
  variantAttributes: VariantAttribute[],
  productAttribute: ProductAttribute,
) => {
  for (const variantAttribute of variantAttributes) {
    if (variantAttribute.product_attribute_template_id !== productAttribute.product_attribute_template_id) {
      continue;
    }

    for (const variantAttributeOption of variantAttribute.variant_attribute_options) {
      if (variantAttributeOption.value === productAttribute.value) {
        return variantAttributeOption;
      }
    }
  }

  return null;
}
