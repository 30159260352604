export const toCamelCase = (string: string) => string.replace(/[-_]+/g, ' ')
  .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => index === 0 ? letter.toLowerCase() : letter.toUpperCase())
  .replace(/\s+/g, '');

export const toSnakeCase = (string: string) => string
  .replace(/([a-z])([A-Z])/g, '$1_$2')
  .replace(/-/g, '_')
  .replace(/[^a-zA-Z0-9_]/g, '_')
  .toLowerCase()
  .replace(/_+/g, '_')
  .replace(/^_+|_+$/g, '');
