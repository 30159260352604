<form
  [formGroup]="supplierForm"
  (ngSubmit)="onSubmit()">
    <mat-card class="form">
        <mat-card-content>
            <mat-expansion-panel
              class="mat-elevation-z0 no-border"
              expanded="true">
                <mat-expansion-panel-header>
                    <mat-panel-title class="mt-2">
                        <div class="mat-title">Allgemein</div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="flex">
                    <mat-form-field class="flex-1">
                        <mat-label>Name</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="name"
                          placeholder="Name"
                          autofocus
                          maxlength="100"
                          required/>
                        <mat-error *ngIf="supplierForm.controls.name.hasError('maxlength')">
                            Der Name darf nicht mehr als 100 Zeichen lang sein.
                        </mat-error>
                        <mat-error *ngIf="supplierForm.controls.name.hasError('required')">
                            Bitte geben Sie einen Namen ein.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex">
                    <mat-form-field class="flex-1">
                        <mat-label>Name (2)</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="name2"
                          placeholder="Name (2)"
                          maxlength="100"/>
                        <mat-error *ngIf="supplierForm.controls.name2.hasError('maxlength')">
                            Der Name (2) darf nicht mehr als 100 Zeichen lang sein.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex">
                    <mat-form-field class="flex-1">
                        <mat-label>Sprache</mat-label>
                        <mat-select
                          formControlName="languageCodeId"
                          name="language">
                            <mat-option
                              *ngFor="let languageCode of languageCodes$ | async"
                              [value]="languageCode.id">
                                {{ languageCode.display_name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="flex">
                    <mat-form-field class="flex-1">
                        <mat-label>E-Mail-Adresse</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="email"
                          placeholder="E-Mail-Adresse"
                          maxlength="254"
                          required/>
                        <mat-error *ngIf="supplierForm.controls.email.hasError('email')">
                            Bitte geben Sie gültige E-Mail-Adressen ein.
                        </mat-error>
                        <mat-error *ngIf="supplierForm.controls.email.hasError('maxlength')">
                            Der E-Mail-Adresse darf nicht mehr als 254 Zeichen lang sein.
                        </mat-error>
                        <mat-error *ngIf="supplierForm.controls.email.hasError('required')">
                            Bitte geben Sie eine E-Mail-Adresse ein.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex">
                    <mat-form-field class="flex-1">
                        <mat-label>Website</mat-label>
                        <input
                          matInput
                          type="url"
                          formControlName="homepage"
                          maxlength="255"
                          [pattern]="environment.regularExpressions.websiteBaseUrl"
                          placeholder="Website"/>
                        <mat-error *ngIf="supplierForm.controls.homepage.hasError('maxlength')">
                            Der Website darf nicht mehr als 255 Zeichen lang sein.
                        </mat-error>
                        <mat-error *ngIf="supplierForm.controls.homepage.hasError('pattern')">
                            Bitte geben Sie eine gültige Website an.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex">
                    <mat-form-field class="flex-1">
                        <mat-label>GLN</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="gln"
                          placeholder="GLN"
                          maxlength="13"/>
                        <mat-error *ngIf="supplierForm.controls.gln.hasError('maxlength')">
                            Die GLN darf nicht mehr als 13 Zeichen lang sein.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex">
                    <mat-form-field class="flex-1">
                        <mat-label>Kundennummer</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="accountNumber"
                          placeholder="Kundennummer"
                          maxlength="100"/>
                        <mat-error *ngIf=" supplierForm.controls.accountNumber.hasError('maxlength')">
                            Der Kundennummer darf nicht mehr als 100 Zeichen lang sein.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex">
                    <mat-form-field class="flex-1">
                        <mat-label>Externe Nummer</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="extId"
                          placeholder="Externe Nummer"
                          maxlength="100"/>
                        <mat-error *ngIf="supplierForm.controls.extId.hasError('maxlength')">
                            Der externe Nummer darf nicht mehr als 100 Zeichen lang sein.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex">
                    <mat-form-field class="flex-1">
                        <mat-label>Beschreibung</mat-label>
                        <textarea
                          matInput
                          #description
                          formControlName="description"
                          placeholder="Beschreibung"
                          maxlength="1200"
                          rows="5">
                        </textarea>
                        <mat-hint align="end">
                            {{ description.value.length }} / 1200 Zeichen
                        </mat-hint>
                        <mat-error *ngIf="supplierForm.controls.description.hasError('maxlength')">
                            Die Beschreibung darf nicht mehr als 1200 Zeichen lang sein.
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel
              class="mat-elevation-z0 no-border"
              expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title class="mt-2">
                        <div class="mat-title">Adresse</div>
                    </mat-panel-title>
                </mat-expansion-panel-header>


                <div class="flex gap-2 items-start">
                    <mat-form-field class="flex-1 max-w-[66%]">
                        <mat-label>Straße</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="street"
                          placeholder="Straße"
                          maxlength="50"
                          required/>
                        <mat-error *ngIf="supplierForm.controls.street.hasError('maxlength')">
                            Die Straße darf nicht mehr als 50 Zeichen lang sein.
                        </mat-error>
                        <mat-error *ngIf="supplierForm.controls.street.hasError('required')">
                            Bitte geben Sie eine Straße ein.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="flex w-[33%]">
                        <mat-label>Hausnummer</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="houseNumber"
                          placeholder="Hausnummer"
                          maxlength="10"
                          required/>
                        <mat-error *ngIf="supplierForm.controls.houseNumber.hasError('maxlength')">
                            Die Hausnummer ist zu lang.
                        </mat-error>
                        <mat-error *ngIf="supplierForm.controls.houseNumber.hasError('required')">
                            Bitte Hausnummer eingeben.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex gap-2 items-start">
                    <mat-form-field class="flex-1">
                        <mat-label>Adresszusatz</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="additionalAddressInformation"
                          placeholder="Adresszusatz"
                          maxlength="100"/>
                        <mat-error *ngIf="supplierForm.controls.additionalAddressInformation.hasError('maxlength')">
                            Der Adresszusatz darf nicht mehr als 100 Zeichen lang sein.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex gap-2 items-start">
                    <mat-form-field class="w-[40%]">
                        <mat-label>PLZ</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="postalCode"
                          placeholder="PLZ"
                          maxlength="20"
                          required/>
                        <mat-error *ngIf="supplierForm.controls.postalCode.hasError('maxlength')">
                            Die PLZ darf nicht mehr als 20 Zeichen lang sein.
                        </mat-error>
                        <mat-error *ngIf=" supplierForm.controls.postalCode.hasError('required')">
                            Bitte geben Sie eine PLZ ein.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-[60%]">
                        <mat-label>Stadt</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="city"
                          placeholder="Stadt"
                          maxlength="50"
                          required/>
                        <mat-error *ngIf=" supplierForm.controls.city.hasError('maxlength')">
                            Der Stadt darf nicht mehr als 50 Zeichen lang sein.
                        </mat-error>
                        <mat-error
                          *ngIf="supplierForm.controls.city.hasError('required')">
                            Bitte geben Sie einen Stadt ein.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex">
                    <mat-form-field class="flex-1">
                        <mat-label>Land</mat-label>
                        <mat-select
                          formControlName="countryCodeId"
                          name="country"
                          required>
                            <mat-option
                              *ngFor="let countryCode of countryCodes$ | async"
                              [value]="countryCode.id">
                                {{ countryCode.display_name }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="supplierForm.controls.countryCodeId.hasError('required')">
                            Bitte wählen Sie ein Land aus.
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel
              class="mat-elevation-z0 no-border"
              expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title class="mt-2">
                        <div class="mat-title">Versandkosten & Zuschläge</div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="flex gap-2 items-start">
                    <app-mat-input-number
                      [required]="isMinimumOrderValueRequired"
                      [disabled]="supplierForm.controls.minimumOrderValue.disabled"
                      label="Mindestbestellwert"
                      [value]="supplierForm.controls.minimumOrderValue.value"
                      (onChanged)="supplierForm.controls.minimumOrderValue.setValue($event)"
                      (onTouched)="supplierForm.controls.minimumOrderValue.markAsTouched()"
                      [error]="hasMinimumOrderValueRequiredError ? 'Mindestbestellwert muss ausgefüllt sein.' : ''"
                      class="flex-1"/>

                    <mat-form-field
                      class="w-[15%]"
                      color="accent">
                        <mat-label>Währung</mat-label>
                        <mat-select
                          formControlName="minimumOrderValueCurrencyCodeId"
                          [required]="(supplierForm.controls.minimumOrderValue.value | isNullOrUndefined) === false">
                            <mat-option
                              *ngFor="let currencyCode of currencyCodesQuery.currencyCodes$ | async"
                              [value]="currencyCode.id">
                                {{ currencyCode.display_name }}
                            </mat-option>
                        </mat-select>

                        <mat-error *ngIf="getFirstErrorOfProperty('minimumOrderValueCurrencyCodeId')">
                            {{ getFirstErrorOfProperty('minimumOrderValueCurrencyCodeId') }}
                        </mat-error>
                    </mat-form-field>

                    <app-mat-input-number
                      [required]="isMinimumOrderValueSurchargeRequired"
                      [disabled]="supplierForm.controls.minimumOrderValueSurcharge.disabled"
                      label="Mindermengenzuschlag"
                      [value]="supplierForm.controls.minimumOrderValueSurcharge.value"
                      (onChanged)="supplierForm.controls.minimumOrderValueSurcharge.setValue($event)"
                      (onTouched)="supplierForm.controls.minimumOrderValueSurcharge.markAsTouched()"
                      [error]="hasMinimumOrderValueSurchargeRequiredError ? 'Mindermengenzuschlag muss ausgefüllt sein.' : ''"
                      class="flex-1 ml-5"/>

                    <mat-form-field
                      class="w-[15%]"
                      color="accent">
                        <mat-label>Währung</mat-label>
                        <mat-select
                          formControlName="minimumOrderValueSurchargeCurrencyCodeId"
                          [required]="(supplierForm.controls.minimumOrderValueSurcharge.value | isNullOrUndefined) === false">
                            <mat-option
                              *ngFor="let currencyCode of currencyCodesQuery.currencyCodes$ | async"
                              [value]="currencyCode.id">
                                {{ currencyCode.display_name }}
                            </mat-option>
                        </mat-select>

                        <mat-error *ngIf="getFirstErrorOfProperty('minimumOrderValueSurchargeCurrencyCodeId')">
                            {{ getFirstErrorOfProperty('minimumOrderValueSurchargeCurrencyCodeId') }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex gap-2 items-start">
                    <app-mat-input-number
                      [required]="false"
                      [disabled]="supplierForm.controls.shippingCostsMinimumOrderValue.disabled"
                      label="Mindestbestellwert für Versandkostenfrei"
                      [value]="supplierForm.controls.shippingCostsMinimumOrderValue.value"
                      (onChanged)="supplierForm.controls.shippingCostsMinimumOrderValue.setValue($event)"
                      (onTouched)="supplierForm.controls.shippingCostsMinimumOrderValue.markAsTouched()"
                      [error]="getFirstErrorOfProperty('shippingCostsMinimumOrderValue')"
                      class="flex-1"/>

                    <mat-form-field
                      class="w-[15%]"
                      color="accent">
                        <mat-label>Währung</mat-label>
                        <mat-select
                          formControlName="shippingCostsMinimumOrderValueCurrencyCodeId"
                          [required]="(supplierForm.controls.shippingCostsMinimumOrderValue.value | isNullOrUndefined) === false">
                            <mat-option
                              *ngFor="let currencyCode of currencyCodesQuery.currencyCodes$ | async"
                              [value]="currencyCode.id">
                                {{ currencyCode.display_name }}
                            </mat-option>
                        </mat-select>

                        <mat-error *ngIf="getFirstErrorOfProperty('shippingCostsMinimumOrderValueCurrencyCodeId')">
                            {{ getFirstErrorOfProperty('shippingCostsMinimumOrderValueCurrencyCodeId') }}
                        </mat-error>
                    </mat-form-field>

                    <app-mat-input-number
                      [required]="isShippingCostRequired"
                      [disabled]="supplierForm.controls.shippingCosts.disabled"
                      label="Versandkosten"
                      [value]="supplierForm.controls.shippingCosts.value"
                      (onChanged)="supplierForm.controls.shippingCosts.setValue($event)"
                      (onTouched)="supplierForm.controls.shippingCosts.markAsTouched()"
                      [error]="hasShippingCostsRequiredError ? 'Versandkosten muss ausgefüllt sein.' : ''"
                      class="flex-1 ml-5"/>

                    <mat-form-field
                      class="w-[15%]"
                      color="accent">
                        <mat-label>Währung</mat-label>
                        <mat-select
                          formControlName="shippingCostsCurrencyCodeId"
                          [required]="(supplierForm.controls.shippingCosts.value | isNullOrUndefined) === false">
                            <mat-option
                              *ngFor="let currencyCode of currencyCodesQuery.currencyCodes$ | async"
                              [value]="currencyCode.id">
                                {{ currencyCode.display_name }}
                            </mat-option>
                        </mat-select>

                        <mat-error *ngIf="getFirstErrorOfProperty('minimumOrderValueSurchargeCurrencyCodeId')">
                            {{ getFirstErrorOfProperty('minimumOrderValueSurchargeCurrencyCodeId') }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex gap-2 items-start">
                    <app-mat-input-number
                      [required]="false"
                      [disabled]="supplierForm.controls.tollCharge.disabled"
                      label="Mautkosten"
                      [value]="supplierForm.controls.tollCharge.value"
                      (onChanged)="supplierForm.controls.tollCharge.setValue($event)"
                      [error]="getFirstErrorOfProperty('shippingCostsMinimumOrderValue')"
                      class="flex-1"/>

                    <mat-form-field
                      class="w-[15%]"
                      color="accent">
                        <mat-label>Währung</mat-label>
                        <mat-select
                          formControlName="tollChargeCurrencyCodeId"
                          [required]="(supplierForm.controls.tollCharge.value | isNullOrUndefined) === false">
                            <mat-option
                              *ngFor="let currencyCode of currencyCodesQuery.currencyCodes$ | async"
                              [value]="currencyCode.id">
                                {{ currencyCode.display_name }}
                            </mat-option>
                        </mat-select>

                        <mat-error *ngIf="getFirstErrorOfProperty('tollChargeCurrencyCodeId')">
                            {{ getFirstErrorOfProperty('tollChargeCurrencyCodeId') }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex gap-2 items-start">
                    <app-mat-input-number
                      [required]="false"
                      [disabled]="supplierForm.controls.additionalCosts.disabled"
                      label="Sonstige Kosten"
                      [value]="supplierForm.controls.additionalCosts.value"
                      (onChanged)="supplierForm.controls.additionalCosts.setValue($event)"
                      [error]="getFirstErrorOfProperty('additionalCosts')"
                      class="flex-1"/>

                    <mat-form-field
                      class="w-[15%]"
                      color="accent">
                        <mat-label>Währung</mat-label>
                        <mat-select
                          formControlName="additionalCostsCurrencyCodeId"
                          [required]="(supplierForm.controls.additionalCosts.value | isNullOrUndefined) === false">
                            <mat-option
                              *ngFor="let currencyCode of currencyCodesQuery.currencyCodes$ | async"
                              [value]="currencyCode.id">
                                {{ currencyCode.display_name }}
                            </mat-option>
                        </mat-select>

                        <mat-error *ngIf="getFirstErrorOfProperty('additionalCostsCurrencyCodeId')">
                            {{ getFirstErrorOfProperty('additionalCostsCurrencyCodeId') }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex">
                    <mat-form-field class="flex-1">
                        <mat-label>Sonstige Kosten Beschreibung</mat-label>
                        <textarea
                          matInput
                          #descriptionAdditionalCosts
                          formControlName="additionalCostsDescription"
                          placeholder="Beschreibung"
                          maxlength="1200"
                          rows="5">
                        </textarea>
                        <mat-hint align="end">
                            {{ descriptionAdditionalCosts.value.length }} / 1200 Zeichen
                        </mat-hint>
                        <mat-error *ngIf="supplierForm.controls.additionalCostsDescription.hasError('maxlength')">
                            Die Beschreibung darf nicht mehr als 1200 Zeichen lang sein.
                        </mat-error>
                    </mat-form-field>
                </div>

            </mat-expansion-panel>

            <mat-expansion-panel
              *ngIf="!isProposal"
              class="mat-elevation-z0 no-border">
                <mat-expansion-panel-header>
                    <mat-panel-title class="mt-2">
                        <div class="mat-title">Status</div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="flex">
                    <mat-form-field class="flex-1">
                        <mat-label>Status</mat-label>
                        <mat-select
                          formControlName="statusId"
                          name="status"
                          required>
                            <mat-option
                              *ngFor="let supplierAvailableStatus of supplierAvailableStatuses$ | async"
                              [value]="supplierAvailableStatus.status_id">
                                {{ supplierAvailableStatus.status.display_name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="supplierForm.controls.statusId.hasError('required')">
                            Bitte wählen Sie einen Status aus.
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-expansion-panel>

            <ng-container *ngIf="formMode === FormMode.details && !isProposal">
                <mat-expansion-panel class="mat-elevation-z0 no-border">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="mt-2">
                            <div class="mat-title">Sonstiges</div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="flex">
                        <mat-form-field class="flex-1">
                            <mat-label>Erstellt am</mat-label>
                            <input
                              matInput
                              type="text"
                              formControlName="createdAt"/>
                        </mat-form-field>
                    </div>
                    <div class="flex">
                        <mat-form-field class="flex-1">
                            <mat-label>Geändert am</mat-label>
                            <input
                              matInput
                              type="text"
                              formControlName="updatedAt"/>
                        </mat-form-field>
                    </div>
                    <div class="flex">
                        <mat-form-field class="flex-1">
                            <mat-label>Gelöscht am</mat-label>
                            <input
                              matInput
                              type="text"
                              formControlName="deletedAt"/>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </ng-container>
        </mat-card-content>

        <mat-card-actions
          *ngIf="!isProposal"
          class="!flex justify-end gap-1">
            <button
              type="button"
              mat-button
              color="primary"
              (click)="
                  formMode !== FormMode.details && supplierId && supplierId > 0
                    ? navigateToSupplierList()
                    : navigateToSupplierList()
                ">
                {{
                formMode === FormMode.details
                  ? "Zurück zur Übersicht"
                  : "Abbrechen"
                }}
            </button>

            <ng-container *ngIf="supplierId && supplierId > 0">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  matTooltip="nicht implementiert">
                    Löschen
                </button>
            </ng-container>
            <button
              *ngIf="formMode === FormMode.details"
              type="button"
              mat-flat-button
              color="primary"
              (click)="navigateToSupplierEdit(supplierId)">
                Bearbeiten
            </button>

            <button
              *ngIf="formMode !== FormMode.details"
              type="submit"
              mat-flat-button
              color="primary"
              [disabled]="!supplierForm.valid">
                Speichern
            </button>
        </mat-card-actions>
    </mat-card>
</form>
