import {createProductAttribute} from '@/management/product/state/product-attributes/product-attribute.model';
import {createProduct, ProductVariant} from '@/management/product/state/product.model';
import {
  VariantAttributeOption
} from '@/management/product/state/variant-attribute-options/variant-attribute-option.model';
import {VariantAttribute} from '@/management/product/state/variant-attributes/variant-attribute.model';
import {generateMultipleNextArtificialIds} from '../../../../../helpers/generate-multiple-next-artificial-ids';

type ProductAttributeCombination = {
  id: VariantAttribute['id'],
  value: VariantAttributeOption['value']
}[];

export const createPossibleVariants = (
  variantAttributes: VariantAttribute[],
  existingVariants: ProductVariant[],
) => {
  const possibleVariants = variantAttributes
    .reduce(generatePossibleCombinations, [[]] as ProductAttributeCombination[])
    .filter(variantCombination => !hasExistingVariantForCombination(variantCombination, existingVariants))
    .map(mapCombinationToProduct);

  return generateMultipleNextArtificialIds(possibleVariants);
};

const generatePossibleCombinations = (combinations: ProductAttributeCombination[], variantAttribute: VariantAttribute) => {
  const combinationsForCurrentAttribute: ProductAttributeCombination[] = [];

  for (const option of variantAttribute.variant_attribute_options) {
    for (const combination of combinations) {
      combinationsForCurrentAttribute.push([...combination, {
        id: variantAttribute.product_attribute_template_id,
        value: option.value,
      }]);
    }
  }

  return combinationsForCurrentAttribute;
}

const hasExistingVariantForCombination = (
  variantCombination: ProductAttributeCombination,
  existingVariants: ProductVariant[]
) => {
  for (const existingVariant of existingVariants) {
    const hasExistingVariant = variantCombination.every(combinationAttribute => {
      return existingVariant.product_attributes.some(existingAttribute => {
        return existingAttribute.product_attribute_template_id === combinationAttribute.id && existingAttribute.value === combinationAttribute.value;
      });
    });

    if (hasExistingVariant) {
      return true;
    }
  }

  return false;
}

const mapCombinationToProduct = (variantCombination: ProductAttributeCombination) => {
  const productAttributes = variantCombination.map(combinationAttribute => createProductAttribute({
    value: combinationAttribute.value,
    product_attribute_template_id: combinationAttribute.id,
  }));

  return createProduct({
    product_attributes: generateMultipleNextArtificialIds(productAttributes),
  });
}
