<div
  class="gallery-item">
    <div class="flex gap-2">
        <div class="w-[50%] supplier-product-image-container">
            <div class="supplier-product-image">
                <button
                  *ngIf="rank"
                  type="button"
                  mat-mini-fab
                  class="supplier-product-rank-icon">
                    #{{ rank }}
                </button>

                <!-- Hide favorite icon for ranked products because it won't show the correct status after updating -->
                <app-product-favorite-icon
                  *ngIf="!rank"
                  [product]="product"
                  class="product-favorite-icon">
                </app-product-favorite-icon>

                <a [routerLink]="supplierProductIdForRoute | supplierProductDetailsRouterLink">
                    <img
                      [src]="product.images | productDefaultImageUrl"
                      alt="Bild des Produktes" />
                </a>
            </div>
        </div>

        <div class="flex flex-col gap-2">
            <a [routerLink]="supplierProductIdForRoute | supplierProductDetailsRouterLink">
                <div class="w-[50%] flex flex-col gap-2">
                    <div>
                        <div>
                            {{ product?.name }}
                        </div>
                        <div class="eco">
                            <ng-container *ngFor="let i of [1, 2, 3, 4]">
                                <mat-icon fontSet="material-icons-round">eco</mat-icon>
                            </ng-container>
                            <ng-container *ngFor="let i of [1]">
                                <mat-icon fontSet="material-icons-outlined">eco</mat-icon>
                            </ng-container>
                        </div>
                    </div>

                    <div>
                        <div
                          *ngIf="hasVariants"
                          class="mat-caption">
                            {{ product.variant_count }} Varianten
                        </div>
                        <div
                          *ngIf="product.price_unit_price_from"
                          class="unit-price">
                            <ng-container *ngIf="hasMultipleUnitPrices">ab</ng-container>
                            {{ product.price_unit_price_from | priceToHighestCurrencyValue | currency: (product.price_unit_price_currency_code_id | currencyCodeById | async)?.name }}
                        </div>

                        <div
                          *ngIf="product.price_billing_frequency_price_from"
                          class="billing-frequency-price">
                            <ng-container *ngIf="hasMultipleBillingFrequencyPrices">ab</ng-container>
                            {{ product.price_billing_frequency_price_from | priceToHighestCurrencyValue | currency: (product.price_billing_frequency_price_currency_code_id | currencyCodeById | async)?.name }}
                            {{ product.price_billing_frequency_id | billingFrequencyById | async }}
                        </div>

                    </div>
                </div>
            </a>

            <div *ngIf="isShopActive">
                <button
                  mat-raised-button
                  color="primary"
                  (click)="toShoppingCart()"
                  [disabled]="productIsLoading$ | async"
                  type="button">
                    <div class="flex gap-2 items-center">
                        <mat-icon>shopping_cart</mat-icon>
                        In den Einkaufswagen

                        <mat-progress-spinner
                          *ngIf="productIsLoading$ | async"
                          diameter="20"
                          mode="indeterminate" />
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
