import {SessionQuery} from '@/core/session/state/session.query';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {ID, setLoading} from '@datorama/akita';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {ApiResponse} from '../../types/api/api-response';
import {handleError} from '../../utils';
import {getHttpOptionsWithParams} from '../../utils/functions/http-params';
import {CurrencyCode} from './currency-code.model';
import {CurrencyCodesStore} from './currency-codes.store';

@Injectable({providedIn: 'root'})
export class CurrencyCodesService {
  private readonly currencyCodesStore = inject(CurrencyCodesStore);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);

  get() {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .get<ApiResponse<CurrencyCode[]>>(environment.api.baseUrl + 'currency-codes', options)
      .pipe(
        setLoading(this.currencyCodesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.currencyCodesStore)),
        tap((response) => {
          this.currencyCodesStore.set(response['data']);
        }),
      );
  }

  add(currencyCode: CurrencyCode) {
    this.currencyCodesStore.add(currencyCode);
  }

  update(id, currencyCode: Partial<CurrencyCode>) {
    this.currencyCodesStore.update(id, currencyCode);
  }

  remove(id: ID) {
    this.currencyCodesStore.remove(id);
  }

}
