import {
  createSupplierProduct,
  SupplierProduct
} from '@/management/product/state/supplier-products/supplier-product.model';
import {Injectable} from '@angular/core';
import {combineQueries, Query} from '@datorama/akita';
import {map} from 'rxjs/operators';
import {ComponentStore} from '../../../../helpers/component-store/component-store';
import {generateNextArtificialId} from '../../../../helpers/generate-next-artificial-id';
import {isArtificialId} from '../../../../helpers/is-artificial-id';

interface State {
  initialSupplierProducts: SupplierProduct[];
  supplierProducts: SupplierProduct[];
}

const getInitialState = (): State => ({
  initialSupplierProducts: [],
  supplierProducts: [],
});

@Injectable()
export class SupplierProductManagementStore extends ComponentStore<State> {
  constructor() {
    super(getInitialState(), 'supplier-product-management');
  }
}

@Injectable()
export class SupplierProductManagementQuery extends Query<State> {
  supplierProducts$ = this.select('supplierProducts');
  initialSupplierProducts$ = this.select('initialSupplierProducts');

  hasActiveOrInitialSupplierProducts$ = combineQueries([
    this.supplierProducts$,
    this.initialSupplierProducts$,
  ]).pipe(
    map(([supplierProducts, initialSupplierProducts]) => supplierProducts.length > 0 || initialSupplierProducts.length > 0),
  );

  constructor(protected override store: SupplierProductManagementStore) {
    super(store);
  }

  getDeletedSupplierProducts() {
    const {initialSupplierProducts, supplierProducts} = this.getValue();

    // Find all attributes that are in the initial attributes but not in the selected anymore
    return initialSupplierProducts.filter(({id}) => !supplierProducts.some(selectedSupplierProduct => selectedSupplierProduct.id === id));
  }

  getNewSupplierProducts() {
    const {initialSupplierProducts, supplierProducts} = this.getValue();

    return supplierProducts.filter(supplierProduct =>
        supplierProduct.id < 0
        && !initialSupplierProducts.some(({
                                            id,
                                            supplier_order_number,
                                            unit_price,
                                            currency_code_id,
                                            billing_frequency_price,
                                            billing_frequency_currency_code_id,
                                            billing_frequency_id,
                                            status,
                                            promoted_order_number
                                          }) =>
          supplier_order_number === supplierProduct.supplier_order_number
          && unit_price === supplierProduct.unit_price
          && currency_code_id === supplierProduct.currency_code_id
          && billing_frequency_price === supplierProduct.billing_frequency_price
          && billing_frequency_currency_code_id === supplierProduct.billing_frequency_currency_code_id
          && billing_frequency_id === supplierProduct.billing_frequency_id
          && status === supplierProduct.status
          && promoted_order_number === supplierProduct.promoted_order_number
        )
    );
  }

  getUpdatedSupplierProducts() {
    const {initialSupplierProducts, supplierProducts} = this.getValue();

    return supplierProducts.filter(supplierProduct =>
        supplierProduct.id !== null
        && !isArtificialId(supplierProduct.id)
        // Checking for difference in attributes to reduce amount of requests being sent
        && !initialSupplierProducts.some(({
                                            id,
                                            supplier_order_number,
                                            unit_price,
                                            currency_code_id,
                                            billing_frequency_price,
                                            billing_frequency_currency_code_id,
                                            billing_frequency_id,
                                            status,
                                            promoted_order_number
                                          }) =>
          id === supplierProduct.id
          && supplier_order_number === supplierProduct.supplier_order_number
          && unit_price === supplierProduct.unit_price
          && currency_code_id === supplierProduct.currency_code_id
          && billing_frequency_price === supplierProduct.billing_frequency_price
          && billing_frequency_currency_code_id === supplierProduct.billing_frequency_currency_code_id
          && billing_frequency_id === supplierProduct.billing_frequency_id
          && status === supplierProduct.status
          && promoted_order_number === supplierProduct.promoted_order_number
        )
    );
  }
}

@Injectable()
export class SupplierProductManagementService {
  constructor(private store: SupplierProductManagementStore) {
  }

  addSupplierProduct(supplierProduct: SupplierProduct) {
    // Add generated ID for new supplier products
    supplierProduct = {...supplierProduct, id: this.getNextId()};

    this.store.update(({supplierProducts: existingSupplierProducts}) => ({
      supplierProducts: [
        ...supplierProduct.promoted_order_number
          ? existingSupplierProducts.map(
            existingSupplierProduct => createSupplierProduct({...existingSupplierProduct, promoted_order_number: null})
          )
          : existingSupplierProducts,
        supplierProduct
      ],
    }));
  }

  removeSupplierProduct(supplierProductId: number) {
    this.store.update(({supplierProducts}) => ({
      supplierProducts: supplierProducts.filter(supplierProduct =>
        supplierProduct.id !== supplierProductId
      )
    }));
  }

  updateSupplierProduct(updatedSupplierProduct: SupplierProduct) {
    this.store.update(({supplierProducts}) => ({
      supplierProducts: supplierProducts.map(supplierProduct =>
        supplierProduct.id === updatedSupplierProduct.id
          ? {...supplierProduct, ...updatedSupplierProduct}
          : {
            ...supplierProduct,
            promoted_order_number: updatedSupplierProduct.promoted_order_number != null
              ? null
              : supplierProduct.promoted_order_number
          }
      )
    }));
  }

  setInitialSupplierProducts(supplierProducts: SupplierProduct[]) {
    this.store.update({initialSupplierProducts: supplierProducts, supplierProducts});
  }

  private getNextId() {
    return generateNextArtificialId(this.store.getValue().supplierProducts);
  }
}

export const provideSupplierProductManagement = () => [
  SupplierProductManagementStore,
  SupplierProductManagementQuery,
  SupplierProductManagementService
];
