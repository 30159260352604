import {PermissionsQuery} from '@/core/session/state/permissions/permissions.query';
import {PermissionsService} from '@/core/session/state/permissions/permissions.service';
import {SessionQuery} from '@/core/session/state/session.query';
import {ManagementRouteNames} from '@/management/constants/routes.constants';
import {CostCentersService} from '@/management/cost-center/state/cost-centers.service';
import {ProductRouteNames as ManagementProductRouteNames} from '@/management/product/constants/routes.constants';
import {
  ProductCategoriesService as ManagementProductCategoriesService
} from '@/management/product/state/product-categories/product-categories.service';
import {SuppliersService} from '@/management/supplier/state/suppliers.service';
import {ShopRouteNames} from '@/shop/constants/routes.constants';
import {ProductRouteNames as ShopProductRouteNames} from '@/shop/product/constants/routes.constants';
import {
  ProductCategoriesQuery as ShopProductCategoriesQuery
} from '@/shop/product/state/product-categories/product-categories.query';
import {
  ProductCategoriesService as ShopProductCategoriesService
} from '@/shop/product/state/product-categories/product-categories.service';
import {ProductCategory} from '@/shop/product/state/product-categories/product-category.model';
import {
  ProductCategoryTenantsService
} from '@/shop/product/state/product-category-tenants/product-category-tenants.service';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {combineLatest} from 'rxjs';
import {filter, take, tap} from 'rxjs/operators';
import packageJson from '../../../../../package.json';
import {environment} from '../../../../environments/environment';
import {SharedConstants} from '../../constants';
import {NavigationMode} from '../../models';
import {UiQuery} from '../../state/ui.query';
import {UiService} from '../../state/ui.service';

@UntilDestroy()
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent implements OnInit {
  private readonly costCentersService = inject(CostCentersService);
  private readonly managementProductCategoriesService = inject(ManagementProductCategoriesService);
  private readonly shopProductCategoriesQuery = inject(ShopProductCategoriesQuery);
  private readonly shopProductCategoriesService = inject(ShopProductCategoriesService);
  private readonly productCategoryTenantsService = inject(ProductCategoryTenantsService);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly uiQuery = inject(UiQuery);
  private readonly uiService = inject(UiService);
  private readonly suppliersService = inject(SuppliersService);
  private readonly permissionsService = inject(PermissionsService);
  private readonly permissionsQuery = inject(PermissionsQuery);
  private readonly cd = inject(ChangeDetectorRef);

  @Input() isSidenavOpen: boolean;

  navigationModeActive$ = this.uiQuery.navigationMode$;
  productCategories$ = this.shopProductCategoriesQuery.productCategoriesForTenant$;

  environment = environment;
  isSupplierProductProposalAllowed: boolean;
  productCategories: ProductCategory[];
  availableNavigationModes: NavigationMode[] = [];
  navigationModes = SharedConstants.NAVIGATION_MODES;
  productListRouterLink = [
    '/',
    ManagementRouteNames.MANAGEMENT,
    ManagementProductRouteNames.PRODUCT,
    ManagementProductRouteNames.LIST,
  ];
  productCreateRouterLink = [
    '/',
    ManagementRouteNames.MANAGEMENT,
    ManagementProductRouteNames.PRODUCT,
    ManagementProductRouteNames.CREATE,
  ];
  supplierProductProposalRouterLink = [
    '/',
    ShopRouteNames.SHOP,
    ShopProductRouteNames.PRODUCT,
    ShopProductRouteNames.PROPOSAL,
  ];

  get stage() {
    return environment.stage === 'prod' ? '' : environment.stage.toUpperCase();
  }

  get appVersion() {
    return 'v' + packageJson.version;
  }

  ngOnInit(): void {
    combineLatest([
      this.permissionsQuery.isLoading$,
      this.permissionsQuery.permissions$,
    ]).pipe(
      filter(([isLoading, permissions]) => !isLoading && permissions?.length > 0),
      take(1),
      tap(() => {
        this.navigationModes.forEach(navigationMode =>
          this.permissionsService.checkIfUserHasOneOrMorePermissionTo([{
            model: navigationMode.module,
            scope: null,
            action: 'use'
          }]).then(hasPermission => {
            if (hasPermission) {
              this.availableNavigationModes = [...this.availableNavigationModes, {...navigationMode}];
              this.cd.markForCheck();
            }
          }));
      })
    ).subscribe();

    this.permissionsService.checkIfUserHasOneOrMorePermissionTo([{
        model: 'supplierProduct',
        attribute: 'proposal',
        action: 'create'
      }]
    ).then(hasOneOrMorePermissions => {
      this.isSupplierProductProposalAllowed = hasOneOrMorePermissions;
    });

    this.shopProductCategoriesService.get().subscribe();
    this.productCategoryTenantsService.get().subscribe();
    this.permissionsService.get(this.sessionQuery.userId).subscribe();
  }

  navigateToProductCategoryDetails(id: number) {
    return this.shopProductCategoriesService.navigateToProductCategoryDetails(id);
  }

  navigateToCostCentersCreate(): Promise<boolean> {
    return this.costCentersService.navigateToCostCenterCreate();
  }

  navigateToCostCenterList(): Promise<boolean> {
    return this.costCentersService.navigateToCostCenterList();
  }

  navigateToSupplierCreate(): Promise<boolean> {
    return this.suppliersService.navigateToSupplierCreate();
  }

  navigateToSupplierList(): Promise<boolean> {
    return this.suppliersService.navigateToSupplierList();
  }

  navigateToProductCategoryList(): Promise<boolean> {
    return this.managementProductCategoriesService.navigateToProductCategoryList();
  }

  updateNavigationMode(newNavigationMode: NavigationMode): void {
    return this.uiService.updateNavigationMode(newNavigationMode);
  }
}
