import {CostCentersQuery} from '@/shared/state/cost-centers/cost-centers.query';
import {inject, Injectable} from '@angular/core';
import {filterNilValue, QueryEntity} from '@datorama/akita';
import {map, switchMap} from 'rxjs/operators';
import {ProductCategoriesQuery} from '../product-categories/product-categories.query';
import {ProductCategoryTenantsQuery} from '../product-category-tenants/product-category-tenants.query';
import {
  CostCenterProductCategoryPivot,
  createCostCenterProductCategory,
  createProductCategoryCostCenter
} from './cost-center-product-category-pivot.model';
import {
  CostCenterProductCategoryPivotsState,
  CostCenterProductCategoryPivotsStore
} from './cost-center-product-category-pivots.store';

@Injectable({providedIn: 'root'})
export class CostCenterProductCategoryPivotsQuery extends QueryEntity<CostCenterProductCategoryPivotsState> {
  private readonly costCentersQuery = inject(CostCentersQuery);
  private readonly productCategoriesQuery = inject(ProductCategoriesQuery);
  private readonly productCategoryTenantsQuery = inject(ProductCategoryTenantsQuery);

  costCenterProductCategories$ = this.selectAll();

  // costCenterProductCategoriesForActiveProductCategory$ = this.productCategoryTenantsQuery.activeProductCategoryTenant$.pipe(
  //   filterNilValue(),
  //   switchMap(productCategoryTenant => this.selectCostCenterProductCategoryPivotsForProductCategory(productCategoryTenant.product_category?.id)),
  //   map(pivots => this.mapToCostCenterProductCategories(pivots)),
  // );

  constructor(
    protected store: CostCenterProductCategoryPivotsStore,
  ) {
    super(store);
  }

  selectProductCategoryCostCentersForCostCenter(costCenterId: number) {
    return this.costCenterProductCategories$.pipe(
      map(costCenterProductCategories => costCenterProductCategories.filter(pivot => {
        return pivot?.cost_center_id === costCenterId;
      })),
      map(pivots => this.mapToProductCategoryCostCenters(pivots))
    );
  }

  private selectCostCenterProductCategoryPivotsForProductCategory(productCategoryId: number) {
    return this.costCenterProductCategories$.pipe(
      map(costCenterProductCategories => costCenterProductCategories.filter(pivot => {
        return pivot?.product_category_id === productCategoryId;
      })),
    );
  }

  private mapToCostCenterProductCategories(costCenterProductCategoryPivots: CostCenterProductCategoryPivot[]) {
    return costCenterProductCategoryPivots.map(pivot => createCostCenterProductCategory({
      ...this.costCentersQuery.getEntity(pivot.cost_center_id),
      pivot,
    }));
  }


  private mapToProductCategoryCostCenters(productCategoryCostCenterPivots: CostCenterProductCategoryPivot[]) {
    return productCategoryCostCenterPivots.map(pivot => createProductCategoryCostCenter({
      ...this.productCategoriesQuery.getEntity(pivot.cost_center_id),
      pivot,
    }));
  }
}
