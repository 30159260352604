import {Product} from '../../product.model';

export const mergeProductAttributesForVariant = (virtualProduct: Product, variantProduct: Product) => {
  const virtualProductAttributes = virtualProduct.product_attributes;
  const variantProductAttributes = variantProduct.product_attributes;

  // Overwrite virtual product attributes with variant product attributes
  return [
    ...virtualProductAttributes.filter(virtualProductAttribute => {
      return !variantProductAttributes.some(variantProductAttribute => variantProductAttribute.product_attribute_template_id === virtualProductAttribute.product_attribute_template_id);
    }),
    ...variantProductAttributes,
  ];
}
