import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';

/** Unit price or billing frequency price one of them is required */
export const unitPriceOrBillingFrequencyPriceRequiredValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const unitPriceControlValue = control.get('unitPriceGroup.unitPrice')?.value;
  const billingFrequencyPriceControlValue = control.get('billingFrequencyPriceGroup.billingFrequencyPrice')?.value;

  return (unitPriceControlValue != null && unitPriceControlValue !== '') || (billingFrequencyPriceControlValue != null && billingFrequencyPriceControlValue !== '') ? null : {'required': true};
};

@Directive({
  selector: '[appUnitPriceOrBillingFrequencyPriceRequired]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: UnitPriceOrBillingFrequencyPriceRequiredValidatorDirective,
    multi: true
  }]
})
export class UnitPriceOrBillingFrequencyPriceRequiredValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return unitPriceOrBillingFrequencyPriceRequiredValidator(control);
  }
}
