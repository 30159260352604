import {HashMap} from '@datorama/akita';
import {SupplierProduct} from '../../../../product/state/supplier-products/supplier-product.model';
import {ShoppingCartItem, ShoppingCartItemWithSupplierProduct} from '../../shopping-cart.model';

export const joinShoppingCartItemsWithSupplierProducts = ([shoppingCartItems, supplierProducts]: [ShoppingCartItem[], HashMap<SupplierProduct>]) =>
  shoppingCartItems.map(shoppingCartItem => {
    const supplierProduct = supplierProducts[shoppingCartItem.supplier_product_id];

    return {
      ...shoppingCartItem,
      ...supplierProduct,
      unitPriceTotal: shoppingCartItem.quantity * supplierProduct?.unit_price,
      billingFrequencyPriceTotal: shoppingCartItem.quantity * supplierProduct?.billing_frequency_price
    } as ShoppingCartItemWithSupplierProduct;
  });
