import {inject, Pipe, PipeTransform} from '@angular/core';
import {Product} from '../../product.model';
import {SupplierProductsQuery} from '../supplier-products.query';

@Pipe({
  name: 'supplierProductsForProduct$'
})
export class SupplierProductsForProductPipe implements PipeTransform {
  private readonly supplierProductsQuery = inject(SupplierProductsQuery);

  transform(productId: Product['id']) {
    return this.supplierProductsQuery.selectSupplierProductsWithSameBaseProduct(productId);
  }
}
