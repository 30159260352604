import {ProductVariant} from '../../product.model';
import {VariantOption} from '../../types/available-variant-option';

interface FindMatchingVariantForSelectionProps {
  variantOptions: VariantOption[];
  variants: ProductVariant[];
}

/** Will return a variant if it matches perfect for all options including the new option */
export const findMatchingVariantForSelection = ({
                                                  variants,
                                                  variantOptions
                                                }: FindMatchingVariantForSelectionProps) => {
  return variants.find(variant => {
    return variantOptions.every(option => {
      return variant.product_attributes?.some(attribute =>
        attribute.product_attribute_template_id === option.productAttributeTemplateId
        && attribute.value === option.value
      );
    });
  });
}
