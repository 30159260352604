<div class="mat-typography mb-4">
    <div>
        <div class="flex flex-col items-center justify-center">
            <div
              class="mat-headline"
              mat-dialog-title>
                Artikel "<span>{{ supplierProductName }}</span>" teilen
            </div>
        </div>
    </div>

    <div>
        <form [formGroup]="shareForm">
            <div class="flex flex-col">
                <ng-container *ngIf="(microsoftGraphUsersQuery.isLoading$ | async) === false; else microsoftGraphUsersLoading">
                    <ng-container *ngIf="(microsoftGraphUsersAsDqnAutocompleteOptions$ | async) as microsoftGraphUsers">
                        <dqn-autocomplete
                          class="w-full"
                          formControlName="recipients"
                          placeholder="Suche..."
                          label="Nutzerauswahl"
                          [multi]="true"
                          [isRequired]="true"
                          [options]="microsoftGraphUsers">
                        </dqn-autocomplete>

                        <mat-error
                          *ngIf="shareForm.controls.recipients.touched && shareForm.controls.recipients.hasError('required')"
                          class="mat-small">
                            Bitte wählen Sie Kollegen aus.
                        </mat-error>
                    </ng-container>
                </ng-container>
                <ng-template #microsoftGraphUsersLoading>
                    <p>Die Benutzer werden geladen ...</p>
                </ng-template>

                <mat-form-field
                  class="w-full"
                  color="accent"
                  appearance="outline"
                  hintLabel="Maximale Länge: 255 Zeichen">
                    <mat-label>Kommentar</mat-label>

                    <textarea
                      #descriptionInput
                      matInput
                      formControlName="message"
                      required
                      rows="4"
                      placeholder="Nachricht"
                      maxlength="255">
                    </textarea>

                    <mat-hint align="end">{{ descriptionInput.value?.length || 0 }}/255</mat-hint>
                </mat-form-field>
            </div>
        </form>
    </div>

    <mat-dialog-actions
      align="center"
      class="flex justify-center items-center gap-2">
        <button
          (click)="shareSupplierProduct()"
          [disabled]="shareForm.invalid"
          color="accent"
          class="flex-grow"
          mat-flat-button>
            Teilen
        </button>

        <button
          (click)="cancel()"
          class="flex-grow !ml-0"
          color="accent"
          mat-button>Abbrechen
        </button>
    </mat-dialog-actions>
</div>
