import {formatNumber} from '@angular/common';
import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {Product} from '../../product/state/product.model';
import {
  getDisplayNameForQuantityUnit
} from '../../product/state/quantity-units/helpers/get-display-name-for-quantity-unit';

type ProductUnit = Pick<Product, 'scale_unit' | 'selling_quantity'>;

/**
 * Generates a displayable string showing the unit of the product.
 */
@Pipe({
  name: 'productUnitDisplayText'
})
export class ProductUnitDisplayTextPipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  transform<TProduct extends ProductUnit>({
                                            scale_unit,
                                            selling_quantity
                                          }: TProduct) {
    let unitDisplayText = '';

    if (selling_quantity) {
      unitDisplayText += formatNumber(selling_quantity, this.locale, '1.0-2');
    }

    if (scale_unit) {
      const scaleUnitText = getDisplayNameForQuantityUnit(scale_unit);
      // Append an empty string in between when both are present
      unitDisplayText += unitDisplayText ? ' ' + scaleUnitText : scaleUnitText;
    }

    return unitDisplayText;
  }
}
