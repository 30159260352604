import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {map, tap} from 'rxjs/operators';

@Component({
  selector: 'app-mat-autocomplete-name-search',
  templateUrl: './mat-autocomplete-name-search.component.html',
  styleUrls: ['./mat-autocomplete-name-search.component.scss']
})
export class MatAutocompleteNameSearchComponent implements OnInit {
  @Input() label: string;
  @Input() required: boolean = false;
  @Input() returnObject: boolean = false;
  @Input() disableSuffixIcon: boolean = false;
  @Input() useDisplayName: boolean = false;

  @Input()
  set disabled(value: boolean) {
    if (value) {
      this.selectedOptionControl.disable();
    } else {
      this.selectedOptionControl.enable();
    }
  }

  selectedOptionControl: UntypedFormControl = new UntypedFormControl(null);
  filteredOptions$: Observable<any[]>;
  opened: boolean = false;

  get inputIconName(): string {
    return this.opened ? 'arrow_drop_up' : 'arrow_drop_down';
  }

  private _options: any[] = [];
  @Input()
  set options(value: any[]) {
    if (value !== this._options) {
      this._options = value;
      this.selectedOptionControl.setValue(this.selectedOptionControl.value);
    }
  }

  get options(): any[] {
    return this._options;
  }

  private _selectedOption: any = null;
  @Input()
  set selectedOption(value: any) {
    if (value !== this._selectedOption) {
      this._selectedOption = value;
      this.selectedOptionControl.setValue(value);
    }
  }

  get selectedOption() {
    return this._selectedOption;
  }

  @Output() onChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
    this.selectedOptionControl.disabled;

    this.filteredOptions$ = this.selectedOptionControl.valueChanges
      .pipe(tap(value => this.onChanged.emit(value?.id ? (this.returnObject ? value : value.id) : null)))
      .pipe(
        map(value => {
          if (this.useDisplayName) {
            return this.filter(value?.display_name ? value.display_name : value);
          } else {
            return this.filter(value?.name ? value.name : value);
          }
        })
      );
  }

  toggleOpenedState(): void {
    this.opened = !this.opened;
  }

  displayValueOfOptionControl(value: any) {
    if (this.useDisplayName) {
      return value?.display_name ? value.display_name : '';
    }
    return value?.name ? value.name : '';
  }

  private filter(value: string): any {
    value = value ? value : '';
    const filterValue = value.toLowerCase();

    if (this.useDisplayName) {
      return this.options.filter(option => option.display_name.toLowerCase().includes(filterValue));
    }

    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  validateSelectedOption() {
    if (!this.selectedOptionControl.value?.id) {
      this.selectedOptionControl.setValue(null);
    }
    this.toggleOpenedState();
  }
}
