import {Product} from '@/shop/product/state/product.model';
import {ProductsService} from '@/shop/product/state/products.service';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-product-favorite-icon',
  templateUrl: './product-favorite-icon.component.html',
  styleUrls: ['./product-favorite-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFavoriteIconComponent {
  @Input({required: true}) product!: Product;

  get isFavorite() {
    return this.product.is_favorite;
  }

  get tooltip() {
    return this.isFavorite ? 'Von meinen Favoriten entfernen' : 'Zu meinen Favoriten hinzufügen';
  }

  get favoriteIcon() {
    return this.isFavorite ? 'favorite' : 'favorite_border';
  }

  constructor(private productsService: ProductsService) {
  }

  toggleFavorite() {
    this.productsService.updateFavoriteStatusForProduct(this.product.id, !this.isFavorite).subscribe();
  }
}
