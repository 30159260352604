import {A11yModule} from '@angular/cdk/a11y';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {CommonModule, DecimalPipe} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTreeModule} from '@angular/material/tree';
import {RouterModule} from '@angular/router';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {DqnAutocompleteModule} from '@dqn/components-library/autocomplete';
import {DqnButtonModule} from '@dqn/components-library/button';
import {DqnDropdownModule} from '@dqn/components-library/dropdown';
import {DqnFormFieldModule} from '@dqn/components-library/form-field';
import {DqnInputModule} from '@dqn/components-library/input';
import {DqnTableModule} from '@dqn/components-library/table';
import {DqnTooltipModule} from '@dqn/components-library/tooltip';
import {FaConfig, FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IMaskModule} from 'angular-imask';
import {MatAutocompleteNameSearchComponent, MatCardAlertComponent,} from './components';
import {AddressSelectionComponent} from './components/address-selection/address-selection.component';
import {
  AddressListItemComponent
} from './components/address-selection/components/address-list-item/address-list-item.component';
import {
  AddressSelectionDialogComponent
} from './components/address-selection/dialogs/address-selection-dialog/address-selection-dialog.component';
import {
  ApprovalWorkflowContainerComponent
} from './components/approval-workflow-container/approval-workflow-container.component';
import {
  ApprovalDialogComponent
} from './components/approval-workflow-container/components/approval-dialog/approval-dialog.component';
import {
  ProductCategoriesAndCostCenterApprovalWorkflowComponent
} from './components/approval-workflow-container/components/product-categories-and-cost-center/product-categories-and-cost-center-approval-workflow.component';
import {
  ProductCategoriesAndCostCentersApprovalWorkflowComponent
} from './components/approval-workflow-container/components/product-categories-and-cost-centers/product-categories-and-cost-centers-approval-workflow.component';
import {BreadCrumbsComponent} from './components/bread-crumbs/bread-crumbs.component';
import {BreadCrumbItemDirective} from './components/bread-crumbs/directives/bread-crumb-item.directive';
import {ConfirmDeleteDialogComponent} from './components/confirm-delete-dialog/confirm-delete-dialog.component';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {MatInputNumberComponent} from './components/mat-input-number/mat-input-number.component';
import {
  ProductAttributesAddDialogComponent
} from './components/product-form/components/product-attributes-add-dialog/product-attributes-add-dialog.component';
import {
  ProductAttributesManagementComponent
} from './components/product-form/components/product-attributes-management/product-attributes-management.component';
import {
  ProductAttributesTableComponent
} from './components/product-form/components/product-attributes-table/product-attributes-table.component';
import {
  SupplierProductFormDialogComponent
} from './components/product-form/components/supplier-product-form-dialog/supplier-product-form-dialog.component';
import {
  CurrencyCodeByIdPipe
} from './components/product-form/components/supplier-product-management/pipes/currency-code-by-id.pipe';
import {
  SupplierProductStatusByIdPipe
} from './components/product-form/components/supplier-product-management/pipes/supplier-product-status-by-id.pipe';
import {
  SupplierProductManagementComponent
} from './components/product-form/components/supplier-product-management/supplier-product-management.component';
import {
  VariantAttributesAddDialogComponent
} from './components/product-form/components/variant-attributes-add-dialog/variant-attributes-add-dialog.component';
import {
  VariantAttributesManagementComponent
} from './components/product-form/components/variant-attributes-management/variant-attributes-management.component';
import {
  FilterProductAttributesByVariantAttributesPipe
} from './components/product-form/components/variant-management/pipes/filter-product-attributes-by-variant-attributes.pipe';
import {
  NameFromProductAttributesPipe
} from './components/product-form/components/variant-management/pipes/name-from-product-attributes.pipe';
import {
  VariantManagementComponent
} from './components/product-form/components/variant-management/variant-management.component';
import {
  VariantsAddDialogComponent
} from './components/product-form/components/variants-add-dialog/variants-add-dialog.component';
import {ProductAttributeTemplateByIdPipe} from './components/product-form/pipes/product-attribute-template-by-id.pipe';
import {ProductFormComponent} from './components/product-form/product-form.component';
import {SidenavCategoryTreeComponent} from './components/sidenav-category-tree/sidenav-category-tree.component';
import {SupplierFormComponent} from './components/supplier-form/supplier-form.component';
import {
  ProductFavoriteIconComponent
} from './components/supplier-product-favorite-icon/product-favorite-icon.component';
import {TitleIconComponent} from './components/title-icon/title-icon.component';
import {VariantOptionComponent} from './components/variant-option/variant-option.component';
import {DragAndDropDirective} from './directives';
import {FormModeDirective} from './directives/form-mode.directive';
import {getFontawesomeIcons} from './get-fontawesome-icons';
import {FooterComponent, HeaderComponent} from './layout';
import {LayoutComponent} from './layout/layout.component';
import {SidenavComponent} from './layout/sidenav/sidenav.component';
import {SubheaderComponent} from './layout/subheader/subheader.component';
import {BlankPageComponent, ImprintComponent, Page401Component, PrivacyPolicyComponent} from './pages';
import {Page404Component} from './pages/page-404/page-404.component';
import {SafeResourceUrlPipe} from './pipes';
import {BillingFrequencyByIdPipe} from './pipes/billing-frequency-by-id.pipe';
import {ChildCategoriesByParentIdPipe} from './pipes/child-categories-by-parent-id.pipe';
import {CostCenterByIdPipe} from './pipes/cost-center-by-id.pipe';
import {GermanNumberStringToNumberPipe} from './pipes/german-number-string-to-number.pipe';
import {HasChildCategoriesByParentIdPipe} from './pipes/has-child-categories-by-parent-id.pipe';
import {IsFormControlRequiredPipe} from './pipes/is-form-control-required.pipe';
import {IsNullOrUndefinedPipe} from './pipes/is-null-or-undefined.pipe';
import {PriceForAllowancePipe} from './pipes/price-for-allowance.pipe';
import {PriceNetToGrossPipe} from './pipes/price-net-to-gross.pipe';
import {PriceToHighestCurrencyValuePipe} from './pipes/price-to-highest-currency-value.pipe';
import {ProductByIdPipe} from './pipes/product-by-id.pipe';
import {ProductDetailsRouterLinkPipe} from './pipes/product-details-router-link.pipe';
import {ProductEditRouterLinkPipe} from './pipes/product-edit-router-link.pipe';
import {ProductStatusPipe} from './pipes/product-status.pipe';
import {StatusDisplayNameForNamePipe} from './pipes/status-display-name-for-name.pipe';
import {SupplierByIdPipe} from './pipes/supplier-by-id.pipe';
import {ProductDefaultImageUrlPipe} from './pipes/supplier-product-default-image-url.pipe';
import {
  BillingFrequencyPriceNotEmptyThenCurrencyCodeIdAndBillingFrequencyIdRequiredValidatorDirective,
  UnitPriceNotEmptyThenCurrencyCodeIdRequiredValidatorDirective,
  UnitPriceOrBillingFrequencyPriceRequiredValidatorDirective,
} from './validators';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SidenavComponent,
    SubheaderComponent,
    MatCardAlertComponent,
    MatAutocompleteNameSearchComponent,
    GermanNumberStringToNumberPipe,
    SafeResourceUrlPipe,
    MatInputNumberComponent,
    BlankPageComponent,
    DragAndDropDirective,
    FileUploadComponent,
    ProductFavoriteIconComponent,
    UnitPriceOrBillingFrequencyPriceRequiredValidatorDirective,
    UnitPriceNotEmptyThenCurrencyCodeIdRequiredValidatorDirective,
    BillingFrequencyPriceNotEmptyThenCurrencyCodeIdAndBillingFrequencyIdRequiredValidatorDirective,
    TitleIconComponent,
    FooterComponent,
    Page401Component,
    Page404Component,
    PrivacyPolicyComponent,
    ImprintComponent,
    SidenavCategoryTreeComponent,
    ChildCategoriesByParentIdPipe,
    ProductCategoriesAndCostCenterApprovalWorkflowComponent,
    ProductCategoriesAndCostCentersApprovalWorkflowComponent,
    ApprovalWorkflowContainerComponent,
    ApprovalDialogComponent,
    PriceNetToGrossPipe,
    ProductDefaultImageUrlPipe,
    StatusDisplayNameForNamePipe,
    ConfirmDialogComponent,
    ConfirmDeleteDialogComponent,
    AddressSelectionComponent,
    AddressListItemComponent,
    AddressSelectionDialogComponent,
    IsFormControlRequiredPipe,
    ProductFormComponent,
    ProductAttributesAddDialogComponent,
    ProductAttributesManagementComponent,
    SupplierProductFormDialogComponent,
    SupplierProductManagementComponent,
    VariantManagementComponent,
    VariantsAddDialogComponent,
    VariantAttributesManagementComponent,
    VariantAttributesAddDialogComponent,
    SupplierProductStatusByIdPipe,
    CurrencyCodeByIdPipe,
    SupplierByIdPipe,
    PriceToHighestCurrencyValuePipe,
    PriceForAllowancePipe,
    BillingFrequencyByIdPipe,
    IsNullOrUndefinedPipe,
    HasChildCategoriesByParentIdPipe,
    BreadCrumbsComponent,
    BreadCrumbItemDirective,
    SupplierFormComponent,
    CostCenterByIdPipe,
    ProductByIdPipe,
    ProductDetailsRouterLinkPipe,
    ProductEditRouterLinkPipe,
    ProductAttributesTableComponent,
    ProductAttributeTemplateByIdPipe,
    VariantOptionComponent,
    NameFromProductAttributesPipe,
    FilterProductAttributesByVariantAttributesPipe,
    ProductStatusPipe,
  ],
  providers: [GermanNumberStringToNumberPipe, DecimalPipe, SafeResourceUrlPipe],
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatChipsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    MatTableModule,
    ReactiveFormsModule,
    RouterModule,
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTabsModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    CKEditorModule,
    IMaskModule,
    // DQN
    DqnAutocompleteModule,
    DqnTableModule,
    DqnTooltipModule,
    DqnFormFieldModule,
    DqnDropdownModule,
    DqnInputModule,
    DqnButtonModule,
    // Fontawesome
    FontAwesomeModule,
    FormModeDirective,
  ],
  exports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatChipsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    MatTableModule,
    ReactiveFormsModule,
    RouterModule,
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTabsModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    MatCardAlertComponent,
    MatAutocompleteNameSearchComponent,
    MatInputNumberComponent,
    FileUploadComponent,
    ProductFavoriteIconComponent,
    TitleIconComponent,
    GermanNumberStringToNumberPipe,
    DecimalPipe,
    SafeResourceUrlPipe,
    CKEditorModule,
    ProductCategoriesAndCostCenterApprovalWorkflowComponent,
    ProductCategoriesAndCostCentersApprovalWorkflowComponent,
    ApprovalWorkflowContainerComponent,
    PriceNetToGrossPipe,
    ProductDefaultImageUrlPipe,
    StatusDisplayNameForNamePipe,
    ConfirmDialogComponent,
    ConfirmDeleteDialogComponent,
    AddressSelectionComponent,
    AddressListItemComponent,
    AddressSelectionDialogComponent,
    ProductFormComponent,
    ProductAttributesAddDialogComponent,
    ProductAttributesManagementComponent,
    SupplierProductFormDialogComponent,
    SupplierProductManagementComponent,
    VariantManagementComponent,
    SupplierProductStatusByIdPipe,
    CurrencyCodeByIdPipe,
    SupplierByIdPipe,
    PriceToHighestCurrencyValuePipe,
    PriceForAllowancePipe,
    BillingFrequencyByIdPipe,
    IsNullOrUndefinedPipe,
    FontAwesomeModule,
    ChildCategoriesByParentIdPipe,
    HasChildCategoriesByParentIdPipe,
    BreadCrumbsComponent,
    BreadCrumbItemDirective,
    SupplierFormComponent,
    CostCenterByIdPipe,
    ProductDetailsRouterLinkPipe,
    ProductEditRouterLinkPipe,
    FormModeDirective,
    VariantAttributesAddDialogComponent,
    VariantOptionComponent,
    ProductByIdPipe,
    ProductStatusPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor(library: FaIconLibrary, faConfig: FaConfig) {
    library.addIcons(...getFontawesomeIcons());
    faConfig.defaultPrefix = 'fad';
  }
}
