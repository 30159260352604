import {generateNextArtificialId} from './generate-next-artificial-id';

/** Will generate multiple new artificial ids (negative numbers) for the list based on the provided list of existing items. */
export const generateMultipleNextArtificialIds = <T extends { id: number }>(list: T[], existingList: T[] = []) => {
  return list.reduce((updatedItems, item) => {
    return [
      ...updatedItems,
      {...item, id: generateNextArtificialId([...existingList, ...updatedItems])}
    ]
  }, [] as T[]);
}
