import {Pipe, PipeTransform} from '@angular/core';
import {Product} from '../../../state/product.model';

@Pipe({
  name: 'hasProductContentsInformation'
})
export class HasProductContentsInformationPipe implements PipeTransform {
  transform(product: Product) {
    return product.scale_unit || product.selling_quantity;
  }
}
