<mat-card class="mat-elevation-z0">
    <mat-card-content>
        <form [formGroup]="form">
            <div class="select-supplier">
                <div class="mat-title">Produkt auswählen</div>

                <dqn-autocomplete
                  class="w-full"
                  formControlName="productId"
                  label="Produkt auswählen"
                  placeholder="Produkt suchen ..."
                  [isRequired]="true"
                  [options]="(products$ | async)">
                </dqn-autocomplete>
            </div>
        </form>

        <div class="create-supplier">
            <mat-expansion-panel
              class="mat-elevation-z0"
              [expanded]="false">
                <mat-expansion-panel-header>
                    <div class="mat-title">Neues Produkt anlegen</div>
                </mat-expansion-panel-header>

                <app-product-form
                  [formMode]="FormMode.proposal"
                  [disabled]="disableCreateProduct"
                  (submitForm)="createProduct($event)">
                </app-product-form>
            </mat-expansion-panel>
        </div>

        <mat-divider></mat-divider>

        <div class="mt-2">
            <mat-expansion-panel
              class="mat-elevation-z0"
              [expanded]="true">
                <mat-expansion-panel-header>
                    <div class="mat-title">Bestellinformationen</div>
                </mat-expansion-panel-header>

                <form
                  [formGroup]="orderingInformationForm"
                  class="flex flex-col gap-1">
                    <mat-form-field>
                        <mat-label>Artikelnummer</mat-label>
                        <input
                          matInput
                          formControlName="supplierOrderNumber"
                          required/>
                    </mat-form-field>

                    <div
                      class="flex gap-1"
                      formGroupName="unitPriceGroup">
                        <app-mat-input-number
                          class="max-w-[80%]"
                          [required]="
                  this.orderingInformationForm.get('billingFrequencyPriceGroup.billingFrequencyPrice').value == null ||
                  this.orderingInformationForm.get(
                    'billingFrequencyPriceGroup.billingFrequencyPrice'
                  )?.value === ''
                "
                          label="Einmalpreis"
                          [value]="this.orderingInformationForm.get('unitPriceGroup').get('unitPrice').value"
                          (onChanged)="updateUnitPrice($event)">
                        </app-mat-input-number>

                        <mat-form-field class="max-w-20%">
                            <mat-label>Währung</mat-label>
                            <mat-select
                              formControlName="currencyCodeId"
                              [required]="!!this.orderingInformationForm.get('unitPriceGroup').get('unitPrice').value">
                                <mat-option
                                  *ngFor="let currencyCode of currencyCodes$ | async"
                                  [value]="currencyCode.id">
                                    {{ currencyCode.display_name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div
                      class="flex gap-1"
                      formGroupName="billingFrequencyPriceGroup">
                        <app-mat-input-number
                          class="max-w-[80%]"
                          [required]="
                              this.orderingInformationForm.get('unitPriceGroup').get('unitPrice').value == null
                              || this.orderingInformationForm.get('unitPriceGroup').get('unitPrice').value === ''"
                          label="Abrechnungspreis"
                          [value]="this.orderingInformationForm.get('billingFrequencyPriceGroup').get('billingFrequencyPrice').value"
                          (onChanged)="updateBillingFrequencyPrice($event)">
                        </app-mat-input-number>

                        <mat-form-field class="max-w-[20%]">
                            <mat-label>Währung</mat-label>
                            <mat-select
                              formControlName="billingFrequencyCurrencyCodeId"
                              [required]="!!this.orderingInformationForm.get('billingFrequencyPriceGroup').get('billingFrequencyPrice').value">
                                <mat-option
                                  *ngFor="let currencyCode of currencyCodes$ | async"
                                  [value]="currencyCode.id">
                                    {{ currencyCode.display_name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="max-w-[20%]">
                            <mat-label>Abrechnungshäufigkeit</mat-label>
                            <mat-select
                              formControlName="billingFrequencyId"
                              [required]="!!this.orderingInformationForm.get('billingFrequencyPriceGroup.billingFrequencyPrice')?.value">
                                <mat-option
                                  *ngFor="let billingFrequency of billingFrequencies$ | async"
                                  [value]="billingFrequency.id">
                                    {{ billingFrequency.display_name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </form>
            </mat-expansion-panel>
        </div>
    </mat-card-content>
</mat-card>
