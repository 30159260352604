import {ManagementRouteNames} from '@/management/constants/routes.constants';
import {ProductRouteNames} from '@/management/product/constants/routes.constants';
import {Pipe, PipeTransform} from '@angular/core';

// TODO: Needs to be moved to management
@Pipe({
  name: 'productEditRouterLink'
})
export class ProductEditRouterLinkPipe implements PipeTransform {
  transform(productId: number) {
    return [
      '/',
      ManagementRouteNames.MANAGEMENT,
      ProductRouteNames.PRODUCT,
      productId,
      'edit',
    ];
  }
}
