import {SessionQuery} from '@/core/session/state/session.query';
import {User} from '@/core/session/state/user/user.model';
import {Product} from '@/management/product/state/product.model';
import {Supplier} from '@/management/supplier/state/supplier.model';
import {MatCardAlertMode} from '@/shared/enums/mat-card-alert-mode.enum';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ProposalQuery, ProposalState, ProposalStore} from './state/proposal.state';

@Component({
  selector: 'app-supplier-product-proposal',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.scss'],
  providers: [ProposalStore, ProposalQuery]
})
export class ProposalComponent implements OnInit {
  MatCardAlertMode = MatCardAlertMode;
  ProposalState = ProposalState;

  state$: Observable<ProposalState>;
  user$: Observable<User>;
  supplier$: Observable<Supplier>;
  product$: Observable<Product>;
  canPropose$: Observable<boolean>;

  constructor(
    private sessionQuery: SessionQuery,
    private store: ProposalStore,
    private query: ProposalQuery,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.initialize();
  }

  createProposal() {
    this.store.updateState(ProposalState.stepper);
  }

  proposalSupplierProduct() {
    this.store.addOnDb().subscribe();
  }

  navigateToHome() {
    this.router.navigateByUrl('/home');
  }

  reset() {
    this.store.reset();
    this.store.updateState(ProposalState.stepper);
  }

  private initialize() {
    this.user$ = this.sessionQuery.user$;
    this.state$ = this.query.state$;
    this.supplier$ = this.query.supplier$;
    this.product$ = this.query.product$;
    this.canPropose$ = this.query.canPropose$;
  }
}
