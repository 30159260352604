import {ProductCategoriesQuery} from '@/shop/product/state/product-categories/product-categories.query';
import {inject, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'hasChildCategoriesByParentId'
})
export class HasChildCategoriesByParentIdPipe implements PipeTransform {
  private readonly productCategoriesQuery = inject(ProductCategoriesQuery);

  transform(productCategoryId: number) {
    return this.productCategoriesQuery.selectHasChildCategoriesOfParent(productCategoryId);
  }
}
