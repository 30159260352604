import {Product} from '../../product.model';
import {VariantOptionGroup} from '../../types/available-variant-option';
import {VariantAttribute} from '../../variant-attributes/variant-attribute.model';

export const collectAvailableVariantOptions = (variants: Product[], variantAttributes: VariantAttribute[]): VariantOptionGroup[] => {
  if (variants.length === 0 || variantAttributes.length === 0) {
    return [];
  }

  // TODO: Optimize performance (e.g. using lookup map)
  const availableTemplates = variantAttributes.map(variantAttribute => ({
    templateId: variantAttribute.product_attribute_template_id,
    options: variantAttribute.variant_attribute_options.map(option => option.value) ?? [],
  }));

  return variants.reduce((collectedVariantOptions, variant) => {
    if (!variant.product_attributes) {
      return collectedVariantOptions;
    }

    // Filter out all attributes that are not in the available templates or already have been added
    const filteredAttributes = variant.product_attributes.filter(productAttribute => {
        const hasMatchingTemplate = availableTemplates.some(template =>
          template.templateId === productAttribute.product_attribute_template_id && template.options.includes(productAttribute.value),
        );
        const hasExistingVariantOption = collectedVariantOptions.some(accAttribute => accAttribute.productAttributeTemplateId === productAttribute.product_attribute_template_id && accAttribute.options.includes(productAttribute.value));

        return hasMatchingTemplate && !hasExistingVariantOption;
      },
    );

    // Add all new options to the collected variant options
    for (const productAttribute of filteredAttributes) {
      const existingTemplate = collectedVariantOptions.find(accAttribute => accAttribute.productAttributeTemplateId === productAttribute.product_attribute_template_id);

      if (existingTemplate) {
        collectedVariantOptions = collectedVariantOptions.map(accAttribute => {
          if (accAttribute.productAttributeTemplateId === productAttribute.product_attribute_template_id) {
            return accAttribute.options.includes(productAttribute.value)
              ? accAttribute
              : {
                ...accAttribute,
                options: [...accAttribute.options, productAttribute.value],
              }
          }

          return accAttribute;
        });
      } else {
        collectedVariantOptions = [
          ...collectedVariantOptions,
          {
            productAttributeTemplateId: productAttribute.product_attribute_template_id,
            options: [productAttribute.value]
          }
        ]
      }
    }

    return collectedVariantOptions;
  }, [] as VariantOptionGroup[]);
};
