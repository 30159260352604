import {
  ProductAttributeTemplate
} from '@/management/product/state/product-attribute-templates/product-attribute-template.model';
import {
  VariantAttributeOption
} from '@/management/product/state/variant-attribute-options/variant-attribute-option.model';
import {VariantAttribute} from '@/management/product/state/variant-attributes/variant-attribute.model';
import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {VariantAttributesForm} from './types/variant-attributes-form';
import {noExistingVariantAttributeOptionValidator} from './validators/no-existing-variant-attribute-option-validator';

export type VariantAttributesAddDialogData = {
  productAttributeTemplates: ProductAttributeTemplate[];
  variantAttributes: VariantAttribute[];
  selectedProductAttributeTemplateId: ProductAttributeTemplate['id'] | null;
};

export type VariantAttributesAddDialogOutput = {
  productAttributeTemplateId: ProductAttributeTemplate['id'];
  variantAttributeOptions: VariantAttributeOption['value'][];
} | undefined;

@Component({
  selector: 'app-variant-attributes-add-dialog',
  templateUrl: './variant-attributes-add-dialog.component.html',
  styleUrls: ['./variant-attributes-add-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VariantAttributesAddDialogComponent implements OnInit {
  readonly data: VariantAttributesAddDialogData = inject(MAT_DIALOG_DATA);

  private readonly dialogRef = inject(MatDialogRef<VariantAttributesAddDialogComponent>);

  get variantAttributeOptionControl() {
    return this.variantAttributesForm.controls.variantAttributeOption;
  }

  get variantAttributeTemplateControl() {
    return this.variantAttributesForm.controls.variantAttributeTemplate;
  }

  get availableProductAttributes() {
    return this.data.productAttributeTemplates.filter(productAttribute =>
      this.data.selectedProductAttributeTemplateId === productAttribute.id ||
      !this.data.variantAttributes.some(
        variantAttribute => variantAttribute.product_attribute_template_id === productAttribute.id
      )
    );
  }

  variantAttributeOptionsTableHeaders = [
    {key: 'value', title: 'Option'},
  ];

  variantAttributeOptions: VariantAttributeOption['value'][] = [];

  variantAttributesForm: FormGroup<VariantAttributesForm> = new FormGroup({
    variantAttributeTemplate: new FormControl(this.data.selectedProductAttributeTemplateId, Validators.required),
    variantAttributeOption: new FormControl(''),
  }, {
    validators: noExistingVariantAttributeOptionValidator(() => ({
      newVariantAttributeOptions: this.variantAttributeOptions,
      existingVariantAttributes: this.data.variantAttributes,
    })),
  });

  ngOnInit() {
    if (this.data.selectedProductAttributeTemplateId) {
      this.variantAttributesForm.controls.variantAttributeTemplate.disable();
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (this.variantAttributesForm.invalid || this.variantAttributeOptions.length === 0) {
      return;
    }

    this.dialogRef.close({
      productAttributeTemplateId: this.variantAttributesForm.controls.variantAttributeTemplate.value,
      variantAttributeOptions: this.variantAttributeOptions,
    });
  }

  addNewVariantAttributeOption() {
    const option = this.variantAttributesForm.controls.variantAttributeOption.value.trim();
    if (this.variantAttributesForm.invalid || !option) {
      return;
    }

    this.variantAttributeOptions = [...this.variantAttributeOptions, option];
    this.variantAttributesForm.controls.variantAttributeOption.setValue('');
  }

  removeVariantAttributeOption(value: VariantAttributeOption['value']) {
    this.variantAttributeOptions = this.variantAttributeOptions.filter(option => option !== value);
  }
}
