<div class="mat-typography">
    <div
      class="mat-headline"
      mat-dialog-title>Varianten erstellen
    </div>

    <div>
        <div class="mat-subheading-1">Welche Varianten sollen erstellt werden?</div>

        <dqn-table
          size="small"
          [rows]="data.variants"
          [headers]="variantHeaders"
          selectionMode="multi"
          (selectionChange)="updateSelection($event)">
            <td
              *dqnTableCellTemplate="'name'; let variant"
              dqnTableCell>
                {{ variant.product_attributes | nameFromProductAttributes }}
        </dqn-table>
    </div>

    <mat-dialog-actions align="end">
        <button
          mat-button
          color="primary"
          (click)="cancel()">Abbrechen
        </button>

        <button
          mat-raised-button
          color="primary"
          [disabled]="false"
          (click)="save()">
            Anlegen
        </button>
    </mat-dialog-actions>
</div>
