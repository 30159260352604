import {SessionQuery} from '@/core/session/state/session.query';
import {ProductAttributeGroup} from '@/management/product/state/product-attribute-groups/product-attribute-group.model';
import {
  ProductAttributeGroupsQuery
} from '@/management/product/state/product-attribute-groups/product-attribute-groups.query';
import {
  ProductAttributeGroupsService
} from '@/management/product/state/product-attribute-groups/product-attribute-groups.service';
import {
  ProductAttributeTemplate
} from '@/management/product/state/product-attribute-templates/product-attribute-template.model';
import {
  ProductAttributeTemplatesQuery
} from '@/management/product/state/product-attribute-templates/product-attribute-templates.query';
import {
  ProductAttributeTemplatesService
} from '@/management/product/state/product-attribute-templates/product-attribute-templates.service';
import {ProductAttribute} from '@/management/product/state/product-attributes/product-attribute.model';
import {HttpClient} from '@angular/common/http';
import {Component, inject, Inject, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {MatCardAlertMode} from '../../../../enums/mat-card-alert-mode.enum';
import {ApiResponse} from '../../../../types/api/api-response';
import {getHttpOptionsWithInclude} from '../../../../utils/functions/http-params';

@Component({
  selector: 'app-product-attributes-add-dialog',
  templateUrl: './product-attributes-add-dialog.component.html',
  styleUrls: ['./product-attributes-add-dialog.component.scss'],
})
export class ProductAttributesAddDialogComponent implements OnInit {
  alive = true;
  matCardAlertMode = MatCardAlertMode;
  productAttributeGroups$: Observable<ProductAttributeGroup[]>;
  productAttributeTemplates$: Observable<ProductAttributeTemplate[]>;
  selectedProductAttributeTemplatesDataSource: MatTableDataSource<
    ProductAttributeTemplate
  > = new MatTableDataSource<ProductAttributeTemplate>([]);
  selectedProductAttributeGroupControl: UntypedFormControl = new UntypedFormControl();
  selectedProductAttributeTemplateControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns: string[] = [
    'productAttributeTemplateName',
    'quantityUnitDisplayName',
    'quantityUnitDisplayAbbreviation',
    'actions',
  ];
  productId: number;
  tenantId: number;

  private readonly dialogRef = inject(MatDialogRef<ProductAttributesAddDialogComponent>);
  private readonly productAttributeGroupsService = inject(ProductAttributeGroupsService);
  private readonly productAttributeGroupsQuery = inject(ProductAttributeGroupsQuery);
  private readonly productAttributeTemplatesService = inject(ProductAttributeTemplatesService);
  private readonly productAttributeTemplatesQuery = inject(ProductAttributeTemplatesQuery);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    this.productId = data.productId;
    this.tenantId = data.tenantId;
  }

  ngOnInit(): void {
    this.productAttributeGroupsService.get().subscribe();
    this.productAttributeGroups$ = this.productAttributeGroupsQuery.productAttributeGroups$;
    this.productAttributeTemplatesService.get().subscribe();
    this.productAttributeTemplates$ = this.productAttributeTemplatesQuery.productAttributeTemplates$;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  selectProductAttributeGroup(productAttributeGroup: ProductAttributeGroup) {
    this.selectedProductAttributeGroupControl.setValue(productAttributeGroup);

    if (productAttributeGroup?.id) {
      this.getProductAttributeTemplatesByProductAttributeGroupId(productAttributeGroup.id).pipe(
        tap(response => {
          const productAttributeTemplates = response.data;

          if (productAttributeTemplates) {
            productAttributeTemplates.forEach((productAttributeTemplate) => {
              if (
                !this.selectedProductAttributeTemplatesDataSource.data.find(
                  (selectedProductAttributeTemplate) => selectedProductAttributeTemplate.id === productAttributeTemplate.id
                )
              ) {
                this.selectedProductAttributeTemplatesDataSource.data.push(productAttributeTemplate);
                this.selectedProductAttributeTemplatesDataSource._updateChangeSubscription();
              }
            });
          }

          this.selectedProductAttributeGroupControl.reset();
        }),
      ).subscribe();
    }
  }

  selectProductAttribute(productAttributeTemplate: ProductAttributeTemplate) {
    this.selectedProductAttributeTemplateControl.setValue(productAttributeTemplate);

    if (productAttributeTemplate?.id) {
      this.getProductAttributeTemplateById(productAttributeTemplate.id).pipe(
        tap(response => {
          const productAttributeTemplate = response.data;

          if (
            productAttributeTemplate &&
            !this.selectedProductAttributeTemplatesDataSource.data.find(
              (selectedProductAttributeTemplate) => selectedProductAttributeTemplate.id === productAttributeTemplate.id
            )
          ) {
            this.selectedProductAttributeTemplatesDataSource.data.push(productAttributeTemplate);
            this.selectedProductAttributeTemplatesDataSource._updateChangeSubscription();
          }

          this.selectedProductAttributeTemplateControl.reset();
        }),
      ).subscribe();
    }
  }

  getProductAttributeTemplateById(id: ProductAttributeTemplate['id']) {
    const options = getHttpOptionsWithInclude('productAttributeType,quantityUnit', {
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http.get<ApiResponse<ProductAttributeTemplate>>(
      environment.api.baseUrl + 'product-attribute-templates/' + id,
      options
    );
  }

  getProductAttributeTemplatesByProductAttributeGroupId(id: ProductAttributeGroup['id']) {
    const options = getHttpOptionsWithInclude('productAttributeType,quantityUnit', {
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http.get<ApiResponse<ProductAttributeTemplate[]>>(
      environment.api.baseUrl +
      'product-attribute-groups/' +
      id +
      '/product-attribute-templates',
      options
    );
  }

  removeSelectedAttributeTemplate(id: number): void {
    this.selectedProductAttributeTemplatesDataSource.data = this.selectedProductAttributeTemplatesDataSource.data.filter(
      selectedProductAttributeTemplate => selectedProductAttributeTemplate.id !== id
    );
    this.selectedProductAttributeTemplatesDataSource._updateChangeSubscription();
  }

  add(): void {
    const productAttributes: ProductAttribute[] = [];
    this.selectedProductAttributeTemplatesDataSource.data.forEach(selectedProductAttributeTemplate => {
      productAttributes.push({
        id: null,
        value: '',
        tenant_id: this.tenantId,
        product_id: this.productId,
        product_attribute_template_id: selectedProductAttributeTemplate.id,
        product_attribute_template: selectedProductAttributeTemplate,
      } as ProductAttribute);
    });
    this.dialogRef.close(productAttributes);
  }
}
