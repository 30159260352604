import {Pipe, PipeTransform} from '@angular/core';
import {parseProductAttributeValue} from '../../../state/product-attributes/helpers/parse-product-attribute-value';
import {ProductAttribute} from '../../../state/product-attributes/product-attribute.model';

@Pipe({
  name: 'parseProductAttributeValue'
})
export class ParseProductAttributeValuePipe implements PipeTransform {
  transform(productAttributeValue: ProductAttribute['value']) {
    return parseProductAttributeValue(productAttributeValue);
  }
}
