import {Status} from '../supplier-product-available-statuses/statuses/status.model';
import {CurrencyCode} from '@/shared/state/currency-codes/currency-code.model';
import {BillingFrequency} from '@/shared/state/billing-frequencies/billing-frequency.model';
import {User} from '@/core/session/state/user/user.model';
import {Supplier} from '../../../supplier/state/supplier.model';
import {Product} from '../product.model';
import {WithTimestamps} from '@/shared/types/api/with-timestamps';

export interface SupplierProduct extends WithTimestamps {
  id: number;
  product_id: number;
  product?: Product;
  unit_price?: number | null;
  supplier_order_number?: string | null;
  priority?: number | null;
  tenant_id?: number;
  is_favorite?: boolean;
  created_by_id?: number;
  created_by?: User;
  supplier_id?: number;
  supplier?: Supplier;
  status_id?: number;
  status?: Status;
  currency_code_id?: number;
  currency_code?: CurrencyCode;
  billing_frequency_currency_code?: CurrencyCode;
  billing_frequency?: BillingFrequency;
  billing_frequency_price?: number | null;
  billing_frequency_currency_code_id?: number | null;
  billing_frequency_id?: number | null;
  promoted_order_number?: number | null;
}

export const createSupplierProduct = (params: Partial<SupplierProduct>) => ({...params} as SupplierProduct);
