<mat-card class="mat-elevation-z0">
    <mat-card-content>
        <form [formGroup]="form">
            <div class="select-supplier">
                <div class="mat-title">Lieferanten auswählen</div>

                <dqn-autocomplete
                  class="w-full"
                  formControlName="supplierId"
                  label="Lieferanten auswählen"
                  placeholder="Lieferant suchen ..."
                  [isRequired]="true"
                  [options]="(suppliers$ | async)">
                </dqn-autocomplete>
            </div>
        </form>

        <div class="create-supplier">
            <mat-expansion-panel
              class="mat-elevation-z0"
              [expanded]="false">
                <mat-expansion-panel-header>
                    <div class="mat-title">Neuen Lieferant anlegen</div>
                </mat-expansion-panel-header>

                <app-supplier-form
                  [formMode]="FormMode.proposal"
                  (onChanged)="onSupplierFormChanged($event)"
                  [disabled]="disableCreateSupplier">
                </app-supplier-form>

                <div class="flex justify-end mt-2">
                    <button
                      mat-raised-button
                      color="primary"
                      (click)="proposal()"
                      [disabled]="!newSupplier">
                        Lieferanten vorschlagen
                    </button>
                </div>
            </mat-expansion-panel>
        </div>
    </mat-card-content>
</mat-card>
