import {Tenant} from '@/core/session/state/tenant/tenant.model';
import {CurrencyCode} from '@/shared/state/currency-codes/currency-code.model';
import {StatusNames} from '@/shared/state/status/enums/status-names.enum';
import {WithTimestamps} from '@/shared/types/api/with-timestamps';
import {SupplierProduct} from '@/shop/product/state/supplier-products/supplier-product.model';
import {Supplier} from '../../supplier/state/supplier.model';
import {ProductAttribute} from './product-attributes/product-attribute.model';
import {ProductCategory} from './product-categories/product-category.model';
import {QuantityUnit} from './quantity-units/quantity-unit.model';

type ProductIncludes = {
  product_category?: ProductCategory;
  quantity_unit?: QuantityUnit;
  scale_unit?: QuantityUnit;
  product_attributes?: ProductAttribute[];
  supplier_products?: SupplierProduct[];
  suppliers?: Supplier[];
};

type ProductCustomAttributes = {
  price_unit_price_from: number | null;
  price_unit_price_to: number | null;
  price_unit_price_currency_code_id: CurrencyCode['id'];
  price_billing_frequency_price_from: number | null;
  price_billing_frequency_price_to: number | null;
  price_billing_frequency_price_currency_code_id: CurrencyCode['id'];
  supplier_product_count: number;
  variant_count: number;
  preferred_supplier_product_id?: SupplierProduct['id'];
  status?: StatusNames;
};

// TODO: Could be merged with model in shop
export interface Product extends ProductIncludes, ProductCustomAttributes, WithTimestamps {
  id: number;
  tenant_id?: Tenant['id'];
  name?: string;
  description?: string | null;
  description_long?: string | null;
  keywords?: string | null;
  ean_gtin?: string | null;
  product_category_id?: ProductCategory['id'];
  quantity_unit_id?: QuantityUnit['id'] | null;
  scale_unit_id: QuantityUnit['id'] | null;
  selling_quantity: number | null;
  basic_quantity: number | null;
  requires_approval?: boolean;
  requires_offer?: boolean;
  images?: any;
  is_virtual?: boolean;
  parent_id?: Product['id'] | null;
  is_favorite?: boolean;
}

export interface ProductVariant extends Product {
  parent_id?: Product['id'];
}

export const createProduct = (params: Partial<Product>) => ({...params} as Product);
