import {Pipe, PipeTransform} from '@angular/core';
import {ShopRouteNames} from '../../../../constants/routes.constants';
import {ProductRouteNames} from '../../../constants/routes.constants';

@Pipe({
  name: 'supplierProductDetailsRouterLink'
})
export class SupplierProductDetailsRouterLinkPipe implements PipeTransform {
  transform(productId: number) {
    return [
      '/',
      ShopRouteNames.SHOP,
      ProductRouteNames.PRODUCT,
      productId,
    ];
  }
}
