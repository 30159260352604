<div *ngFor="let optionGroup of availableOptions">
    <span>{{ (optionGroup.productAttributeTemplateId | productAttributeTemplateById$ | async)?.display_name }}</span>

    <div class="flex gap-1 flex-wrap">
        <button
          *ngFor="let option of optionGroup.options"
          app-variant-option
          (click)="selectOption(option, optionGroup.productAttributeTemplateId)"
          [disabled]="loading"
          [selectable]="(option | isVariantOptionSelectable: optionGroup.productAttributeTemplateId : (variantSelectionQuery.selectedOptions$ | async) : variants) === true"
          [selected]="(option | isVariantOptionSelected: optionGroup.productAttributeTemplateId : (variantSelectionQuery.selectedVariant$ | async))">
            {{ option }}
        </button>
    </div>
</div>
