import {AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const error: ValidationErrors = {email: true};
    if (control.dirty && control.value !== '') {
      try {
        let manipulatedEmailReceiversString: string = control.value.replace(/;/g, ","); // Replace all ";" -> ","
        manipulatedEmailReceiversString = manipulatedEmailReceiversString.replace(/\s/g, ""); // Remove all whitespaces

        let receivers = manipulatedEmailReceiversString.split(",");
        receivers = receivers.filter(Boolean); // Remove all empty elements

        for (const receiver of receivers) {
          if (Validators.email(new UntypedFormControl(receiver))) {
            throw new Error();
          }
        }
      } catch (e) {
        return error;
      }
    }
    return null;
  };
}
