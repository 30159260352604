/** Will generate a new artificial id (negative number) based on the provided list.*/
export const generateNextArtificialId = <T extends { id: number }>(array: T[]) => {
  const existingItemsWithArtificialId = array?.filter(item => item.id < 0) ?? [];

  if (existingItemsWithArtificialId.length === 0) {
    return -1;
  }

  return existingItemsWithArtificialId.reduce((maxId, item) =>
    // Find the lowest id
    item.id < maxId ? item.id : maxId, -1
  ) - 1;
}
