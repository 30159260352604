<mat-form-field>
  <mat-label *ngIf="label">{{label}}</mat-label>
  <input type="text"
         placeholder="Bitte auswählen"
         matInput
         [formControl]="selectedOptionControl"
         [matAutocomplete]="auto"
         [required]="required">
  <mat-icon matSuffix *ngIf="!disableSuffixIcon">{{inputIconName}}</mat-icon>
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                    [displayWith]="displayValueOfOptionControl.bind(this)"
                    (opened)="toggleOpenedState()"
                    (closed)="validateSelectedOption()">
    <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
      <ng-container *ngIf="!useDisplayName">
        {{option.name}}
      </ng-container>
      <ng-container *ngIf="useDisplayName">
        {{option.display_name}}
      </ng-container>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
