import {isAnythingLoading} from '@/shared/utils/functions/is-anything-loading';
import {Injectable} from '@angular/core';
import {combineQueries, Query} from '@datorama/akita';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {OrderActivitiesQuery} from '../order/state/order-activities/order-activities.query';
import {OrderAvailableStatusesQuery} from '../order/state/order-available-statuses/order-available-statuses.query';
import {OrderItemsQuery} from '../order/state/order-items/order-items.query';
import {OrderStatusesQuery} from '../order/state/order-statuses/order-statuses.query';
import {OrderTasksQuery} from '../order/state/order-tasks/order-tasks.query';
import {OrdersQuery} from '../order/state/orders.query';
import {ProductsQuery} from '../product/state/products.query';
import {SupplierProductsQuery} from '../product/state/supplier-products/supplier-products.query';
import {VariantAttributeOptionsQuery} from '../product/state/variant-attribute-options/variant-attribute-options.query';
import {VariantAttributesQuery} from '../product/state/variant-attributes/variant-attributes.query';
import {ShoppingCartQuery} from '../shopping-cart/state/shopping-cart.query';
import {UiShopState, UiShopStore} from './ui-shop.store';

@Injectable({providedIn: 'root'})
export class UiShopQuery extends Query<UiShopState> {
  isLoading$ = combineQueries([
    this.shoppingCartQuery.selectLoading(),
    this.ordersQuery.selectLoading(),
    this.orderTasksQuery.selectLoading(),
    this.orderItemsQuery.selectLoading(),
    this.orderAvailableStatusesQuery.selectLoading(),
    this.orderActivitiesQuery.selectLoading(),
    this.orderStatusesQuery.selectLoading(),
    this.productsQuery.selectLoading(),
    this.supplierProductsQuery.selectLoading(),
    this.variantAttributesQuery.selectLoading(),
    this.variantAttributeOptionsQuery.selectLoading(),
  ]).pipe(
    map(loading => isAnythingLoading(...loading)),
    distinctUntilChanged(),
  );

  constructor(
    protected store: UiShopStore,
    private shoppingCartQuery: ShoppingCartQuery,
    private ordersQuery: OrdersQuery,
    private orderTasksQuery: OrderTasksQuery,
    private orderItemsQuery: OrderItemsQuery,
    private orderAvailableStatusesQuery: OrderAvailableStatusesQuery,
    private orderActivitiesQuery: OrderActivitiesQuery,
    private orderStatusesQuery: OrderStatusesQuery,
    private productsQuery: ProductsQuery,
    private supplierProductsQuery: SupplierProductsQuery,
    private variantAttributesQuery: VariantAttributesQuery,
    private variantAttributeOptionsQuery: VariantAttributeOptionsQuery,
  ) {
    super(store);
  }

}
