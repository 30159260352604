import {Directive} from '@angular/core';

@Directive({
  selector: '[appBreadCrumbItem]',
  host: {
    class: 'bread-crumb-item'
  },
})
export class BreadCrumbItemDirective {
}
