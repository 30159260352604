<div class="flex justify-end items-center">
    <mat-icon
      class="close-button"
      [mat-dialog-close]="null"
      matTooltip="Abbrechen">close
    </mat-icon>
</div>

<mat-dialog-content>
    <div class="dialog-container mat-typography flex flex-col gap-2">
        <div class="mat-title">
            Alle Auswahlmöglichkeiten zu {{ data.product.name }}
        </div>

        <app-product-variant-selection
          *ngIf="data.variants"
          [variants]="data.variants"
          [initialSelection]="initialSelection"
          [variantAttributes]="(productsQuery.variantAttributesForActiveProduct$ | async)"
          [loading]="supplierProductsQuery.isLoading$ | async"
          (selectVariant)="selectVariant($event)" />

        <ng-container *ngIf="selectedVariant">
            <app-supplier-products-product-list
              [supplierProducts]="selectedVariant.id | supplierProductsForProduct$ | async"
              [loading]="supplierProductsQuery.isLoading$ | async"
              [product]="selectedVariant"
              [allowNavigation]="true"
              (onAddToShoppingCart)="addToShoppingCart($event)"
              (onNavigateToSupplierProduct)="cancel()" />
        </ng-container>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
      mat-button
      color="primary"
      (click)="cancel()">Abbrechen
    </button>
</mat-dialog-actions>
