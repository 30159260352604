import {WithTimestamps} from '@/shared/types/api/with-timestamps';
import {ProductAttributeTemplate} from '../product-attribute-templates/product-attribute-template.model';

export interface ProductAttribute extends WithTimestamps {
  id: number;
  value?: string;
  tenant_id: number;
  product_id: number;
  product_attribute_template_id: number;
  product_attribute_template?: ProductAttributeTemplate;
}

export const createProductAttribute = (params: Partial<ProductAttribute>) => ({...params} as ProductAttribute);
