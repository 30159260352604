<mat-form-field>
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input
      [required]="required"
      matInput
      [formControl]="valueControl"
      [errorStateMatcher]="errorStateMatcher"
      (focus)="onTouched.emit()">

    <mat-error *ngIf="error">
        {{ error }}
    </mat-error>
</mat-form-field>
