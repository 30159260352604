import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {QuantityUnitsState, QuantityUnitsStore} from './quantity-units.store';

@Injectable({providedIn: 'root'})
export class QuantityUnitsQuery extends QueryEntity<QuantityUnitsState> {
  quantityUnits$ = this.selectAll();

  constructor(protected store: QuantityUnitsStore) {
    super(store);
  }
}
