import {Pipe, PipeTransform} from '@angular/core';
import {isNullOrUndefined} from '../helpers/is-null-or-undefined';

@Pipe({
  name: 'isNullOrUndefined'
})
export class IsNullOrUndefinedPipe implements PipeTransform {
  transform(value) {
    return isNullOrUndefined(value);
  }
}
