import {Supplier} from '@/management/supplier/state/supplier.model';
import {CostCenter} from '@/shared/state/cost-centers/cost-center.model';
import {OrderApprovalWorkflowFormData} from '../../order/state/order.model';
import {SupplierProduct} from '../../product/state/supplier-products/supplier-product.model';

export interface ShoppingCart {
  comments: string;
  approvalWorkflowData: OrderApprovalWorkflowFormData;
  shoppingCartItemsUnitPriceTotal: number;
  shoppingCartItemsBillingFrequencyPriceTotal: number;
  shoppingCartItemsGroupBySuppliers: ShoppingCartItemsForSupplier[];
}

export interface ShoppingCartItem {
  supplier_product_id: SupplierProduct['id'];
  quantity: number;
  unitPriceTotal: number;
  billingFrequencyPriceTotal: number;
  cost_center_id: CostCenter['id'];
}

export type ShoppingCartItemWithSupplierProduct = ShoppingCartItem & SupplierProduct;

export interface ShoppingCartItemsForSupplier {
  supplier_id: Supplier['id'];
  supplier_name: Supplier['name'];
  unitPriceTotal: number;
  billingFrequencyPriceTotal: number;
  shoppingCartItems: ShoppingCartItemWithSupplierProduct[];
}

export const createShoppingCartItem = (params: Partial<ShoppingCartItem>) => ({
  unitPriceTotal: 0,
  billingFrequencyPriceTotal: 0,
  quantity: 1,
  ...params
} as ShoppingCartItem);
