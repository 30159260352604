import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';

/** Unit price or billing frequency price one of them is required */
export const unitPriceNotEmptyThenCurrencyCodeIdRequiredValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const unitPriceControl = control.get('unitPrice');
  const currencyCodeIdControl = control.get('currencyCodeId');

  const unitPriceControlValueIsEmpty = unitPriceControl?.value == null || unitPriceControl?.value?.value === '';
  const currencyCodeIdControlValueIsEmpty = currencyCodeIdControl?.value == null || currencyCodeIdControl?.value?.value === '';

  return unitPriceControlValueIsEmpty || (!unitPriceControlValueIsEmpty && !currencyCodeIdControlValueIsEmpty) ? null : {'required': true};
};

@Directive({
  selector: '[appUnitPriceNotEmptyThenCurrencyCodeIdRequired]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: UnitPriceNotEmptyThenCurrencyCodeIdRequiredValidatorDirective,
    multi: true
  }]
})
export class UnitPriceNotEmptyThenCurrencyCodeIdRequiredValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return unitPriceNotEmptyThenCurrencyCodeIdRequiredValidator(control);
  }
}
