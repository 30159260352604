import {ImageUrl} from '../image-urls/image-url.model';

export interface TenantImage {
  tenant_id: number | string;
  name: string;
  urls: ImageUrl;
}

export const createTenantImage = (params: Partial<TenantImage>) => ({
  ...params
} as TenantImage);
