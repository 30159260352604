import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnDestroy, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute, Router} from '@angular/router';
import {filterNilValue, withTransaction} from '@datorama/akita';
import {RouterQuery} from '@datorama/akita-ng-router-store';
import {BehaviorSubject, forkJoin, of} from 'rxjs';
import {distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {ShoppingCartService} from '../../../shopping-cart/state/shopping-cart.service';
import {
  ProductAttributeTemplatesService
} from '../../state/product-attribute-templates/product-attribute-templates.service';
import {ProductAttribute} from '../../state/product-attributes/product-attribute.model';
import {ProductCategoriesQuery} from '../../state/product-categories/product-categories.query';
import {ProductCategoriesService} from '../../state/product-categories/product-categories.service';
import {Product, ProductVariant} from '../../state/product.model';
import {ProductsQuery} from '../../state/products.query';
import {ProductsService} from '../../state/products.service';
import {SupplierProduct} from '../../state/supplier-products/supplier-product.model';
import {SupplierProductsQuery} from '../../state/supplier-products/supplier-products.query';
import {SupplierProductsService} from '../../state/supplier-products/supplier-products.service';
import {VariantAttributesService} from '../../state/variant-attributes/variant-attributes.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  readonly supplierProductsQuery = inject(SupplierProductsQuery);
  readonly productCategoriesQuery = inject(ProductCategoriesQuery);
  readonly productsQuery = inject(ProductsQuery);

  private readonly productCategoriesService = inject(ProductCategoriesService);
  private readonly supplierProductsService = inject(SupplierProductsService);
  private readonly productsService = inject(ProductsService);
  private readonly routerQuery = inject(RouterQuery);
  private readonly variantAttributesService = inject(VariantAttributesService);
  private readonly productAttributeTemplatesService = inject(ProductAttributeTemplatesService);
  private readonly shoppingCartService = inject(ShoppingCartService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);

  get isShopActive() {
    return environment.shopActive;
  }

  environment = environment;

  breadCrumbs$ = this.supplierProductsQuery.activeSupplierProduct$.pipe(
    filterNilValue(),
    switchMap(({product}) => this.productCategoriesQuery.selectBreadCrumbsForProductCategoryById(product.product_category_id)),
  );

  displayedColumns: string[] = ['attribute', 'value'];
  quantities: number[] = [1];
  quantity = 1;

  private productLoadingSubject = new BehaviorSubject(false);
  productLoading$ = this.productLoadingSubject.asObservable().pipe(
    distinctUntilChanged(),
  );

  ngOnInit() {
    this.quantity = 1;
    for (let counter = 2; counter < 100; counter++) {
      this.quantities.push(counter);
    }

    this.routerQuery.selectParams('supplierProductId').pipe(
      filter(id => !!id),
      // Fetch supplier product
      tap(() => this.productLoadingSubject.next(true)),
      switchMap((supplierProductId: number) => this.supplierProductsService.getById(supplierProductId).pipe(
        // Fetch product by product_id
        switchMap(({data: supplierProduct}) => this.productsService.getById(supplierProduct.product_id).pipe()),
        switchMap(({data: product}) => this.getDetailsForProduct(product)),
        withTransaction(product => {
          this.supplierProductsService.setActiveSupplierProduct(supplierProductId);
          this.productCategoriesService.setActiveProductCategory(product.product_category_id);
          this.productsService.setActiveProduct(product.id);
        })),
      ),
      tap(() => this.productLoadingSubject.next(false)),
      // Delay loading of supplier products for product category after product loading is finished
      switchMap(product => this.productsService.getByProductCategoryId(product.product_category_id)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    this.productAttributeTemplatesService.get().subscribe()
  }

  ngOnDestroy() {
    this.supplierProductsService.setActiveSupplierProduct(null);
    this.productCategoriesService.setActiveProductCategory(null);
    this.productsService.setActiveProduct(null);
  }

  addItemToShoppingCart(supplierProductId: SupplierProduct['id']) {
    this.shoppingCartService.addProductToCart(supplierProductId, this.quantity);
    this.navigateToShoppingCart();
  }

  navigateToShoppingCart() {
    this.shoppingCartService.navigateToShoppingCart();
  }

  selectVariant(variant: ProductVariant) {
    if (!variant) {
      return;
    }

    this.router.navigate(['..', variant.preferred_supplier_product_id], {
      relativeTo: this.activatedRoute,
    });
  }

  trackByProductAttributeId(index: number, productAttribute: ProductAttribute) {
    return productAttribute.id;
  }

  private getDetailsForProduct(product: Product) {
    // Check if product is variant (= parentId is filled)
    if (product.parent_id) {
      return this.productsService.getById(product.parent_id).pipe(
        // If initial product had a parent product, we already know the parent will be a virtual product
        switchMap(({data: virtualProduct}) => forkJoin([
            this.productsService.getVariantsForProduct(virtualProduct.id),
            this.variantAttributesService.getForProduct(virtualProduct.id),
            this.supplierProductsService.getForProduct(virtualProduct.id),
          ]).pipe(
            map(() => virtualProduct),
          ),
        ),
      );
    }

    return of(product);
  }
}
