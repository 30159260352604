import {Tenant} from '@/core/session/state/tenant/tenant.model';
import {WithBaseTimestamps} from '@/shared/types/api/with-timestamps';
import {ProductAttributeTemplate} from '../product-attribute-templates/product-attribute-template.model';
import {Product} from '../product.model';
import {VariantAttributeOption} from '../variant-attribute-options/variant-attribute-option.model';

interface VariantAttributeIncludes {
  variant_attribute_options?: VariantAttributeOption[];
  product_attribute_template?: ProductAttributeTemplate;
}

export interface VariantAttribute extends VariantAttributeIncludes, WithBaseTimestamps {
  id: number;
  product_attribute_template_id: ProductAttributeTemplate['id'];
  tenant_id: Tenant['id'];
  product_id: Product['id'];
}

export const createVariantAttribute = (params: Partial<VariantAttribute>) => ({...params} as VariantAttribute);
