import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {map} from 'rxjs/operators';
import {Product} from '../product.model';
import {VariantAttributesState, VariantAttributesStore} from './variant-attributes.store';

@Injectable({providedIn: 'root'})
export class VariantAttributesQuery extends QueryEntity<VariantAttributesState> {
  variantAttributes$ = this.selectAll();

  constructor(protected store: VariantAttributesStore) {
    super(store);
  }

  getVariantAttributesForProduct(productId: Product['id']) {
    return this.getAll().filter(variantAttribute => variantAttribute.product_id === productId);
  }

  selectVariantAttributesForProduct(productId: Product['id']) {
    return this.variantAttributes$.pipe(
      map(variantAttributes => variantAttributes.filter(variantAttribute => variantAttribute.product_id === productId))
    );
  }
}
