import {IconDefinition} from '@fortawesome/pro-duotone-svg-icons';
import {
  faArrowLeft as faArrowLeftRegular,
  faCircleInfo as faCircleInfoRegular,
} from '@fortawesome/pro-regular-svg-icons';
import {faCrown as faCrownSolid} from '@fortawesome/pro-solid-svg-icons';

export const getFontawesomeIcons = () => [
  // Solid
  faCrownSolid,
  // Regular
  faCircleInfoRegular,
  faArrowLeftRegular,
] as IconDefinition[];
