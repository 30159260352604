import {ProductAttributeBooleanValue} from '../enums/product-attribute-boolean-value';
import {ProductAttribute} from '../product-attribute.model';

/**
 * Will parse the value of the product attribute because it can contain special values that are not just strings. Will
 * return the value itself if no parsing is needed.
 */
export const parseProductAttributeValue = (productAttributeValue: ProductAttribute['value']) => {
  // Boolean values
  if (isProductAttributeValueBooleanValue(productAttributeValue)) {
    return parseProductAttributeBooleanValue(productAttributeValue);
  }

  return productAttributeValue;
}

const isProductAttributeValueBooleanValue = (productAttributeValue: ProductAttribute['value']) => {
  return productAttributeValue === ProductAttributeBooleanValue.True || productAttributeValue === ProductAttributeBooleanValue.False;
}

/** Will parse special product attributes that represent a boolean value. */
const parseProductAttributeBooleanValue = (productAttributeValue: ProductAttribute['value']) => {
  return productAttributeValue === ProductAttributeBooleanValue.True ? 'Ja' : 'Nein';
}
