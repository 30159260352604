import {
  VariantAttributeOption
} from '@/management/product/state/variant-attribute-options/variant-attribute-option.model';
import {VariantAttribute} from '@/management/product/state/variant-attributes/variant-attribute.model';
import {FormGroup} from '@angular/forms';
import {VariantAttributesForm} from '../types/variant-attributes-form';

export const noExistingVariantAttributeOptionValidator = (
  getExistingValues: () => {
    newVariantAttributeOptions: VariantAttributeOption['value'][];
    existingVariantAttributes: VariantAttribute[];
  }
) => (form: FormGroup<VariantAttributesForm>) => {
  const {
    newVariantAttributeOptions,
    existingVariantAttributes,
  } = getExistingValues();
  const variantAttributeOptionsControl = form.controls.variantAttributeOption
  const variantAttributeTemplateControl = form.controls.variantAttributeTemplate;

  if (!variantAttributeTemplateControl.value) {
    return null;
  }

  const existingVariantAttribute = existingVariantAttributes.find(
    variantAttribute => variantAttribute.product_attribute_template_id === variantAttributeTemplateControl.value
  );

  const existingOptions = existingVariantAttribute?.variant_attribute_options
      .map(option => option.value)
      // Collect existing variant options and options that are to be added
      .concat(newVariantAttributeOptions)
    ?? newVariantAttributeOptions;

  const hasError = existingOptions.includes(variantAttributeOptionsControl.value);

  // Set error on options control for form field to show error message
  if (hasError) {
    variantAttributeOptionsControl.setErrors({existingOption: true});
  } else {
    variantAttributeOptionsControl.setErrors(null);
  }

  return null;
}
