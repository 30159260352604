import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {isValidPriceValue} from '../helpers/is-valid-price-value';
import {SupplierFormControls} from '../types/supplier-form-controls';

/**
 * Validate required controls for minimum order value and surcharge if either is set.
 * ValidationError: 'minimum-order-value-required'
 */
export const minimumOrderValueValidator: ValidatorFn = (formGroup: FormGroup<SupplierFormControls>): ValidationErrors | null => {
  const {minimumOrderValue, minimumOrderValueSurcharge} = formGroup.controls;

  const isValidMinimumOrderValue = isValidPriceValue(minimumOrderValue.value);
  const isValidMinimumOrderValueSurcharge = isValidPriceValue(minimumOrderValueSurcharge.value);

  if (
    (isValidMinimumOrderValue && !isValidMinimumOrderValueSurcharge)
    || (!isValidMinimumOrderValue && isValidMinimumOrderValueSurcharge)
  ) {
    return {'minimum-order-value-required': true};
  }

  return null;
};
