import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {isValidPriceValue} from '../helpers/is-valid-price-value';
import {SupplierFormControls} from '../types/supplier-form-controls';

/**
 * Validate required controls for shipping costs and minimum value for free shipping if free shipping is set.
 * ValidationError: 'shipping-costs-required'
 */
export const shippingCostsValidator: ValidatorFn = (formGroup: FormGroup<SupplierFormControls>): ValidationErrors | null => {
  const {shippingCostsMinimumOrderValue, shippingCosts} = formGroup.controls;

  const isValidShippingCostsMinimumOrderValue = isValidPriceValue(shippingCostsMinimumOrderValue.value);
  const isValidShippingCosts = isValidPriceValue(shippingCosts.value);

  if (isValidShippingCostsMinimumOrderValue && !isValidShippingCosts) {
    return {'shipping-costs-required': true};
  }

  return null;
};
