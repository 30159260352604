import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {VariantAttributeOptionsState, VariantAttributeOptionsStore} from './variant-attribute-options.store';

@Injectable({providedIn: 'root'})
export class VariantAttributeOptionsQuery extends QueryEntity<VariantAttributeOptionsState> {

  constructor(protected store: VariantAttributeOptionsStore) {
    super(store);
  }

  productAttributeTemplates$ = this.selectAll();
}
