<div class="favorite-icon-container">
    <button
      mat-mini-fab
      (click)="toggleFavorite()"
      [matTooltip]="tooltip"
      type="button"
      class="favorite-icon"
      aria-label="Hinzufügen oder Entfernen von einem Produkt als Favorit">
        <mat-icon fontSet="material-icons-outlined">{{ favoriteIcon }}</mat-icon>
    </button>
</div>
