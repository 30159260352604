import {User} from '@/core/session/state/user/user.model';
import {Supplier} from '@/management/supplier/state/supplier.model';
import {BillingFrequency} from '@/shared/state/billing-frequencies/billing-frequency.model';
import {CurrencyCode} from '@/shared/state/currency-codes/currency-code.model';
import {WithTimestamps} from '@/shared/types/api/with-timestamps';
import {Product} from '../product.model';
import {Status} from '../supplier-product-available-statuses/statuses/status.model';

export interface SupplierProductIncludes {
  product?: Product;
  created_by?: User;
  supplier?: Supplier;
  status?: Status;
  currency_code?: CurrencyCode;
  billing_frequency_currency_code?: CurrencyCode;
}

export interface SupplierProduct extends WithTimestamps, SupplierProductIncludes {
  id: number;
  product_id: Product['id'];
  unit_price?: number | null;
  supplier_order_number?: string | null;
  priority?: number | null;
  tenant_id?: number;
  created_by_id?: number;
  supplier_id?: number;
  status_id?: number;
  currency_code_id?: number;
  billing_frequency_currency_code_id?: CurrencyCode['id'] | null;
  billing_frequency_id?: BillingFrequency['id'] | null;
  billing_frequency_price?: number | null;
  promoted_order_number?: number | null;
}

export interface SupplierProductWithRank extends SupplierProduct {
  rank: number;
}

export const createSupplierProduct = (params: Partial<SupplierProduct>) => ({...params} as SupplierProduct);
