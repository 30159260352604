import {SessionQuery} from '@/core/session/state/session.query';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {
  createHttpIncludes,
  getHttpOptionsWithInclude,
  getHttpOptionsWithParams
} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {ID, setLoading} from '@datorama/akita';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {SupplierProduct} from './supplier-product.model';
import {SupplierProductsStore} from './supplier-products.store';

@Injectable({providedIn: 'root'})
export class SupplierProductsService {
  static includes = createHttpIncludes([
    'supplier',
    'status',
    'createdBy',
    'currencyCode',
    'billingFrequencyCurrencyCode',
    'billingFrequency',
  ]);

  private readonly supplierProductsStore = inject(SupplierProductsStore);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);

  add(supplierProduct: SupplierProduct) {
    const options = getHttpOptionsWithInclude(SupplierProductsService.includes, {
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http.post<ApiResponse<SupplierProduct>>(
      environment.api.baseUrl + 'supplier-products',
      supplierProduct,
      options
    ).pipe(
      setLoading(this.supplierProductsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
      tap(({data: addedSupplierProduct}) => {
        this.supplierProductsStore.add(addedSupplierProduct);
      }),
    );
  }

  update(id: number, supplierProduct: Partial<SupplierProduct>) {
    const options = getHttpOptionsWithInclude(SupplierProductsService.includes, {
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http.put<ApiResponse<SupplierProduct>>(
      environment.api.baseUrl + 'supplier-products/' + id,
      supplierProduct,
      options
    ).pipe(
      setLoading(this.supplierProductsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
      tap(({data: updatedSupplierProduct}) => {
        this.supplierProductsStore.update(id, updatedSupplierProduct);
      })
    );
  }

  remove(id: ID) {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery?.tenantId?.toString(),
    });

    return this.http.delete(environment.api.baseUrl + 'supplier-products/' + id, options).pipe(
      setLoading(this.supplierProductsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
      tap(() => {
        this.supplierProductsStore.remove(id);
      })
    );
  }
}
