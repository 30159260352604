import {Tenant} from '@/core/session/state/tenant/tenant.model';
import {WithBaseTimestamps} from '@/shared/types/api/with-timestamps';
import {VariantAttribute} from '../variant-attributes/variant-attribute.model';

export interface VariantAttributeOption extends WithBaseTimestamps {
  id: number;
  value: string;
  tenant_id: Tenant['id'];
  variant_attribute_id: VariantAttribute['id'];
}

export const createVariantAttributeOption = (params: Partial<VariantAttributeOption>) =>
  ({...params} as VariantAttributeOption);
