import {ProductAttribute} from '@/management/product/state/product-attributes/product-attribute.model';
import {Pipe, PipeTransform} from '@angular/core';
import {removeProductAttributesByVariantAttributes} from '../helpers/remove-product-attributes-by-variant-attributes';

@Pipe({
  name: 'filterProductAttributesByVariantAttributes'
})
export class FilterProductAttributesByVariantAttributesPipe implements PipeTransform {
  transform(productAttributes: ProductAttribute[], variantAttributes: ProductAttribute[]) {
    return removeProductAttributesByVariantAttributes(productAttributes, variantAttributes);
  }
}
