import {SessionQuery} from '@/core/session/state/session.query';
import {Product} from '@/management/product/state/product.model';
import {Supplier} from '@/management/supplier/state/supplier.model';
import {handleError} from '@/shared';
import {Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {combineQueries, guid, Query, Store} from '@datorama/akita';
import {EMPTY} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {
  SupplierProductAvailableStatusesQuery
} from '../../../state/supplier-product-available-statuses/supplier-product-available-statuses.query';
import {createSupplierProduct, SupplierProduct} from '../../../state/supplier-products/supplier-product.model';
import {SupplierProductsService} from '../../../state/supplier-products/supplier-products.service';

export enum ProposalState {
  landingPage,
  stepper,
  finished,
}

type State = {
  productCategoryId: number;
  supplierProduct: SupplierProduct;
  supplier: Supplier;
  product: Product;
  state: ProposalState;
};

const createInitialState = (): State => ({
  productCategoryId: null as number,
  supplierProduct: {} as SupplierProduct,
  supplier: {} as Supplier,
  product: {} as Product,
  state: ProposalState.landingPage,
});

@Injectable()
export class ProposalStore extends Store<State> {
  constructor(
    private supplierProductsService: SupplierProductsService,
    private supplierProductAvailableStatusesQuery: SupplierProductAvailableStatusesQuery,
    private sessionQuery: SessionQuery,
    private matSnackbar: MatSnackBar,
  ) {
    super(
      createInitialState(),
      {name: `supplierProductProposal-${guid()}`, resettable: true}
    );
  }

  updateProductCategoryId(value: number) {
    this.update({productCategoryId: value});
  }

  updateSupplier(supplier: Supplier) {
    this.update({supplier});
  }

  updateProduct(product: Product) {
    this.update({product});
  }

  updateSupplierProduct(supplierProduct: SupplierProduct) {
    this.update({supplierProduct});
  }

  updateState(proposalState: ProposalState) {
    this.update({state: proposalState});
  }

  addOnDb() {
    const {product, supplierProduct, supplier} = this.getValue();

    const fullSupplierProduct = createSupplierProduct({
      ...supplierProduct,
      product_id: product.id,
      supplier_id: supplier.id,
      status_id: this.supplierProductAvailableStatusesQuery.getStatusIdByName('proposal'),
      tenant_id: this.sessionQuery.tenantId,
    });

    return this.supplierProductsService.add(fullSupplierProduct).pipe(
      tap(() => this.updateState(ProposalState.finished)),
      catchError(error => {
        handleError(error, this.matSnackbar, this);
        return EMPTY;
      })
    );
  }
}

@Injectable()
export class ProposalQuery extends Query<State> {
  state$ = this.select('state');

  productCategoryId$ = this.select('productCategoryId');
  supplierProduct$ = this.select('supplierProduct');
  supplier$ = this.select('supplier');
  product$ = this.select('product');

  canPropose$ = combineQueries([
    this.product$,
    this.supplierProduct$,
    this.productCategoryId$,
    this.supplier$,
  ]).pipe(
    map(([product, supplierProduct, productCategoryId, supplier]) =>
      // Check if all required information are present
      !!product && !!supplierProduct && !!productCategoryId && !!supplier
    ),
  );

  get productCategoryId() {
    return this.getValue().productCategoryId;
  }

  constructor(protected store: ProposalStore) {
    super(store);
  }
}
