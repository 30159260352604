import {ProductVariant} from '@/management/product/state/product.model';
import {Injectable} from '@angular/core';
import {combineQueries, Query} from '@datorama/akita';
import {map} from 'rxjs/operators';
import {ComponentStore} from '../../../../helpers/component-store/component-store';
import {isArtificialId} from '../../../../helpers/is-artificial-id';

interface State {
  initialVariants: ProductVariant[];
  variants: ProductVariant[];
}

const getInitialState = (): State => ({
  initialVariants: [],
  variants: [],
});

@Injectable()
export class VariantManagementStore extends ComponentStore<State> {
  constructor() {
    super(getInitialState(), 'variant-management');
  }
}

@Injectable()
export class VariantManagementQuery extends Query<State> {
  variants$ = this.select('variants');
  initialVariants$ = this.select('initialVariants');

  hasVariants$ = this.variants$.pipe(
    map(variants => variants.length > 0),
  );

  hasActiveOrInitialVariants$ = combineQueries([
    this.hasVariants$,
    this.initialVariants$,
  ]).pipe(
    map(([hasVariants, initialVariants]) => hasVariants || initialVariants.length > 0),
  );

  get variants() {
    return this.getValue().variants;
  }

  constructor(protected override store: VariantManagementStore) {
    super(store);
  }

  getDeletedVariants() {
    const {initialVariants, variants} = this.getValue();

    // Find all variants that are in the initial variants but not in the final list anymore
    return initialVariants.filter(({id}) => !variants.some(variant => variant.id === id));
  }

  getNewVariants() {
    const {variants} = this.getValue();

    return variants.filter(productAttribute => isArtificialId(productAttribute.id));
  }
}

@Injectable()
export class VariantManagementService {
  constructor(private store: VariantManagementStore) {
  }

  addVariants(newVariants: ProductVariant[]) {
    this.store.update(({variants}) => ({
      variants: [...variants, ...newVariants],
    }));
  }

  removeVariant(variantId: ProductVariant['id']) {
    this.store.update(({variants}) => ({
      variants: variants.filter(variant => variant.id !== variantId),
    }));
  }

  setInitialVariants(variants: ProductVariant[]) {
    this.store.update({initialVariants: variants, variants});
  }
}

export const provideVariantManagement = () => [
  VariantManagementStore,
  VariantManagementQuery,
  VariantManagementService,
];
