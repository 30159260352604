<div class="mat-title">
    Alle Bezugsquellen zu {{ product?.name }}
</div>

<dqn-table
  [rows]="supplierProducts"
  [headers]="supplierProductsTableHeaders"
  [loading]="loading"
  class="supplier-product-list">
    <td
      *dqnTableCellTemplate="'image'; let supplierProduct"
      dqnTableCell>
        <img
          [src]="product.images | productDefaultImageUrl"
          [alt]="'Produktbild ' + product.name">
    </td>

    <td
      *dqnTableCellTemplate="'name'; let supplierProduct"
      dqnTableCell>
        <span class="break-anywhere">
            {{ product.name }}
        </span>
    </td>

    <td
      *dqnTableCellTemplate="'supplier'; let supplierProduct"
      dqnTableCell>
        {{ (supplierProduct.supplier_id | supplierById | async)?.name }}
    </td>

    <td
      *dqnTableCellTemplate="'rating'; let supplierProduct"
      dqnTableCell>
        <span class="eco whitespace-nowrap">
            <ng-container *ngFor="let i of [1, 2, 3, 4]">
                <mat-icon fontSet="material-icons-round">eco</mat-icon>
            </ng-container>
            <ng-container *ngFor="let i of [1]">
                <mat-icon fontSet="material-icons-outlined">eco</mat-icon>
            </ng-container>
        </span>
    </td>

    <td
      *dqnTableCellTemplate="'price'; let supplierProduct"
      dqnTableCell>
        <app-supplier-product-price
          class="whitespace-nowrap"
          [supplierProduct]="supplierProduct" />
    </td>

    <td
      *dqnTableCellTemplate="'addToBasket'; let supplierProduct"
      dqnTableCell>
        <button
          mat-icon-button
          mat-raised-button
          color="primary"
          matTooltip="In den Einkaufswagen"
          (click)="onAddToShoppingCart.emit(supplierProduct)">
            <mat-icon>shopping_cart</mat-icon>
        </button>
    </td>

    <td
      *dqnTableCellTemplate="'goToProduct'; let supplierProduct"
      dqnTableCell>
        <a
          *ngIf="selectedSupplierProduct?.id !== supplierProduct.id; else currentSupplierProduct"
          mat-icon-button
          [routerLink]="supplierProduct.id | supplierProductDetailsRouterLink"
          (click)="onNavigateToSupplierProduct.emit(supplierProduct)">
            <mat-icon>arrow_forward</mat-icon>
        </a>

        <ng-template #currentSupplierProduct>
            <span class="mat-hint">Ausgewählt</span>
        </ng-template>
    </td>
</dqn-table>
