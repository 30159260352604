/* eslint-disable @typescript-eslint/naming-convention */
import {SessionQuery} from '@/core/session/state/session.query';
import {SuppliersStore} from '@/management/supplier/state/suppliers.store';
import {HttpParamOptions} from '@/shared/enums/api/http-param-options';
import {MicrosoftGraphUsersQuery} from '@/shared/state/microsoft-graph-users/microsoft-graph-users.query';
import {ApiResponse} from '@/shared/types/api/api-response';
import {handleError} from '@/shared/utils';
import {
  createHttpAttributes,
  createHttpIncludes,
  getHttpOptionsWithInclude,
  getHttpOptionsWithIncludeAndAttributes,
  getHttpOptionsWithParams
} from '@/shared/utils/functions/http-params';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {Router} from '@angular/router';
import {setLoading} from '@datorama/akita';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {ShopRouteNames} from '../../../constants/routes.constants';
import {ProductRouteNames} from '../../constants/routes.constants';
import {ProductFilterPresets} from '../enums/product-filter-presets';
import {ProductCategory} from '../product-categories/product-category.model';
import {Product} from '../product.model';
import {SupplierProductFilterPresets} from './enums/supplier-product-filter-preset';
import {SupplierProduct, SupplierProductWithRank} from './supplier-product.model';
import {SupplierProductsQuery} from './supplier-products.query';
import {SupplierProductsStore} from './supplier-products.store';

@Injectable({providedIn: 'root'})
export class SupplierProductsService {
  static defaultAttributes = createHttpAttributes([
    'id',
    'supplier_order_number',
    'unit_price',
    'billing_frequency_price',
    'billing_frequency_currency_code_id',
    'billing_frequency_id',
    'supplier_id',
    'status_id',
    'tenant_id',
    'currency_code_id',
    'created_by_id',
    'created_at',
    'updated_at',
    'deleted_at',
    'product_id',
    'is_favorite',
    'promoted_order_number'
  ]);

  static includes = createHttpIncludes([
    'supplier',
    'product',
    'product.images',
    'product.productCategory',
    'product.quantityUnit',
    'product.scaleUnit',
    'product.productAttributes',
    'product.productAttributes.productAttributeTemplate.productAttributeType',
    'product.productAttributes.productAttributeTemplate.quantityUnit',
    'status',
    'createdBy',
    'currencyCode',
    'billingFrequencyCurrencyCode',
    'billingFrequency',
  ]);

  private readonly http = inject(HttpClient);
  private readonly router = inject(Router);
  private readonly snackBar = inject(MatSnackBar);
  private readonly supplierProductsStore = inject(SupplierProductsStore);
  private readonly suppliersStore = inject(SuppliersStore);
  private readonly supplierProductsQuery = inject(SupplierProductsQuery);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly microsoftGraphUsersQuery = inject(MicrosoftGraphUsersQuery);

  get() {
    const options = getHttpOptionsWithParams({
        [HttpParamOptions.Attributes]: SupplierProductsService.defaultAttributes,
        'filter[and][status_id][eq]': 2, // TODO: Use no static id
        tenant_id: this.sessionQuery.tenantId.toString(),
      }
    );

    return this.http
      .get<ApiResponse<SupplierProduct[]>>(environment.api.baseUrl + 'supplier-products', options)
      .pipe(
        setLoading(this.supplierProductsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
        tap(({data: supplierProducts}) => {
          this.supplierProductsStore.set(supplierProducts);
        })
      );
  }

  getForProduct(id: Product['id']) {
    const options = getHttpOptionsWithInclude(SupplierProductsService.includes, {
        [HttpParamOptions.Attributes]: SupplierProductsService.defaultAttributes,
        [HttpParamOptions.FilterPreset]: ProductFilterPresets.Shop,
        tenant_id: this.sessionQuery.tenantId.toString(),
      }
    );

    return this.http
      .get<ApiResponse<SupplierProduct[]>>(environment.api.baseUrl + `products/${id}/supplier-products`, options)
      .pipe(
        setLoading(this.supplierProductsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
        tap(({data: supplierProducts}) => {
          const suppliers = supplierProducts.map(supplierProduct => supplierProduct.supplier);
          this.supplierProductsStore.upsertMany(supplierProducts);
          this.suppliersStore.upsertMany(suppliers);
        })
      );
  }

  getPromoted() {
    const options = getHttpOptionsWithIncludeAndAttributes(
      SupplierProductsService.includes,
      SupplierProductsService.defaultAttributes,
      {
        [HttpParamOptions.FilterPreset]: SupplierProductFilterPresets.Promoted,
        tenant_id: this.sessionQuery.tenantId.toString(),
        'limit': 4
      }
    );

    return this.http
      .get<ApiResponse<SupplierProduct[]>>(environment.api.baseUrl + 'supplier-products', options)
      .pipe(
        setLoading(this.supplierProductsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
        tap(({data: supplierProducts}) => {
          this.supplierProductsStore.set(supplierProducts);
        }),
      );
  }

  getTopSupplierProductsByProductCategoryId(productCategoryId: ProductCategory['id']) {
    const options = getHttpOptionsWithIncludeAndAttributes(
      SupplierProductsService.includes,
      SupplierProductsService.defaultAttributes,
      {
        [HttpParamOptions.FilterPreset]: ProductFilterPresets.Shop,
        tenant_id: this.sessionQuery.tenantId.toString(),
      }
    );

    return this.http.get<ApiResponse<SupplierProductWithRank[]>>(
      environment.api.baseUrl + `product-categories/${productCategoryId}/custom/top-supplier-products`, options
    );
  }

  getById(id: number) {
    const options = getHttpOptionsWithIncludeAndAttributes(
      SupplierProductsService.includes,
      SupplierProductsService.defaultAttributes,
      {
        'filter[and][status_id][eq]': 2,
        tenant_id: this.sessionQuery.tenantId.toString(),
      }
    );

    return this.http
      .get<ApiResponse<SupplierProduct>>(environment.api.baseUrl + 'supplier-products/' + id, options)
      .pipe(
        setLoading(this.supplierProductsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
        tap((response) => {
          this.supplierProductsStore.upsert(id, response['data']);
        }),
      );
  }

  add(supplierProduct: SupplierProduct) {
    const options = getHttpOptionsWithIncludeAndAttributes(
      SupplierProductsService.includes,
      SupplierProductsService.defaultAttributes,
      {
        tenant_id: this.sessionQuery.tenantId.toString(),
      },
    );

    return this.http
      .post<ApiResponse<SupplierProduct>>(environment.api.baseUrl + 'supplier-products', supplierProduct, options)
      .pipe(
        setLoading(this.supplierProductsStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
        tap(({data: newSupplierProduct}) => {
          this.supplierProductsStore.add(newSupplierProduct);
        }),
      );
  }

  // update(id, supplierProduct: Partial<SupplierProduct>, onDb: boolean = false) {
  //   if (onDb) {
  //     const options = getHttpOptionsWithIncludeAndAttributes(
  //       SupplierProductsService.includes,
  //       SupplierProductsService.defaultAttributes,
  //       {
  //         tenant_id: this.sessionQuery.tenantId.toString(),
  //       },
  //     );
  //
  //     this.http
  //       .put<ApiResponse<SupplierProduct>>(environment.api.baseUrl + 'supplier-products/' + id, supplierProduct, options)
  //       .pipe(
  //         setLoading(this.supplierProductsStore),
  //         catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
  //         tap((response) => {
  //           this.supplierProductsStore.update(id, response['data']);
  //         }),
  //       ).subscribe();
  //   } else {
  //     this.supplierProductsStore.update(id, supplierProduct);
  //   }
  // }

  setActiveSupplierProduct(id: number): void {
    if (!id) {
      this.supplierProductsStore.setActive(null);
    } else if (!this.supplierProductsQuery.hasEntity(id)) {
      this.getById(id).subscribe({
        next: (response) => {
          this.supplierProductsStore.setActive(response['data']?.id);
        }
      });
    } else {
      this.supplierProductsStore.setActive(id);
    }
  }

  shareSupplierProduct(supplierProduct: SupplierProduct, recipients: string[], message: string) {
    recipients = recipients
      .map(recipient => this.microsoftGraphUsersQuery.getMicrosoftGraphUserById(recipient)?.mail)
      .filter(recipient => !!recipient);

    const body = {
      recipients,
      message,
    };

    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http.post<ApiResponse>(environment.api.baseUrl + `supplier-products/${supplierProduct.id}/custom/share`, body, options).pipe(
      setLoading(this.supplierProductsStore),
      catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.supplierProductsStore)),
    );
  }

  navigateToShopSupplierProductDetail(id: number): Promise<boolean> {
    if (id !== this.supplierProductsQuery.getActiveId()) {
      this.setActiveSupplierProduct(null);
    }
    return this.router.navigate([
      ShopRouteNames.SHOP,
      ProductRouteNames.PRODUCT,
      id
    ]);
  }
}
