<div class="mat-typography">
    <div
      class="mat-headline"
      mat-dialog-title>Bezugsquelle erstellen/bearbeiten
    </div>

    <form
      [formGroup]="supplierProductForm"
      class="flex flex-col gap-1">
        <dqn-autocomplete
          formControlName="supplierId"
          [isRequired]="true"
          label="Lieferant"
          [multi]="false"
          [options]="(suppliers$ | async)"
          placeholder="Lieferant">
        </dqn-autocomplete>

        <mat-form-field>
            <mat-label>Artikelnummer</mat-label>
            <input
              matInput
              formControlName="supplierOrderNumber"
              required/>
        </mat-form-field>

        <div
          class="flex gap-1"
          formGroupName="unitPriceGroup">
            <app-mat-input-number
              class="flex-1"
              [required]="
                  this.supplierProductForm.get('billingFrequencyPriceGroup.billingFrequencyPrice').value == null ||
                  this.supplierProductForm.get(
                    'billingFrequencyPriceGroup.billingFrequencyPrice'
                  )?.value === ''
                "
              label="Einmalpreis"
              [value]="this.supplierProductForm.get('unitPriceGroup').get('unitPrice').value"
              (onChanged)="updateUnitPrice($event)">
            </app-mat-input-number>

            <mat-form-field class="w-[20%]">
                <mat-label>Währung</mat-label>
                <mat-select
                  formControlName="currencyCodeId"
                  [required]="!!this.supplierProductForm.get('unitPriceGroup').get('unitPrice').value">
                    <mat-option
                      *ngFor="let currencyCode of currencyCodes$ | async"
                      [value]="currencyCode.id">
                        {{ currencyCode.display_name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div
          class="flex gap-1"
          formGroupName="billingFrequencyPriceGroup">
            <app-mat-input-number
              class="flex-1"
              [required]="
                  this.supplierProductForm.get('unitPriceGroup').get('unitPrice').value == null
                  || this.supplierProductForm.get('unitPriceGroup').get('unitPrice').value === ''"
              label="Abrechnungspreis"
              [value]="this.supplierProductForm.get('billingFrequencyPriceGroup').get('billingFrequencyPrice').value"
              (onChanged)="updateBillingFrequencyPrice($event)">
            </app-mat-input-number>

            <mat-form-field class="w-[20%]">
                <mat-label>Währung</mat-label>
                <mat-select
                  formControlName="billingFrequencyCurrencyCodeId"
                  [required]="!!this.supplierProductForm.get('billingFrequencyPriceGroup').get('billingFrequencyPrice').value">
                    <mat-option
                      *ngFor="let currencyCode of currencyCodes$ | async"
                      [value]="currencyCode.id">
                        {{ currencyCode.display_name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-[20%]">
                <mat-label>Abrechnungshäufigkeit</mat-label>
                <mat-select
                  formControlName="billingFrequencyId"
                  [required]="!!this.supplierProductForm.get('billingFrequencyPriceGroup.billingFrequencyPrice')?.value">
                    <mat-option
                      *ngFor="let billingFrequency of billingFrequencies$ | async"
                      [value]="billingFrequency.id">
                        {{ billingFrequency.display_name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="flex">
            <mat-form-field>
                <mat-label>Status</mat-label>
                <mat-select
                  formControlName="statusId"
                  required>
                    <mat-option
                      *ngFor="let supplierAvailableStatus of supplierAvailableStatuses$| async"
                      [value]="supplierAvailableStatus.status_id">
                        {{ supplierAvailableStatus.status.display_name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-slide-toggle
              formControlName="isPromoted"
              color="primary">Angesagt
            </mat-slide-toggle>
        </div>
    </form>


    <mat-dialog-actions align="end">
        <button
          mat-button
          color="primary"
          (click)="cancel()">Abbrechen
        </button>

        <button
          mat-raised-button
          color="primary"
          [disabled]="supplierProductForm.invalid"
          (click)="save()">
            Speichern
        </button>
    </mat-dialog-actions>
</div>
