import {inject, InjectionToken} from '@angular/core';
import {PaginatorPlugin} from '@datorama/akita';
import {SupplierProductsQuery} from './supplier-products.query';

export const SUPPLIER_PRODUCTS_PAGINATOR =
  new InjectionToken('SUPPLIER_PRODUCTS_PAGINATOR', {
    providedIn: 'root',
    factory: () => {
      const supplierProductsQuery = inject(SupplierProductsQuery);
      return new PaginatorPlugin(supplierProductsQuery)
        .withControls()
        .withRange();
    }
  });



