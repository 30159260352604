import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'button[app-variant-option]',
  templateUrl: './variant-option.component.html',
  styleUrls: ['./variant-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.unselectable]': '!selectable',
    '[class.selected]': 'selected',
  },
})
export class VariantOptionComponent {
  @Input() selected = false;
  @Input() selectable = true;
}
