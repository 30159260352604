import {SessionQuery} from '@/core/session/state/session.query';
import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-help-info-box',
  templateUrl: './help-info-box.component.html',
  styleUrls: ['./help-info-box.component.scss']
})
export class HelpInfoBoxComponent {
  @Input() assetPath: string = 'default-placeholder.jpg';

  baseAssetPath = '../../../../../../../assets';

  get imageSourcePath() {
    return this.baseAssetPath + '/' + this.assetPath;
  }

  constructor(
    public sessionQuery: SessionQuery,
  ) {
  }
}
