import {ProductVariant} from '../../product.model';
import {VariantOption} from '../../types/available-variant-option';
import {findMatchingVariantForSelection} from './find-matching-variant-for-selection';

interface FindVariantForNewSelectionProps {
  newVariantOption: VariantOption;
  previousVariantOptions: VariantOption[];
  variants: ProductVariant[];
}

export const findVariantForNewSelection = ({
                                             newVariantOption,
                                             variants,
                                             previousVariantOptions
                                           }: FindVariantForNewSelectionProps) => {
  const newVariantOptions = previousVariantOptions.map(option => option.productAttributeTemplateId === newVariantOption.productAttributeTemplateId
    ? newVariantOption
    : option
  );
  const variantToSelect = findMatchingVariantForSelection({
    variantOptions: newVariantOptions,
    variants,
  });

  // If no perfect match was found, select the first variant that matches the changed option
  if (!variantToSelect) {
    return variants.find(variant => variant.product_attributes.some(attribute =>
      attribute.product_attribute_template_id === newVariantOption.productAttributeTemplateId && attribute.value === newVariantOption.value
    ));
  }

  return variantToSelect;
}
