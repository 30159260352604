<div class="mb-2">
    <mat-card>
        <mat-card-content>
            <form [formGroup]="form">
                <div>
                    <div class="mat-title">Kategorie auswählen</div>
                </div>
                <div>
                    <mat-form-field class="w-full">
                        <mat-label>Kategorie auswählen</mat-label>
                        <mat-select formControlName="categoryIdControl">
                            <mat-option
                              *ngFor="let productCategory of (productCategoriesQuery.mainProductCategories$ | async)"
                              [value]="productCategory.id"
                              (click)="selectMainProductCategory()">
                                {{ productCategory.display_name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="category-selection-container">
                    <div
                      class="mt-2 flex"
                      *ngIf="mainProductCategory">
                        <div class="flex-item">
                            <mat-selection-list multiple="false">
                                <mat-list-option
                                  selected
                                  (click)="selectMainProductCategory()">
                                    {{ mainProductCategory.display_name }}
                                </mat-list-option>
                            </mat-selection-list>
                        </div>

                        <mat-divider
                          class="mx-2"
                          [vertical]="true" />

                        <div
                          *ngIf="mainProductCategory.id | hasChildCategoriesByParentId | async"
                          class="flex-item">
                            <mat-selection-list
                              multiple="false"
                              formControlName="subProductCategoryControl">
                                <mat-list-option
                                  *ngFor="let subProductCategory of (mainProductCategory.id | childCategoriesByParentId | async)"
                                  [value]="subProductCategory"
                                  (click)="selectSubProductCategory(subProductCategory)">
                                    {{ subProductCategory?.display_name }}
                                </mat-list-option>
                            </mat-selection-list>
                        </div>

                        <ng-container *ngIf="firstLevelChildProductCategory && (firstLevelChildProductCategory.id | hasChildCategoriesByParentId | async)">
                            <mat-divider
                              class="mx-2"
                              [vertical]="true" />

                            <div
                              class="flex-item">
                                <mat-selection-list
                                  multiple="false"
                                  formControlName="subSubProductCategoryControl">
                                    <mat-list-option
                                      selected="false"
                                      *ngFor="let subSubProductCategory of (firstLevelChildProductCategory.id | childCategoriesByParentId | async)"
                                      [value]="subSubProductCategory"
                                      (click)="selectSubSubProductCategory(subSubProductCategory)">
                                        {{ subSubProductCategory?.display_name }}
                                    </mat-list-option>
                                </mat-selection-list>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="secondLevelChildProductCategory && (secondLevelChildProductCategory.id | hasChildCategoriesByParentId | async)">
                            <mat-divider
                              class="mx-2"
                              [vertical]="true"
                            ></mat-divider>
                            <div
                              class="flex-item">
                                <mat-selection-list
                                  multiple="false"
                                  formControlName="subSubSubProductCategoryControl">
                                    <mat-list-option
                                      *ngFor="let subSubSubProductCategory of (secondLevelChildProductCategory?.id | childCategoriesByParentId | async)"
                                      [value]="subSubSubProductCategory"
                                      (click)="selectSubSubSubProductCategory(subSubSubProductCategory)">
                                        {{ subSubSubProductCategory?.display_name }}
                                    </mat-list-option>
                                </mat-selection-list>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div
                  class="flex mt-2"
                  *ngIf="mainProductCategory">
                    <div>Ihr Artikel wird in folgender Kategorie gelistet:</div>
                    <div class="ml-2">
                        <mat-chip-list>
                            <mat-chip
                              color="primary"
                              selected>
                                {{ mainProductCategory.display_name }}
                            </mat-chip>
                            <ng-container *ngIf="firstLevelChildProductCategory">
                                <div class="mx-2">/</div>
                                <mat-chip
                                  color="primary"
                                  selected>
                                    {{ firstLevelChildProductCategory.display_name }}
                                </mat-chip>
                            </ng-container>
                            <ng-container *ngIf="secondLevelChildProductCategory">
                                <div class="mx-2">/</div>
                                <mat-chip
                                  color="primary"
                                  selected>
                                    {{ secondLevelChildProductCategory.display_name }}
                                </mat-chip>
                            </ng-container>
                            <ng-container *ngIf="thirdLevelChildProductCategory">
                                <div class="mx-2">/</div>
                                <mat-chip
                                  color="primary"
                                  selected>
                                    {{ thirdLevelChildProductCategory.display_name }}
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>
                    </div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
