import {SessionQuery} from '@/core/session/state/session.query';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {ID, setLoading} from '@datorama/akita';
import {catchError, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {ApiResponse} from '../../types/api/api-response';
import {handleError} from '../../utils';
import {getHttpOptionsWithParams} from '../../utils/functions/http-params';
import {CountryCode} from './country-code.model';
import {CountryCodesStore} from './country-codes.store';

@Injectable({providedIn: 'root'})
export class CountryCodesService {
  private readonly countryCodesStore = inject(CountryCodesStore);
  private readonly sessionQuery = inject(SessionQuery);
  private readonly http = inject(HttpClient);
  private readonly snackBar = inject(MatSnackBar);

  get() {
    const options = getHttpOptionsWithParams({
      tenant_id: this.sessionQuery.tenantId.toString(),
    });

    return this.http
      .get<ApiResponse<CountryCode[]>>(environment.api.baseUrl + 'country-codes', options)
      .pipe(
        setLoading(this.countryCodesStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.countryCodesStore)),
        tap((response) => {
          this.countryCodesStore.set(response['data']);
        }),
      );
  }

  add(countryCode: CountryCode) {
    this.countryCodesStore.add(countryCode);
  }

  update(id, countryCode: Partial<CountryCode>) {
    this.countryCodesStore.update(id, countryCode);
  }

  remove(id: ID) {
    this.countryCodesStore.remove(id);
  }
}
