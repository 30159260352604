import {Directive, OnDestroy} from '@angular/core';
import {Store} from '@datorama/akita';
import {getComponentStoreConfig} from '../../helpers/component-store/helpers/functions/get-component-store-config';

/**
 * Component Store base class that should only be used for stores that are used inside component provider arrays.
 */
@Directive()
export abstract class ComponentStore<S = any> extends Store<S> implements OnDestroy {
  protected constructor(initialState: Partial<S>, storeName: string) {
    super(initialState, getComponentStoreConfig(storeName));
  }

  ngOnDestroy() {
    // Will automatically destroy the store when the containing component is destroyed
    this.destroy();
  }
}
