import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DqnTableHeader} from '@dqn/components-library/table';
import {environment} from '../../../../../environments/environment';
import {Product} from '../../state/product.model';
import {SupplierProduct} from '../../state/supplier-products/supplier-product.model';

@Component({
  selector: 'app-supplier-products-product-list',
  templateUrl: './supplier-products-product-list.component.html',
  styleUrls: ['./supplier-products-product-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierProductsProductListComponent implements OnInit {
  @Input({required: true}) supplierProducts!: SupplierProduct[];
  @Input({required: true}) product!: Product;
  @Input() allowNavigation = true;
  @Input() loading = false;
  @Input() selectedSupplierProduct: SupplierProduct | null = null;

  @Output() onAddToShoppingCart = new EventEmitter<SupplierProduct>();
  @Output() onNavigateToSupplierProduct = new EventEmitter<SupplierProduct>();

  supplierProductsTableHeaders: DqnTableHeader[] = [
    {key: 'image', title: ''},
    {key: 'name', title: 'Produkt'},
    {key: 'supplier', title: 'Bezugsquelle'},
    {key: 'rating', title: 'Bewertung'},
    {key: 'price', title: 'Preis'},
    // TODO: Migrate to table actions as soon as the selection is presented in another way ("Selected" should be the last cell)
    {key: 'addToBasket', title: ''},
    {key: 'goToProduct', title: ''},
  ];

  ngOnInit() {
    // Hide 'addToBasket' if shop is not active
    if (!environment.shopActive) {
      this.supplierProductsTableHeaders = this.supplierProductsTableHeaders.filter(
        column => column.key !== 'addToBasket'
      );
    }

    // Hide product link if navigation is not allowed
    if (!this.allowNavigation) {
      this.supplierProductsTableHeaders = this.supplierProductsTableHeaders.filter(
        column => column.key !== 'goToProduct'
      );
    }
  }
}
