<dqn-table
  size="small"
  [rows]="productAttributes"
  [headers]="productAttributeHeaders">
    <td
      dqnTableCell
      *dqnTableCellTemplate="'productAttributeTemplateName'; let productAttribute">
        {{ productAttribute?.product_attribute_template?.display_name }}
    </td>

    <td
      dqnTableCell
      *dqnTableCellTemplate="'value'; let productAttribute">
        <mat-form-field>
            <mat-label>Wert</mat-label>
            <input
              matInput
              #value
              [value]="productAttribute?.value"
              (change)="updateProductAttribute.emit({
                productAttributeTemplateId: productAttribute?.product_attribute_template_id,
                value: $event?.target['value']
              })"
              [disabled]="formMode.isDetails"
              required />
        </mat-form-field>
    </td>

    <td
      dqnTableCell
      *dqnTableCellTemplate="'quantityUnitDisplayName'; let productAttribute">
        {{ productAttribute?.product_attribute_template?.quantity_unit?.display_name }}
    </td>

    <td
      dqnTableCell
      *dqnTableCellTemplate="'quantityUnitDisplayAbbreviation'; let productAttribute">
        {{ productAttribute?.product_attribute_template?.quantity_unit?.display_abbreviation }}
    </td>

    <ng-container *ngIf="!formMode.isReadonly">
        <dqn-table-row-actions *dqnTableRowAction="let productAttribute">
            <dqn-table-row-action-delete
              text="Entfernen"
              (click)="removeProductAttribute.emit(productAttribute?.product_attribute_template_id)" />
        </dqn-table-row-actions>
    </ng-container>
</dqn-table>
