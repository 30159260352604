import {PermissionsService} from '@/core/session/state/permissions/permissions.service';
import {SessionQuery} from '@/core/session/state/session.query';
import {ShopRouteNames} from '@/shop/constants/routes.constants';
import {ProductRouteNames as ShopProductRouteNames} from '@/shop/product/constants/routes.constants';
import {SUPPLIER_PRODUCTS_PAGINATOR} from '@/shop/product/state/supplier-products/supplier-products-paginator';
import {SupplierProductsQuery} from '@/shop/product/state/supplier-products/supplier-products.query';
import {SupplierProductsService} from '@/shop/product/state/supplier-products/supplier-products.service';
import {SupplierProductsState} from '@/shop/product/state/supplier-products/supplier-products.store';
import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {PaginatorPlugin} from '@datorama/akita';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BehaviorSubject} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, OnDestroy {
  isSupplierProductProposalAllowed = false;
  isLoadingSubject = new BehaviorSubject(false);

  promotedSupplierProducts$ = this.supplierProductsQuery.promotedSupplierProducts$;
  isLoading$ = this.isLoadingSubject.asObservable();

  supplierProductProposalRouterLink = [
    '/',
    ShopRouteNames.SHOP,
    ShopProductRouteNames.PRODUCT,
    ShopProductRouteNames.PROPOSAL,
  ];

  constructor(
    public sessionQuery: SessionQuery,
    @Inject(SUPPLIER_PRODUCTS_PAGINATOR)
    private paginatorRef: PaginatorPlugin<SupplierProductsState>,
    private permissionsService: PermissionsService,
    private supplierProductsQuery: SupplierProductsQuery,
    private supplierProductsService: SupplierProductsService,
  ) {
  }

  ngOnInit() {
    this.supplierProductsService.getPromoted().subscribe();

    this.permissionsService.checkIfUserHasOneOrMorePermissionTo([{
        model: 'supplierProduct',
        attribute: 'proposal',
        action: 'create'
      }]
    ).then(hasOneOrMorePermissions => {
      this.isSupplierProductProposalAllowed = hasOneOrMorePermissions;
    });
  }

  ngOnDestroy() {
    this.paginatorRef.clearPage(1);
    this.paginatorRef.destroy();
  }
}
