import {CostCenter} from '@/shared/state/cost-centers/cost-center.model';
import {WithAllowanceConfig} from '@/shared/types/api/with-allowance-config';
import {WithBaseTimestamps} from '@/shared/types/api/with-timestamps';
import {ProductCategory} from '../product-categories/product-category.model';

type CostCenterProductCategoryIncludes = {
  product_category?: ProductCategory;
  cost_center?: CostCenter;
}

export interface CostCenterProductCategoryPivot extends CostCenterProductCategoryIncludes, WithAllowanceConfig, WithBaseTimestamps {
  id: number;
  product_category_id: ProductCategory['id'];
  cost_center_id: CostCenter['id'];
}

export interface CostCenterProductCategory extends CostCenter {
  pivot: CostCenterProductCategoryPivot,
}

export interface ProductCategoryCostCenter extends ProductCategory {
  pivot: CostCenterProductCategoryPivot,
}

export const createCostCenterProductCategoryPivot = (params: Partial<CostCenterProductCategoryPivot>) => ({...params} as CostCenterProductCategoryPivot);

export const createCostCenterProductCategory = (params: Partial<CostCenterProductCategory>) => ({...params} as CostCenterProductCategory);

export const createProductCategoryCostCenter = (params: Partial<ProductCategoryCostCenter>) => ({...params} as ProductCategoryCostCenter);
