import {MatCardAlertMode} from '@/shared/enums/mat-card-alert-mode.enum';
import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators,} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
  ProductCategoriesQuery
} from '../../../../state/product-categories/product-categories.query';
import {
  ProductCategoriesService
} from '../../../../state/product-categories/product-categories.service';
import {ProductCategory} from '../../../../state/product-categories/product-category.model';
import {ProposalStore} from '../../state/proposal.state';

@UntilDestroy()
@Component({
  selector: 'app-step-select-product-category',
  templateUrl: './step-select-product-category.component.html',
  styleUrls: ['./step-select-product-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepSelectProductCategoryComponent implements OnInit {
  readonly productCategoriesQuery = inject(ProductCategoriesQuery);

  private readonly formBuilder = inject(UntypedFormBuilder);
  private readonly productCategoriesService = inject(ProductCategoriesService);
  private readonly store = inject(ProposalStore);

  matCardAlertMode = MatCardAlertMode;
  form: UntypedFormGroup;
  mainProductCategory: ProductCategory;
  firstLevelChildProductCategory: ProductCategory;
  secondLevelChildProductCategory: ProductCategory;
  thirdLevelChildProductCategory: ProductCategory;

  selectedProductCategoryId: number | null = null;

  get isValid() {
    return this.selectedProductCategoryId != null;
  }

  constructor() {
    this.form = this.formBuilder.group({
      categoryIdControl: [null, Validators.required],
      subProductCategoryControl: [null],
      secondLevelChildProductCategoryControl: [null],
      thirdLevelChildProductCategoryControl: [null]
    });
  }

  ngOnInit(): void {
    this.productCategoriesService.get().subscribe();

    this.form.get('categoryIdControl').valueChanges.pipe(
      untilDestroyed(this),
    ).subscribe((value) => {
      this.productCategoriesQuery.selectCategoryById(value).subscribe(category => {
        if (category) {
          this.mainProductCategory = category;
          this.firstLevelChildProductCategory = null;
          this.secondLevelChildProductCategory = null;
          this.thirdLevelChildProductCategory = null;
        }
      });
    });

    this.form.get('subProductCategoryControl').valueChanges.pipe(
      untilDestroyed(this),
    ).subscribe((value) => {
      if (value) {
        this.selectSubProductCategory(value[0]);
      } else {
        this.selectSubProductCategory(null);
      }
    });

    this.form.get('secondLevelChildProductCategoryControl').valueChanges.pipe(
      untilDestroyed(this),
    ).subscribe((value) => {
      if (value) {
        this.selectSubSubProductCategory(value[0]);
      } else {
        this.selectSubSubProductCategory(null);
      }
    });

    this.form.get('thirdLevelChildProductCategoryControl').valueChanges.pipe(
      untilDestroyed(this),
    ).subscribe((value) => {
      if (value) {
        this.selectSubSubSubProductCategory(value[0]);
      } else {
        this.selectSubSubSubProductCategory(null);
      }
    });
  }

  selectSubProductCategory(productCategory: ProductCategory) {
    this.secondLevelChildProductCategory = null;
    this.thirdLevelChildProductCategory = null;
    this.productCategoriesQuery.selectCategoryById(productCategory?.id)
      .pipe(untilDestroyed(this))
      .subscribe(subProductCategory => {
        this.firstLevelChildProductCategory = subProductCategory;
        this.selectedProductCategoryId = this.firstLevelChildProductCategory?.id;
        this.updateProductCategoryOfProductCollection();
      });
  }

  selectSubSubProductCategory(productCategory: ProductCategory) {
    this.thirdLevelChildProductCategory = null;
    this.productCategoriesQuery.selectCategoryById(productCategory.id)
      .pipe(untilDestroyed(this))
      .subscribe(subSubCategory => {
        this.secondLevelChildProductCategory = subSubCategory;
        this.selectedProductCategoryId = this.secondLevelChildProductCategory?.id;
        this.updateProductCategoryOfProductCollection();
      });
  }

  selectMainProductCategory() {
    this.form.get('subProductCategoryControl').setValue(null);
    this.firstLevelChildProductCategory = null;
    this.secondLevelChildProductCategory = null;
    this.thirdLevelChildProductCategory = null;
    this.selectedProductCategoryId = this.mainProductCategory?.id;
    this.updateProductCategoryOfProductCollection();
  }

  selectSubSubSubProductCategory(productCategory: ProductCategory) {
    this.thirdLevelChildProductCategory = productCategory;
    this.selectedProductCategoryId = this.thirdLevelChildProductCategory.id;
    this.updateProductCategoryOfProductCollection();

  }

  updateProductCategoryOfProductCollection() {
    this.store.updateProductCategoryId(this.selectedProductCategoryId);
  }
}
