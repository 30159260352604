import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {VariantAttributeOption} from './variant-attribute-option.model';

export type VariantAttributeOptionsState = EntityState<VariantAttributeOption>;

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'variant-attribute-options'})
export class VariantAttributeOptionsStore extends EntityStore<VariantAttributeOptionsState> {
  constructor() {
    super({loading: false});
  }
}

