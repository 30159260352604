import {SharedModule} from '@/shared';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {DqnAutocompleteModule} from '@dqn/components-library/autocomplete';
import {DqnButtonModule} from '@dqn/components-library/button';
import {DqnTableModule} from '@dqn/components-library/table';
import {SharedModule as ShopSharedModule} from '../shared/shared.module';
import {HelpInfoBoxComponent} from '../supplier-product/components/help-info-box/help-info-box.component';
import {
  SelectSupplierProductForShoppingCartDialogComponent
} from './components/dialogs/select-supplier-product-for-shopping-cart-dialog/select-supplier-product-for-shopping-cart-dialog.component';
import {
  SelectVariantForShoppingCartDialogComponent
} from './components/dialogs/select-variant-for-shopping-cart-dialog/select-variant-for-shopping-cart-dialog.component';
import {
  SupplierProductDetailsRouterLinkPipe
} from './components/product-gallery-item/pipes/supplier-product-details-router-link.pipe';
import {ProductGalleryItemComponent} from './components/product-gallery-item/product-gallery-item.component';
import {ProductGalleryComponent} from './components/product-gallery/product-gallery.component';
import {
  IsVariantOptionSelectablePipe
} from './components/product-variant-selection/pipes/is-variant-option-selectable.pipe';
import {
  IsVariantOptionSelectedPipe
} from './components/product-variant-selection/pipes/is-variant-option-selected.pipe';
import {
  ProductVariantSelectionComponent
} from './components/product-variant-selection/product-variant-selection.component';
import {
  SupplierProductShareDialogComponent
} from './components/supplier-product-share-icon/components/supplier-product-share-dialog/supplier-product-share-dialog.component';
import {
  SupplierProductShareIconComponent
} from './components/supplier-product-share-icon/supplier-product-share-icon.component';
import {
  SupplierProductPriceComponent
} from './components/supplier-products-product-list/components/supplier-product-price/supplier-product-price.component';
import {
  SupplierProductsProductListComponent
} from './components/supplier-products-product-list/supplier-products-product-list.component';
import {HasProductContentsInformationPipe} from './pages/details/pipes/has-product-contents-information.pipe';
import {ParseProductAttributeValuePipe} from './pages/details/pipes/parse-product-attribute-value.pipe';
import {ProductDetailsComponent} from './pages/details/product-details.component';
import {
  StepCreateSupplierProductComponent
} from './pages/proposal/components/step-create-supplier-product/step-create-supplier-product.component';
import {
  StepSelectOrCreateSupplierComponent
} from './pages/proposal/components/step-select-or-create-supplier/step-select-or-create-supplier.component';
import {
  StepSelectProductCategoryComponent
} from './pages/proposal/components/step-select-product-category/step-select-product-category.component';
import {ProposalComponent} from './pages/proposal/proposal.component';
import {ProductRoutingModule} from './product-routing.module';
import {ProductByIdPipe} from './state/pipes/product-by-id.pipe';
import {
  ProductAttributeTemplateByIdPipe
} from './state/product-attribute-templates/pipes/product-attribute-template-by-id.pipe';
import {SupplierProductByIdPipe} from './state/supplier-products/pipes/supplier-product-by-id-pipe';
import {SupplierProductsForProductPipe} from './state/supplier-products/pipes/supplier-products-for-product.pipe';
import {VariantAttributesForProductPipe} from './state/variant-attributes/pipes/variant-attributes-for-product.pipe';

@NgModule({
  declarations: [
    ProductDetailsComponent,
    ProductGalleryComponent,
    ProductGalleryItemComponent,
    SupplierProductDetailsRouterLinkPipe,
    SupplierProductByIdPipe,
    SelectSupplierProductForShoppingCartDialogComponent,
    SelectVariantForShoppingCartDialogComponent,
    SupplierProductsProductListComponent,
    SupplierProductPriceComponent,
    SupplierProductShareIconComponent,
    SupplierProductShareDialogComponent,
    ProductVariantSelectionComponent,
    VariantAttributesForProductPipe,
    ProductAttributeTemplateByIdPipe,
    SupplierProductsForProductPipe,
    ProductByIdPipe,
    IsVariantOptionSelectedPipe,
    IsVariantOptionSelectablePipe,
    HasProductContentsInformationPipe,
    ParseProductAttributeValuePipe,
    ProposalComponent,
    StepSelectOrCreateSupplierComponent,
    StepCreateSupplierProductComponent,
    StepSelectProductCategoryComponent,
    HelpInfoBoxComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ProductRoutingModule,
    ReactiveFormsModule,
    DqnAutocompleteModule,
    DqnTableModule,
    DqnButtonModule,
    ShopSharedModule,
  ],
  exports: [
    ProductGalleryComponent,
    ProductGalleryItemComponent,
    SupplierProductByIdPipe,
    ProductByIdPipe
  ],
  providers: [
    CurrencyPipe,
  ]
})
export class ProductModule {
}
