<div class="mat-typography">
    <div>
        <div class="flex items-center justify-center w-[66%]">
            <div
              class="mat-headline"
              mat-dialog-title>Artikelmerkmale hinzufügen
            </div>
        </div>
        <div class="flex justify-center items-center w-[33%]">
            <img src="../../../../assets/lightbulb_moment.svg"/>
        </div>
    </div>
    <div class="mb-4 mt-2">
        <app-mat-card-alert
          text="Ergänzen Sie hier alle relevanten Artikelmerkmale. Diese Artikelmerkmale beschreiben den Artikel genauer und liefern die Grundlage für eine Kaufentscheidung."
          icon="info"
          [mode]="matCardAlertMode.accent"
        ></app-mat-card-alert>
    </div>
    <div>
        <div>
            <app-title-icon
              text="Vorlage auswählen"
              icon="topic"></app-title-icon>
            <app-mat-autocomplete-name-search
              label="Vorlage auswählen"
              useDisplayName="true"
              returnObject="true"
              [options]="productAttributeGroups$ | async"
              [selectedOption]="selectedProductAttributeGroupControl.value"
              (onChanged)="selectProductAttributeGroup($event)"
            >
            </app-mat-autocomplete-name-search>
        </div>
        <div>
            <app-title-icon
              text="Artikelmerkmale auswählen"
              icon="add_box"
            ></app-title-icon>
            <app-mat-autocomplete-name-search
              label="Technische Merkmale auswählen"
              useDisplayName="true"
              returnObject="true"
              [options]="productAttributeTemplates$ | async"
              [selectedOption]="selectedProductAttributeTemplateControl.value"
              (onChanged)="selectProductAttribute($event)"
            >
            </app-mat-autocomplete-name-search>
        </div>
        <div>
            <app-title-icon
              text="Liste der gewählten Merkmale"
              icon="list_alt"
            ></app-title-icon>
            <table
              mat-table
              [dataSource]="selectedProductAttributeTemplatesDataSource"
            >
                <ng-container matColumnDef="productAttributeTemplateName">
                    <th
                      mat-header-cell
                      *matHeaderCellDef>Name
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element">
                        {{ element?.display_name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="quantityUnitDisplayName">
                    <th
                      mat-header-cell
                      *matHeaderCellDef>Mengeneinheit
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element">
                        {{ element?.quantity_unit?.display_name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="quantityUnitDisplayAbbreviation">
                    <th
                      mat-header-cell
                      *matHeaderCellDef>Abkürzung
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element">
                        {{ element?.quantity_unit?.display_abbreviation }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="actions"></th>
                    <td
                      mat-cell
                      *matCellDef="let element">
                        <button
                          mat-icon-button
                          color="primary"
                          (click)="removeSelectedAttributeTemplate(element.id)"
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>
    </div>
    <mat-dialog-actions align="end">
        <button
          mat-button
          color="primary"
          (click)="cancel()">Abbrechen
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="add()">
            Hinzufügen
        </button>
    </mat-dialog-actions>
</div>
