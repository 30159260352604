import {
  createVariantAttributeOption,
  VariantAttributeOption
} from '@/management/product/state/variant-attribute-options/variant-attribute-option.model';
import {
  variantAttributesMaxCount
} from '@/management/product/state/variant-attributes/constants/variant-attributes-max-count';
import {
  createVariantAttribute,
  VariantAttribute
} from '@/management/product/state/variant-attributes/variant-attribute.model';
import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {map, startWith} from 'rxjs/operators';
import {ComponentStore} from '../../../../helpers/component-store/component-store';
import {generateMultipleNextArtificialIds} from '../../../../helpers/generate-multiple-next-artificial-ids';
import {generateNextArtificialId} from '../../../../helpers/generate-next-artificial-id';
import {isArtificialId} from '../../../../helpers/is-artificial-id';

interface State {
  initialVariantAttributes: VariantAttribute[];
  variantAttributes: VariantAttribute[];
}

const getInitialState = (): State => ({
  initialVariantAttributes: [],
  variantAttributes: [],
});

@Injectable()
export class VariantAttributesManagementStore extends ComponentStore<State> {
  constructor() {
    super(getInitialState(), 'variant-attributes-management');
  }
}

@Injectable()
export class VariantAttributesManagementQuery extends Query<State> {
  variantsAttributes$ = this.select('variantAttributes');

  limitOfVariantAttributesReached$ = this.variantsAttributes$.pipe(
    map(variantsAttributes => variantsAttributes.length >= variantAttributesMaxCount),
    startWith(false),
  );

  get variantAttributes() {
    return this.getValue().variantAttributes;
  }

  constructor(protected override store: VariantAttributesManagementStore) {
    super(store);
  }

  getNewVariantAttributes() {
    const {variantAttributes} = this.getValue();

    return variantAttributes.filter(({id}) => isArtificialId(id));
  }

  getUpdatedProductAttributes() {
    const {initialVariantAttributes, variantAttributes} = this.getValue();

    return variantAttributes
      .filter(variantAttribute =>
        !isArtificialId(variantAttribute.id)
        && variantAttribute.variant_attribute_options.some(option => isArtificialId(option.id))
      ).map(variantAttribute => ({
        ...variantAttribute,
        // Include only new options that are not already part of the initial options
        variant_attribute_options: variantAttribute.variant_attribute_options.filter(option =>
            isArtificialId(option.id)
            && !initialVariantAttributes.some(({product_attribute_template_id, variant_attribute_options}) =>
              product_attribute_template_id === variantAttribute.product_attribute_template_id
              && variant_attribute_options.some(({value}) => value === option.value)
            )
        )
      }));
  }
}

@Injectable()
export class VariantAttributesManagementService {
  constructor(private store: VariantAttributesManagementStore, private query: VariantAttributesManagementQuery) {
  }

  addVariantAttributeOptions(templateId: VariantAttribute['product_attribute_template_id'], options: VariantAttributeOption['value'][]) {
    const existingVariantAttribute = this.query.variantAttributes.find(attribute => attribute.product_attribute_template_id === templateId);

    const newVariantAttributeOptions = generateMultipleNextArtificialIds(
      options.map(option => createVariantAttributeOption({
        value: option,
      })),
      existingVariantAttribute?.variant_attribute_options,
    );

    if (existingVariantAttribute) {
      this.addOptionsToExistingVariantAttribute(templateId, newVariantAttributeOptions);
    } else {
      this.createNewVariantAttributeWithOptions(templateId, newVariantAttributeOptions);
    }
  }

  setInitialVariantAttributes(variantAttributes: VariantAttribute[]) {
    this.store.update({initialVariantAttributes: variantAttributes, variantAttributes});
  }

  private createNewVariantAttributeWithOptions(templateId: number, variantAttributeOptions: VariantAttributeOption []) {
    const newVariantAttribute = createVariantAttribute({
      id: generateNextArtificialId(this.query.variantAttributes),
      product_attribute_template_id: templateId,
      variant_attribute_options: variantAttributeOptions,
    });

    this.store.update(({variantAttributes}) => ({
      variantAttributes: [...variantAttributes, newVariantAttribute],
    }));
  }

  private addOptionsToExistingVariantAttribute(templateId: number, variantAttributeOptions: VariantAttributeOption[]) {
    this.store.update(({variantAttributes}) => ({
        variantAttributes: variantAttributes.map(attribute => {
          if (attribute.product_attribute_template_id === templateId) {
            return {
              ...attribute,
              variant_attribute_options: [...attribute.variant_attribute_options, ...variantAttributeOptions]
            };
          }
          return attribute;
        }),
      })
    );
  }
}

export const provideVariantAttributesManagement = () => [
  VariantAttributesManagementStore,
  VariantAttributesManagementQuery,
  VariantAttributesManagementService,
];
